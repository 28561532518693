import {MainContext} from "../../_base/MainContext";
import React from "react";
import DataList from "../../Components/List/DataList";
import {ListFilterDefinition} from "../../Components/List/ListFilter";
import Entity from "../../_model/Entity";
import {ColumnDefinition} from "../../Components/List/List";
import {AdminObjectType} from "../../Framework/Enums";
import {RouteComponentProps} from "react-router-dom";
import {ListSortOptions} from "../../Components/List/ListSortOptions";

interface AdminDashboardListProps extends RouteComponentProps {
    title: string
    redirectUrl: string

    sortOptions: ListSortOptions
    columnDefinition: ColumnDefinition[]
    objectType: AdminObjectType
    listFilter: ListFilterDefinition[]
    listItems: Entity[]
}
interface AdminDashboardListState {
    page: number
    pageSize: number
}

export default class AdminDashboardList extends React.Component<AdminDashboardListProps, AdminDashboardListState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: AdminDashboardListProps, state: AdminDashboardListState) {
        super(props, state)

        this.state = {
            page: 1,
            pageSize: 100
        }
    }

    componentDidMount() {
        this.context.getUserSettings().then ((userSettings) => {
            let listPageSize = userSettings.listPageSize > 0 ? userSettings.listPageSize : 100
            this.setState({pageSize: listPageSize})
        })
    }

    getPageCount = () => {
        if (this.props.listItems === undefined || this.props.listItems.length === 0) {
            return 1
        }
        return Math.ceil(this.props.listItems.length / this.state.pageSize)
    }
    onPageChange = (page: number) => {
        if (page > 0 && page <= this.getPageCount()) {
            this.setState({page: page})
        }
    }

    render() {
        return <>
            <h2>{this.props.title}</h2>
            <div className={"admin-dashboard-item-data"}>
                <DataList
                    objectType={this.props.objectType}
                    userList={false}
                    items={this.props.listItems}
                    definitions={this.props.columnDefinition}
                    cardActions={[]}
                    redirectUrl={this.props.redirectUrl}
                    allowStatusChangeToUserItself={false}
                    allowMultiSelect={false}
                    searchOptions={{
                        showSearch: true,
                        showSearchOptions: false,
                    }}
                    paginationOptions={{
                        allowPagination: true,
                        showPagination: true,
                        pageSize: this.state.pageSize
                    }}
                    sortOptions={this.props.sortOptions}
                    filterOptions={{ externalFilter: false }}
                    showRowSelector={false}
                    filter={this.props.listFilter}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                />
            </div>
        </>
    }
}

