import {Coords} from "../../Framework/Coords";

export class ToolboxElement {
    id: number
    name: string
    parent?: string

    constructor(id: number, name: string, parent?: string) {
        this.id = id
        this.name = name
        this.parent = parent
    }
}
export class ToolboxGroup {
    label: string
    options: ToolboxGroupElements[]

    constructor(label: string) {
        this.label = label
        this.options = []
    }
}
export class ToolboxGroupElements {
    value: string
    label: string

    constructor(value: string, label: string) {
        this.value = value
        this.label = label
    }
}
export class ToolboxElementValues {
    width: number
    height: number
    numberOfElementsHeight: number
    numberOfElementsWidth: number
    posX?: number
    posY?: number

    constructor(width: number, height: number, numberOfElementsHeight?: number, numberOfElementsWidth?: number, posX?: number, posY?: number) {
        this.width = width
        this.height = height
        this.numberOfElementsHeight = numberOfElementsHeight || 1
        this.numberOfElementsWidth = numberOfElementsWidth || 1
        this.posX = posX
        this.posY = posY
    }

    static getWidth = (values: ToolboxElementValues) => {
        return values.numberOfElementsWidth > 1 ? values.width / values.numberOfElementsWidth : values.width
    }
    static getHeight = (values: ToolboxElementValues) => {
        return values.numberOfElementsHeight > 1 ? values.height / values.numberOfElementsHeight : values.height
    }
    static getPosX = (values: ToolboxElementValues, originalPos: Coords, currentElementNumber: number) => {
        if (values.posX !== undefined) {
            if (values.numberOfElementsWidth === 1) {
                return values.posX
            }

            return currentElementNumber >= values.numberOfElementsHeight
                ? values.posX + (values.width / values.numberOfElementsWidth * Math.floor(currentElementNumber / values.numberOfElementsHeight))
                : values.posX
        }
        return originalPos.x
    }
    static getPosY = (values: ToolboxElementValues, originalPos: Coords, currentElementNumber: number) => {
        if (values.posY !== undefined) {
            if (values.numberOfElementsHeight === 1) {
                return values.posY
            }

            return values.posY + (values.height / values.numberOfElementsHeight * (currentElementNumber % values.numberOfElementsHeight))
        }
        return originalPos.y
    }
}
