import React from 'react';
import {RibbonButton} from "../Ribbon/RibbonButton";
import translations from "../../Framework/translations.json";
import {Ribbon} from "../Ribbon/Ribbon";
import {MainContext} from "../../_base/MainContext";
import {SearchFields, SearchInput} from "../Controls/SearchInput";
import {AdminStatusConfirmationModal} from "../../Admin/AdminStatusConfirmationModal";
import {ListStyle, Status} from "../../Framework/Enums";
import User from "../../_model/User";
import {RibbonButtonData} from "../Ribbon/RibbonButtonData";
import {ImagePath} from "../../Framework/CategoryImage";
import {TooltipText} from "../Tooltips";

interface IProps {
    userRibbon: boolean
    allowStatusChangeToUserItself: boolean

    onActivate?: (title?: string, comment?: string) => void
    onDeactivate?: (title?: string, comment?: string) => void
    onSubmitForApproval?: (user: User, title: string, comment?: string) => void

    listStyle: ListStyle

    buttonNew?: RibbonButtonData
    buttonCopy?: RibbonButtonData
    buttonActivate?: RibbonButtonData
    buttonDeactivate?: RibbonButtonData
    buttonApproval?: RibbonButtonData

    buttonShowAsList?: RibbonButtonData
    buttonShowAsCard?: RibbonButtonData

    customRibbonButtons?: RibbonButtonData[]

    onSearch?: (searchValue: string, option?: SearchFields) => void
    searchValue?: string
    searchFieldOption?: SearchFields
    showSearchOptions?: boolean
    typeAheadSearch?: boolean

    showFilter: boolean
    onToggleFilter?: () => void
}

interface IState {
    changeStatus?: Status
}

export default class ListRibbon extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {}
    }

    evaluateRibbonButtons = () => {
        let ribbonButtons: RibbonButtonData[] = []

        if (this.props.buttonNew) {
            ribbonButtons.push(this.props.buttonNew)
        }
        if (this.props.buttonCopy) {
            ribbonButtons.push(this.props.buttonCopy)
        }

        if (this.props.buttonActivate && this.props.onActivate) {
            ribbonButtons.push(new RibbonButtonData(
                this.props.buttonActivate.id,
                this.props.buttonActivate.icon,
                this.props.buttonActivate.sort,
                this.props.buttonActivate.colorPrimary,
                this.props.buttonActivate.text,
                this.props.buttonActivate.tooltip,
                this.onDraft
            ))
        }

        if (this.props.buttonDeactivate && this.props.onDeactivate) {
            ribbonButtons.push(new RibbonButtonData(
                this.props.buttonDeactivate.id,
                this.props.buttonDeactivate.icon,
                this.props.buttonDeactivate.sort,
                this.props.buttonDeactivate.colorPrimary,
                this.props.buttonDeactivate.text,
                this.props.buttonDeactivate.tooltip,
                this.onDeactivate
            ))
        }

        if (this.props.buttonApproval && this.props.onSubmitForApproval) {
            ribbonButtons.push(new RibbonButtonData(
                this.props.buttonApproval.id,
                this.props.buttonApproval.icon,
                this.props.buttonApproval.sort,
                this.props.buttonApproval.colorPrimary,
                this.props.buttonApproval.text,
                this.props.buttonApproval.tooltip,
                this.onSubmitForApproval
            ))
        }

        ribbonButtons = ribbonButtons.concat(this.props.customRibbonButtons ?? [])

        return ribbonButtons.sort((a, b) => a.sort - b.sort)
    }

    onDraft = () => {
        if (this.props.userRibbon) {
            this.props.onActivate?.()
        }
        else {
            this.setState({changeStatus: Status.draft})
        }
    }
    onDeactivate = () => {
        if (this.props.userRibbon) {
            this.props.onDeactivate?.()
        }
        else {
            this.setState({changeStatus: Status.deactivated})
        }
    }
    onSubmitForApproval = () => {
        this.setState({changeStatus: Status.approval})
    }

    onSubmitStatus = (user?: User, comment?: string) => {
        // New state: submit for approval
        if (this.props.onSubmitForApproval && this.state.changeStatus === Status.approval) {
            if (user) {
                let title = this.context.translate(translations.text_fragment.status_approval_to) + user.firstname + " " + user.lastname

                this.props.onSubmitForApproval(user, title, comment)
                this.setState({changeStatus: undefined})
            }
        }

        // New state: deactivated
        if(this.props.onDeactivate && this.state.changeStatus === Status.deactivated) {
            this.props.onDeactivate(this.context.translate(translations.text_fragment.status_deactivate), comment)
            this.setState({changeStatus: undefined})
        }

        // New state: draft
        if(this.props.onActivate && this.state.changeStatus === Status.draft) {
            this.props.onActivate(this.context.translate(translations.text_fragment.status_draft), comment)
            this.setState({changeStatus: undefined})
        }
    }
    onCancelStatus = () => {
        this.setState({changeStatus: undefined})
    }

    onToggleFilter = () => {
        this.props.onToggleFilter?.()
    }

    render() {
        let ribbonButtons: RibbonButtonData[] = this.evaluateRibbonButtons()

        return <>
            <Ribbon userRibbon={this.props.userRibbon}>
                <div className={"ribbon-buttons"}>
                    {ribbonButtons.map(b => {
                        if (b.shownOnCondition === undefined || b.shownOnCondition()) {
                            return <RibbonButton data={b} key={"ribbon-button" + b.id} colorIconPrimary={b.colorPrimary}/>
                        }
                        return undefined
                    })}
                </div>

                <div className={"ribbon-buttons"}>
                    {this.props.buttonShowAsList && this.props.listStyle !== ListStyle.list &&
                        <RibbonButton data={this.props.buttonShowAsList} colorIconPrimary={true} />
                    }

                    {this.props.buttonShowAsCard && this.props.listStyle !== ListStyle.card &&
                        <RibbonButton data={this.props.buttonShowAsCard} colorIconPrimary={true} />
                    }

                    {this.props.onToggleFilter &&
                        <RibbonButton
                            data={{
                                id: "filter",
                                sort: 100,
                                colorPrimary: true,
                                tooltip: new TooltipText(this.context.translate(translations.command.filter_options), this.context.translate(translations.tooltip.filter)),
                                icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + (this.props.showFilter ? "delete_filters.svg" : "filter.svg"),
                                onClick: this.onToggleFilter
                            }}
                            colorIconPrimary={false}
                        />
                    }

                    {this.props.onSearch &&
                        <SearchInput onSearch={this.props.onSearch}
                                     value={this.props.searchValue}
                                     fieldOption={this.props.searchFieldOption}
                                     typeAheadSearch={this.props.typeAheadSearch === undefined ? true : this.props.typeAheadSearch}
                                     showFieldOptions={this.props.showSearchOptions || false}
                                     maxLength={50}
                        />
                    }
                </div>

            </Ribbon>

            {this.state.changeStatus &&
            <AdminStatusConfirmationModal
                status={this.state.changeStatus}
                allowStatusChangeToUserItself={this.props.allowStatusChangeToUserItself}
                onSave={this.onSubmitStatus}
                onCancel={this.onCancelStatus} />
            }
        </>
    }
}
