import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import {SyllableDefinition, WDSyllableWord} from "../../Designer/Elements/Textbox/WDSyllableWord";
import {MainContext} from "../../_base/MainContext";
import Syllable from "../../_model/Dictionary/Syllable";
import _ from "lodash";
import {CloseButton} from "./CloseButton";

interface IProps {
    notFoundWords: Syllable[]
    colorFirstSyllable: string
    colorSecondSyllable: string

    addSyllableTextManually: (originalWord: string, currentSyllableManually: string) => void
    closeSyllableNotFoundDialog: () => void
}

interface IState {
    currentSyllableManually: string
}

export class SyllableManualDialog extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentSyllableManually: this.props.notFoundWords[0].originalValue || ""
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.notFoundWords.length !== prevProps.notFoundWords.length) {
            this.setState({currentSyllableManually: this.props.notFoundWords[0].originalValue || ""})
        }
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    onChangeSyllableValueManually = (event: React.FormEvent) => {
        this.setState({currentSyllableManually: (event.target as HTMLInputElement).value})
    }
    onKeypressSyllableValueManually = (event: React.KeyboardEvent, originalWord: string) => {
        event.stopPropagation()

        if (event.key === "Enter") {
            this.props.addSyllableTextManually(originalWord, this.state.currentSyllableManually)
        }
    }

    render() {
        return <div className={"ws-designer-textbox-syllable-manually-container"}>
            <div className={"ws-designer-textbox-syllable-manually-row"}>
                <div className={"ws-designer-textbox-syllable-manually-text"}>
                    <div>{this.context.translate(translations.text.syllable_not_found)}</div>
                    <div className={"ws-designer-textbox-syllable-manually-row"}>
                        {this.context.translate(translations.text.example) + ": "}
                        {this.context.translate(translations.text.syllable_not_found_example) + " ->\u00A0"}
                        <WDSyllableWord
                            syllableWord={this.context.translate(translations.text.syllable_not_found_example)}
                            syllableDefinition={
                                new SyllableDefinition(true,
                                    this.props.colorFirstSyllable,
                                    this.props.colorSecondSyllable
                                )}
                        />
                    </div>
                </div>

                <CloseButton style={{alignSelf: "flex-start"}} close={this.props.closeSyllableNotFoundDialog}/>
            </div>

            <label htmlFor={"txtManualSyllable"}>{this.context.translate(translations.text.syllable_create)}</label>
            <div className={"ws-designer-textbox-syllable-manually"}>
                <input type={"text"}
                       id="txtManualSyllable"
                       value={this.state.currentSyllableManually}
                       onKeyDown={(e) => {
                           this.onKeypressSyllableValueManually(e, this.props.notFoundWords[0].originalValue!)
                       }}
                       onChange={this.onChangeSyllableValueManually}
                />
                <input type={"image"}
                       alt={this.context.translate(translations.command.ok)}
                       src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg"}
                       onClick={() => this.props.addSyllableTextManually(this.props.notFoundWords[0].originalValue!, this.state.currentSyllableManually)}
                />
            </div>
        </div>
    }
}
