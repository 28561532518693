import React, {RefObject} from 'react';
import {Link} from "react-router-dom";
import {ImageToggleButton} from "../Controls/ImageToggleButton";
import Entity from "../../_model/Entity";
import {ListEntry} from "./ListEntry";
import {ImagePath} from "../../Framework/CategoryImage";
import translations from "../../Framework/translations.json";
import ListPagination from "./ListPagination";
import {genericSort} from "../../Framework/Sort";
import {MainContext} from "../../_base/MainContext";
import {ColumnRenderOption, NotificationStatus} from "../../Framework/Enums";
import {Hint, NotificationData} from "../Notification/Hint";
import {ListRecord} from "./ListRecord";
import {Tooltips} from "../Tooltips";
import {ListType, ListTypeProps, ListTypeState} from "./ListType";
import ListFooter from "./ListFooter";

export class ColumnDefinition {
    displayName: string
    logicalName: string
    width: number           // width in %
    searchable: boolean
    sortable: boolean
    renderOption: ColumnRenderOption

    constructor(displayName: string, logicalName: string, width: number, searchable: boolean, sortable: boolean, renderOption?: ColumnRenderOption) {
        this.displayName = displayName
        this.logicalName = logicalName
        this.width = width
        this.searchable = searchable
        this.sortable = sortable

        this.renderOption = ColumnRenderOption.Text
        if (renderOption) {
            this.renderOption = renderOption
        }
    }
}

export class ListItem {
    item: Entity
    ref?: RefObject<ListEntry>
    selected: boolean

    constructor(item: any, ref: RefObject<ListEntry>) {
        this.item = item
        this.ref = ref
        this.selected = false
    }
}

interface IProps extends ListTypeProps {
    definitions: ColumnDefinition[]
    searchTerms?: string
}

interface IState extends ListTypeState {
    globalToggleStatus: boolean
    cacheKey: string
}

export default class List extends ListType<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: IProps, state: IState) {
        super(props, state)

        let sortValueContext = this.getCurrentSortValue()
        let sortValueNew = {
            property: sortValueContext.sortString as keyof Entity,
            isDescending: sortValueContext.descending
        }

        this.state = {
            globalToggleStatus: false,
            page: this.getCurrentPage(),
            cacheKey: Date.now().toString(),
            sortValue: sortValueNew
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        // Number of items changes -> reload list
        if (prevProps.items?.length !== this.props.items?.length ||
            prevState.sortValue.property !== this.state.sortValue.property ||
            prevState.sortValue.isDescending !== this.state.sortValue.isDescending) {

            // Deselect all items
            this.toggleAllElements(false)

            // Tell the outer list that no items are selected
            this.props.onSelectionChange?.([])
        }

        if (prevProps.selectedItems.length > 0 && this.props.selectedItems.length === 0) {
            this.setState({globalToggleStatus: false})
        }

        if (prevProps.sortOptions?.sortField !== this.props.sortOptions?.sortField ||
            prevProps.sortOptions?.sortDescending !== this.props.sortOptions?.sortDescending) {

            if (this.props.sortOptions?.sortField !== undefined && this.props.sortOptions?.sortDescending !== undefined) {
                this.setSortValue(this.props.sortOptions?.sortField, this.props.sortOptions?.sortDescending)
            }
        }

        if (this.props.loading && this.props.loading !== prevProps.loading) {
            this.scrollDown()
        }
    }

    onGlobalToggleClick = (toggleStatus: boolean) => {
        // toggles all child buttons
        this.toggleAllElements(toggleStatus)
    }
    onHeaderItemClicked = (property: string) => {
        // check if this value is sortable
        if (this.props.sortOptions?.allowSort && this.props.definitions.find(item => item.logicalName === property)?.sortable) {
            let descending = false
            if (this.state.sortValue.property === property) {
                descending = !this.state.sortValue.isDescending
            }

            this.setSortValue(property, descending)
        }
    }

    getSortedItems = () => {
        let items = this.props.items ? this.props.items : []

        if (this.props.sortOptions?.onSort) {
            return items
        }

        return items.sort((itemA, itemB) => {
            return genericSort<Entity>(itemA.item, itemB.item, this.state.sortValue);
        })
    }

    toggleAllElements = (toggleStatus: boolean) => {
        const selectedItems: number[] = []
        if (toggleStatus) {
            this.props.items?.forEach((item, i) => {
                if (i < (this.props.paginationOptions.pageSize * (this.state.page + 1))) {
                    selectedItems.push(item.item.id!)
                }
            })
            this.props.onSelectionChange?.(selectedItems)
        } else {
            this.props.onSelectionChange?.([])
        }
        this.setState({globalToggleStatus: toggleStatus})
    }

    onRedirect = (id: number, area: string) => {
        this.context.setListRecords(this.getSortedItems().map(l => new ListRecord(l.item.id!, l.item["itemType"] ? l.item["itemType"] : this.props.objectType)))

        if (this.props.redirectUrl) {
            this.props.history.push(this.props.redirectUrl.replace("{0}", id.toString()).replace("{1}", area))
        }
    }

    renderArrow(property: string) {
        if (property === this.state.sortValue.property) {
            return this.state.sortValue.isDescending ? <div className={"arrow-down"}/> : <div className={"arrow-up"}/>
        }
    }

    render() {
        let footer: string | undefined = undefined
        if (this.props.onSelectionChange && this.props.allowMultiSelect && this.props.items) {
            footer = this.getFooterText()
        }

        let currentPage = this.getCurrentPage()
        let dynamicScrolling = this.props.paginationOptions.allowPagination && !this.props.paginationOptions.showPagination
        let listContainerClass = "list-container" + ((dynamicScrolling && footer === undefined) ? " list-container-scroll-height" : " list-container-no-scroll-height")

        return <div className={listContainerClass} onScroll={dynamicScrolling ? this.onScrollContent : undefined} id={"scrollContainer"}>
            <table className="list-table">
                <thead>
                <tr>
                    {this.props.onSelectionChange &&
                        <th className="list-header" align={"left"}>
                            {this.props.allowMultiSelect &&
                                <ImageToggleButton
                                    id={"list-global-toggle"}
                                    defaultStatus={this.state.globalToggleStatus}
                                    imageUrlSelected={ImagePath.getButtonUrl() + "checkmark.svg"}
                                    imageClassSelected={"row-selection-image-checked"}
                                    imageUrlUnselected={ImagePath.getButtonUrl() + "checkmark.svg"}
                                    imageClassUnselected={"row-selection-image-checked"}
                                    imageWidth={16}
                                    imageHeight={16}
                                    arrow={false}
                                    altText={this.context.translate(translations.command.select)}
                                    toggleHandler={this.onGlobalToggleClick}
                                />
                            }
                        </th>
                    }

                    {this.props.definitions.map((item) => {
                        return <th key={item.logicalName}
                                   className="list-header"
                                   align={"left"}
                                   style={{width: item.width + "%", maxWidth: item.width + "%"}}
                                   onClick={() => this.onHeaderItemClicked(item.logicalName)}
                        >
                            <div className={"list-header-item"}>
                                <div className={"list-header-item-name"}>
                                    {item.displayName}
                                </div>
                                <div className={"list-header-item-arrow"}>
                                    {this.renderArrow(item.logicalName)}
                                </div>
                            </div>
                        </th>
                    })}
                </tr>
                </thead>

                <tbody>{
                    this.getSortedItems().map((value, i) => {
                        if (this.props.paginationOptions.externalPagination === true ||
                            (i >= (this.props.paginationOptions.pageSize * this.state.page) && i < (this.props.paginationOptions.pageSize * (this.state.page + 1)))) {

                            return <ListEntry
                                key={value.item.id}
                                item={value.item}
                                searchTerms={this.props.searchTerms}
                                definitions={this.props.definitions}
                                selected={this.props.selectedItems.find(item => item === value.item.id) !== undefined}
                                onSelectItem={this.props.onSelectionChange && this.onItemSelect}
                                onUnselectItem={this.props.onSelectionChange && this.onItemUnselect}
                                onOpenItem={this.props.redirectUrl ? this.onRedirect : undefined}
                                cacheKey={this.state.cacheKey}
                                ref={value.ref}
                            />
                        }
                        return undefined
                    })
                }

                </tbody>
            </table>

            {this.props.loading &&
                <div className={"list-notification-container"}>
                    <Hint id={"list-loading"}
                          style={{width: "50%", marginTop: 20, height: 50}}
                          notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                </div>
            }

            {dynamicScrolling && !this.props.loading && this.props.items !== undefined && !this.props.paginationOptions.lastElementReached &&
                <div className={"list-load-more-link"} onClick={this.onClickLoadContent}>
                    {this.context.translate(translations.text.load_more)}
                </div>
            }

            {this.props.items && this.props.items.length === 0 &&
                <div className={"list-notification-container"}>
                    <Hint id={"list-empty"}
                          style={{width: "50%", marginTop: 20, height: 50}}
                          notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))}/>
                </div>
            }

            {this.props.newItemUrl &&
                <div className={"plus-button-container tooltip-above"}>
                    <div className={"plus-button tooltip-control"}>
                        <Link id="btnAddEntry" style={{textDecoration: "none"}} to={this.props.newItemUrl}>
                            <input type="image" alt={this.context.translate(translations.command.add)}
                                   className={"plus-button-main"}
                                   src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "plus.svg"}/>
                        </Link>
                    </div>

                    {this.props.newItemTooltip &&
                        <Tooltips
                            text={this.props.newItemTooltip}
                            translateX={15} translateY={110}/>
                    }
                </div>
            }

            {this.props.paginationOptions.showPagination &&
                <ListPagination page={currentPage}
                                pageCount={this.getPageCount()}
                                onPageChange={this.onPageChange}
                                text={footer}/>
            }
            {dynamicScrolling && footer !== undefined && footer &&
                <div className="list-pagination">
                    <ListFooter text={footer}/>
                </div>
            }
        </div>
    }
}

