import React from "react";
import {ImagePath} from "../../Framework/CategoryImage";
import {MainContext} from "../../_base/MainContext";

interface IProps {
    style?: React.CSSProperties
    close: () => void
}

interface IState {
}

export class CloseButton extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg"}
                    style={this.props.style}
                    className={"close-button"}
                    alt={"Close"}
                    draggable={"false"}
                    onContextMenu={(e) => e.preventDefault() }
                    onClick={this.props.close}
                />
    }
}
