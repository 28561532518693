import React, {RefObject} from 'react';
import {Worksheet, WorksheetMarketplaceFilter} from "../../_model/Worksheet";
import {MainContext} from "../../_base/MainContext";
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {
    DownloadMPWorksheet,
    GetMPWorksheetList,
    GetWorksheetById,
    GetWorksheetRatings,
    GetWorksheetThumbnail,
} from "../../_endpoint/WorksheetEndpoint";
import {
    AdminObjectType,
    ColumnRenderOption, Difficulty,
    EnumToEntityArray,
    EnumValueToValueNumber,
    ListStyle,
    WSPageFormat,
} from "../../Framework/Enums";
import DataList from "../../Components/List/DataList";
import AppHeader from "../../_base/AppHeader";
import {Menu, MenuContext, MenuType} from "../../Components/Menu/Menu";
import MPListItem from "./MPListItem";
import {CardAction, CardDefinition} from "../../Components/List/Card";
import {TooltipText} from "../../Components/Tooltips";
import SchoolLevel from "../../_model/SchoolLevel";
import {GetAllLanguages, GetLessonSubject, GetSchoolLevel} from "../../_endpoint/MetadataEndpoint";
import LessonSubject from "../../_model/LessonSubject";
import {ImagePath} from "../../Framework/CategoryImage";
import {RibbonButtonData} from "../../Components/Ribbon/RibbonButtonData";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {TreeView} from "../../Components/Controls/TreeView";
import SelectBox from "../../Components/Controls/SelectBox";
import Language from "../../_model/Language";
import TextBox from "../../Components/Controls/TextBox";
import CheckBox from "../../Components/Controls/CheckBox";
import Entity from "../../_model/Entity";
import Rating from "../../Components/Controls/Rating";
import {SearchFields, SearchInput} from "../../Components/Controls/SearchInput";
import {ListFilter} from "../../Components/List/ListFilter";
import {RouteComponentProps} from "react-router-dom";
import RatingList from "../../Components/Controls/RatingList";
import {WorksheetRating} from "../../_model/WorksheetRating";
import {Modal} from "../../Components/Modal";
import {ButtonInfo, ButtonList} from "../../Components/Controls/ButtonList";
import {Util} from "../../Framework/Util";
import {CloseButton} from '../../Components/Controls/CloseButton';

interface MatchParams {
    id: string
}
interface IProps extends RouteComponentProps<MatchParams> {
}

interface IState {
    selectedItem?: Worksheet
    pageSize: number
    page: number

    showFilter: boolean
    toggleFilter: boolean
    toggleSelection: boolean
    lastElementReached: boolean

    items: Worksheet[],
    schoolLevels?: SchoolLevel[]
    lessonSubjects?: LessonSubject[]
    languages?: Language[]
    formats?: Entity[]
    ratings?: WorksheetRating[]

    listItems?: MPListItem[]
    listStyle: ListStyle

    listRef: RefObject<DataList>

    loading: boolean
    showDownloadedDialog: boolean
    downloadedId?: number
}

export default class MPList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 18, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.rating), "rating", 8, false, true, ColumnRenderOption.Rating),
        new ColumnDefinition(this.context.translate(translations.fields.lesson_subject), "subject", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.difficulty), "difficulty", 8, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.school_level), "schoolLevels", 15, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.author), "createdBy", 13, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.purpose), "target", 19, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.published_on), "modifiedOn", 9, false, true, ColumnRenderOption.Date)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            showFilter: false,
            toggleFilter: false,
            toggleSelection: false,
            listStyle: ListStyle.card,
            lastElementReached: false,
            listRef: React.createRef<DataList>(),
            showDownloadedDialog: false,
            page: 0,
            pageSize: 100,
            loading: false
        }
    }

    componentDidMount() {
        this.getLessonSubjects()
        this.getLanguages()
        this.getFormats()

        this.getSchoolLevel().then(() => this.fetchData(0, false))

        if (!isNaN(+this.props.match.params.id)) {
            this.onSelectItem([+this.props.match.params.id])
        }

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = (page: number, append: boolean) => {
        if (!this.state.loading && (!this.state.lastElementReached || (this.state.lastElementReached && !append))) {
            let items = append ? this.state.items : []
            let listItems = append ? this.state.listItems : undefined

            this.setState({loading: true, items: items, listItems: listItems, page: page}, () => this.fetchWSList(page))
        }
    }
    fetchWSList = (page: number) => {
        GetMPWorksheetList(this.context.getMarketplaceFilter(), page, this.state.pageSize).then(
            (itemData) => {
                if (itemData.length > 0) {
                    let listItems = this.state.listItems || []
                    listItems = listItems.concat(itemData.map(item => {

                        let schoolLevel = ""
                        if (item.schoolLevels) {
                            schoolLevel = item.schoolLevels
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(r => r.name).join(", ")
                        }

                        let subject = ""
                        if (item.subject) {
                            subject = item.subject.name
                        }

                        let difficulty: string | undefined = undefined
                        if (item.difficulty) {
                            difficulty = this.context.translate(translations.enum.difficulty[item.difficulty.valueOf()]).toLowerCase()
                        }

                        let targetText = ""
                        let target: string[] = []
                        if (item.marketplaceTargetPupils) {
                            target.push(this.context.translate(translations.text.marketplace.target_pupils))
                        }
                        if (item.marketplaceTargetParents) {
                            target.push(this.context.translate(translations.text.marketplace.target_parents))
                        }
                        if (item.marketplaceTargetTeacher) {
                            target.push(this.context.translate(translations.text.marketplace.target_teacher))
                        }
                        if (item.marketplaceTargetSchool) {
                            target.push(this.context.translate(translations.text.marketplace.target_school))
                        }
                        if (target.length > 0) {
                            targetText = target.join(" ,")
                        }

                        return new MPListItem(
                            item.id!,
                            item.name,
                            item.modifiedOn!,
                            item.author || "Anonymer Benutzer",
                            targetText,
                            item.orientation,
                            subject,
                            difficulty,
                            schoolLevel,
                            item.thumbnail,
                            item.rating
                        )
                    }))

                    let items = this.state.items.concat(itemData)

                    this.setState({items: items, listItems: listItems, loading: false, lastElementReached: (items.length % this.state.pageSize) !== 0})
                }
                else {
                    this.setState({loading: false, lastElementReached: true})
                }
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    getFormats = () => {
        let data = EnumToEntityArray(WSPageFormat, translations.enum.ws_size, this.context.translate)
        data.splice(0, 0, new Entity(this.context.translate(translations.fields.filter.all_formats), -1))
        this.setState({formats: data})
    }
    getSchoolLevel = () => {
        return new Promise<void>((resolve, reject) => {
            GetSchoolLevel().then(
                (result) => {
                    let schoolLevelFilter = [...result]

                    let filter = this.context.getMarketplaceFilter()
                    filter.schoolLevels = schoolLevelFilter
                    this.context.setMarketplaceFilter(filter)

                    this.setState({schoolLevels: result}, () => {
                        resolve()
                    })
                },
                (error) => {
                    this.context.handleError(error)
                    reject()
                }
            )
        })
    }
    getLessonSubjects = () => {
        GetLessonSubject().then(
            (result) => {
                result.splice(0, 0, new LessonSubject(this.context.translate(translations.fields.filter.all_lesson_subjects), "", -1))
                this.setState({lessonSubjects: result})
            },
            (error) => this.context.handleError(error)
        )
    }
    getLanguages = () => {
        GetAllLanguages().then((result) => {
            result.splice(0, 0, new Language(this.context.translate(translations.fields.filter.all_languages), -1))
            this.setState({languages: result})
        })
    }

    // Function to handle show/hide of ribbon buttons
    isItemSelected = (): boolean => {
        let itemsSelected = this.state.listRef.current?.getSelectedItemCount()
        return (itemsSelected !== undefined && itemsSelected > 0);
    }
    isOnlyOneItemSelected = (): boolean => {
        let itemsSelected = this.state.listRef.current?.getSelectedItemCount()
        return (itemsSelected !== undefined && itemsSelected === 1);
    }
    // isOwnItemSelected = () : boolean => {
    //     if(this.isItemSelected()) {
    //         let selectedItems = this.state.listRef.current?.getSelectedItems()
    //
    //         if(selectedItems) {
    //             for (let i = 0; i < selectedItems.length; i++) {
    //                 let item = this.state.items.filter(item => item.id === selectedItems![i])
    //
    //                 if(!item) {
    //                     return false
    //                 }
    //                 let isOwn = item[0].createdBy?.id === Auth.getUserId()
    //
    //                 if (!isOwn) {
    //                     return false
    //                 }
    //             }
    //             return true
    //         }
    //     }
    //     return false
    // }

    loadThumbnail = (id: number) => {
        GetWorksheetThumbnail(id).then((itemThumb) => {
                let items = this.state.items
                let listItems = this.state.listItems

                let item = items.find(item => item.id === id)
                let listItem = listItems?.find(item => item.id === id)

                item && (item.thumbnail = itemThumb.thumbnail)
                listItem && (listItem.thumbnail = itemThumb.thumbnail)

                this.setState({items: items, listItems: listItems})
            },
            (_) => {
                // Ignore error (no thumbnail), default thumbnail is shown
            }
        )
    }


    // Ribbon button functions
    onDownloadItems = async (items: number[]) => {
        let notification: Notification | undefined = undefined

        try {
            let message = this.context.translate(translations.notification.worksheet_download_start).replace("%1%", items.length.toString())
            notification = Notification.handleLoading(message)
            this.context.setNotification(notification)

            let downloaded: Worksheet | undefined = undefined

            for (let i = 0; i < items.length; i++) {
                let worksheet = this.state.items.filter(item => item.id === items[i])

                if (worksheet !== undefined && worksheet.length === 1) {
                    downloaded = await DownloadMPWorksheet(worksheet[0].id);

                    //
                    // updateCount++;
                    //
                    // // Processing message
                    // let message: string = this.context.translate(translations.notification.worksheet_download_end)
                    // message = message.replace("%1%", i.toString())
                    // message = message.replace("%2%", items.length.toString())
                    // this.context.setNotification(Notification.handleLoading(message), notificationId)
                }
            }

            this.context.removeNotification(notification.id)

            this.setState({showDownloadedDialog: true, downloadedId: downloaded?.id})
        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.worksheet_not_downloaded))
        }
    }
    onDownloadItem = async (item: number) => {
        let arr = [item]
        await this.onDownloadItems(arr)
    }
    onDownloadButtonClick = async () => {
        let selectedItems = this.state.listRef.current?.getSelectedItems()

        if (selectedItems && selectedItems.length > 0) {
            await this.onDownloadItems(selectedItems)
        }
    }
    onSelectItem = (items: number[]) => {
        if (items.length === 0 || items.length > 1) {
            this.setState({selectedItem: undefined, ratings: []})
            return
        }
        this.setState({ratings: []})

        GetWorksheetById(items[0]).then((item) => {
            this.setState({selectedItem: item}, async () => {

                let item = this.state.selectedItem
                if (item === undefined) {
                    return
                }

                let itemThumb = await GetWorksheetThumbnail(this.state.selectedItem!.id)
                item.thumbnail = itemThumb.thumbnail

                let ratings = await GetWorksheetRatings(this.state.selectedItem!.id)
                item.ratingCount = ratings.length

                this.setState({selectedItem: item, ratings: ratings})
            })
        })
    }
    onToggleSelection = () => {
        let currentValue = this.state.toggleSelection
        this.setState({toggleSelection: !currentValue})
    }

    // Filter functions
    onToggleFilter = () => {
        let currentValue = this.state.toggleFilter
        this.setState({toggleFilter: !currentValue})
    }
    onShowFilter = (showFilter: boolean) => {
        this.setState({showFilter: showFilter})
    }
    onSearch = (search: string) => {
        let filter = this.context.getMarketplaceFilter()
        filter.search = search === "" ? undefined : search

        let listFilter = this.context.getListFilter(AdminObjectType.marketplace_worksheet)
        if (listFilter === undefined) {
            listFilter = new ListFilter(
                AdminObjectType.marketplace_worksheet,
                ListStyle.card,
                search,
                SearchFields.SearchField,
                []
            )
        }
        listFilter.searchString = search
        this.context.setListFilter(listFilter)

        this.context.setMarketplaceFilter(filter, () => this.onFilter())
    }
    onFilter = () => {
        this.fetchData(0, false)
    }

    onChangeFilterLanguage = (value: number) => {
        let filter = this.context.getMarketplaceFilter()
        filter.language = this.state.languages?.find(item => item.id === value && item.id > 0)
        this.context.setMarketplaceFilter(filter)
    }
    onChangeFilterEnum = (value: number, enumType: any, fieldName: string) => {
        let filter =  this.context.getMarketplaceFilter()
        filter[fieldName] = Object.values(enumType).find((e, i) => i === value)
        this.context.setMarketplaceFilter(filter)
    }
    onChangeFilterSubject = (value: number) => {
        let filter =  this.context.getMarketplaceFilter()
        filter.subject = this.state.lessonSubjects?.find(item => item.id === value && item.id > 0)
        this.context.setMarketplaceFilter(filter)
    }
    onChangeFilterSchoolLevel = (values: SchoolLevel[]) => {
        let filter =  this.context.getMarketplaceFilter()
        filter.schoolLevels = values
        this.context.setMarketplaceFilter(filter)
    }
    onChangeFilterFlag = (name: string) => {
        let filter =  this.context.getMarketplaceFilter()
        filter[name] = !filter[name]
        this.context.setMarketplaceFilter(filter)
    }
    onResetFilter = () => {
        let filter = WorksheetMarketplaceFilter.initFilter()
        filter.schoolLevels = this.state.schoolLevels
        this.context.setMarketplaceFilter(filter, () => this.onFilter())
    }

    onSubmitDownloadedDialog = () => {
        if (this.state.downloadedId) {
            this.props.history.push("/designer/" + this.state.downloadedId)
        }

        this.onCloseDownloadedDialog()
    }
    onCloseDownloadedDialog = () => {
        this.setState({showDownloadedDialog: false, downloadedId: undefined})
    }

    renderCardContent = (item: MPListItem) => {
        const thumb = item.thumbnail
            ? (ImagePath.getThumbnailUrl() + item.thumbnail)
            : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"

        return <>
                <img src={thumb}
                     alt={"Preview"}
                     className={"card-thumbnail" + (item.orientation ? "" : " card-thumbnail-landscape")}
                     onContextMenu={(e) => e.preventDefault()}
                />
                <div className={"card-title text-cut-ellipsis"}>{item.name}</div>
                <div className={"card-text text-cut-ellipsis"} style={{marginBottom: 0}}>
                    <div className={"card-text-detail"}>
                        {item.subject}
                        {item.difficulty &&
                            " (" + item.difficulty + ")"
                        }
                    </div>
                </div>
                <div className={"card-text text-cut-ellipsis"}>
                    <div className={"card-text-detail"}>
                        {Util.formatDate(item.modifiedOn, false)}
                        {item.rating &&
                            <Rating rating={item.rating} iconSize={12}/>
                        }
                    </div>
                </div>
        </>
    }
    render() {
        let cardActions: CardAction[] = []
        let cardDefinition = new CardDefinition(this.renderCardContent, "orientation")

        cardActions.push(
            new CardAction(1,
                "download.svg",
                new TooltipText(this.context.translate(translations.toolbar.download), this.context.translate(translations.tooltip.download_worksheet)),
                this.onDownloadItem))

        let targetGroups: string[] = []
        let filter = this.context.getMarketplaceFilter()

        if (this.state.selectedItem) {
            if (this.state.selectedItem.marketplaceTargetPupils) {
                targetGroups.push(this.context.translate(translations.text.marketplace.target_pupils))
            }
            if (this.state.selectedItem.marketplaceTargetParents) {
                targetGroups.push(this.context.translate(translations.text.marketplace.target_parents))
            }
            if (this.state.selectedItem.marketplaceTargetTeacher) {
                targetGroups.push(this.context.translate(translations.text.marketplace.target_teacher))
            }
            if (this.state.selectedItem.marketplaceTargetSchool) {
                targetGroups.push(this.context.translate(translations.text.marketplace.target_school))
            }
        }

        return <div className="app-main-content">
            <AppHeader isAdminArea={false} app={this.context.translate(translations.app.marketplace)}/>
            <div className="admin-list-main">
                <div className="menu-container">
                    <Menu menuType={MenuType.app} menuContext={MenuContext.app}/>
                </div>

                <div className="marketplace-content">

                    <div className="marketplace-list">
                        <DataList
                            objectType={AdminObjectType.marketplace_worksheet}
                            items={this.state.listItems}
                            loading={this.state.loading}
                            userList={true}
                            definitions={this.columnDefinition}
                            cardDefinition={cardDefinition}
                            cardActions={cardActions}
                            selectedEntityTextSingle={this.context.translate(translations.text.worksheet_selected)}
                            buttonShowAsList={{
                                id: "showAsList",
                                sort: 210,
                                colorPrimary: true,
                                tooltip: new TooltipText(this.context.translate(translations.command.show_as_list), this.context.translate(translations.tooltip.show_as_list)),
                                icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "listenansicht.svg",
                            }}
                            buttonShowAsCard={{
                                id: "showAsCard",
                                sort: 220,
                                colorPrimary: true,
                                tooltip: new TooltipText(this.context.translate(translations.command.show_as_card), this.context.translate(translations.tooltip.show_as_card)),
                                icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "kachelansicht.svg",
                            }}
                            customRibbonButtons={[
                                new RibbonButtonData(
                                    "download",
                                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "download.svg",
                                    100,
                                    true,
                                    this.context.translate(translations.toolbar.download),
                                    new TooltipText(this.context.translate(translations.toolbar.download), this.context.translate(translations.tooltip.download_worksheet)),
                                    this.onDownloadButtonClick,
                                    this.isItemSelected
                                ),
                                new RibbonButtonData(
                                    "report",
                                    process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "mail.svg",
                                    110,
                                    true,
                                    this.context.translate(translations.command.report_worksheet),
                                    new TooltipText(this.context.translate(translations.command.report_worksheet), this.context.translate(translations.tooltip.report_worksheet)),
                                    () => {
                                    },
                                    this.isOnlyOneItemSelected
                                )
                            ]}
                            allowStatusChangeToUserItself={false}
                            allowMultiSelect={false}
                            searchOptions={{
                                showSearch: true,
                                showSearchOptions: false,
                                typeAheadSearch: false,
                                onSearch: this.onSearch
                            }}
                            paginationOptions={{
                                allowPagination: true,
                                showPagination: false,
                                currentPage: this.state.page,
                                pageSize: this.state.pageSize,
                                lastElementReached: this.state.lastElementReached,
                                externalPagination: true,
                                onPageChange: () => this.fetchData(this.state.page + 1, true)
                            }}
                            sortOptions={{
                                allowSort: true,
                                sortField: "modifiedOn",
                                sortDescending: true
                            }}
                            filterOptions={{ externalFilter: false, toggleFilter: this.state.toggleFilter }}
                            showRowSelector={true}
                            initialListStyle={ListStyle.card}
                            ref={this.state.listRef}

                            onLoadThumbnail={this.loadThumbnail}
                            onSelectionChange={this.onSelectItem}
                            toggleSelection={this.state.toggleSelection}
                            onShowFilter={this.onShowFilter}
                            history={this.props.history}
                            location={this.props.location}
                            match={this.props.match}
                        />
                    </div>

                    {/* Filter */}
                    {this.state.selectedItem === undefined && this.state.showFilter &&
                        <div className={"marketplace-details-container"}>
                            <div className={"marketplace-details-scroll"}>
                                <div className="marketplace-details">

                                    {/* Search Field */}
                                    <div className={"form-row"}>
                                        <SearchInput onSearch={this.onSearch}
                                                     value={filter.search}
                                                     typeAheadSearch={false}
                                                     showFieldOptions={false}
                                                     width={170}
                                                     maxLength={50}
                                        />

                                        <img src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "delete_filters.svg"}
                                             className={"ws-designer-sidebar-images-filter-navigation-button"}
                                             style={{marginLeft: 10}}
                                             draggable={"false"}
                                             alt={this.context.translate(translations.command.delete_filter)}
                                             onClick={this.onResetFilter}
                                             onContextMenu={(e) => e.preventDefault() }
                                         />

                                        <div className={"ws-designer-sidebar-images-filter-navigation-button-container-right"}>
                                            <CloseButton close={this.onToggleFilter}/>
                                        </div>
                                    </div>

                                    {/* Metadata filter */}
                                    <div className={"form-row"}>
                                        <div className={"form-group"} style={{width: 200}}>

                                            {/* Subject */}
                                            <label className="label-bold"
                                                   style={{paddingTop: 15}}>{this.context.translate(translations.text.worksheet_settings.subject)}</label>
                                            <SelectBox id={"selSubject"}
                                                       width={200}
                                                       data={this.state.lessonSubjects || []}
                                                       value={filter.subject?.id || -1}
                                                       style={{padding: 0}}
                                                       required={false}
                                                       readonly={false}
                                                       positioningRow={false}
                                                       onChange={(value) => this.onChangeFilterSubject(value)}
                                            />

                                            {/* Format */}
                                            <label className="label-bold"
                                                   style={{paddingTop: 15}}>{this.context.translate(translations.text.worksheet_settings.format)}</label>
                                            <SelectBox id={"selFormat"}
                                                       width={200}
                                                       data={this.state.formats || []}
                                                       value={EnumValueToValueNumber(WSPageFormat, filter.format) || -1}
                                                       style={{padding: 0}}
                                                       required={false}
                                                       readonly={false}
                                                       positioningRow={false}
                                                       onChange={(value) => this.onChangeFilterEnum(value, WSPageFormat, "format")}
                                            />

                                            {/* Language */}
                                            <label className="label-bold"
                                                   style={{paddingTop: 15}}>{this.context.translate(translations.text.worksheet_settings.language)}</label>
                                            <SelectBox id={"selLanguage"}
                                                       width={200}
                                                       data={this.state.languages || []}
                                                       value={filter.language?.id || -1}
                                                       style={{padding: 0}}
                                                       required={false}
                                                       readonly={false}
                                                       positioningRow={false}
                                                       onChange={(value) => this.onChangeFilterLanguage(value)}
                                            />

                                            {/* Target group */}
                                            <label className="label-bold" style={{paddingTop: 15, paddingBottom: 10}}>
                                                {this.context.translate(translations.text.marketplace.target_group)}</label>
                                            <CheckBox id={"targetGroupSchool"}
                                                      label={this.context.translate(translations.text.marketplace.target_school)}
                                                      labelClass={"label"}
                                                      checked={filter.targetSchool}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("targetSchool")}
                                            />
                                            <CheckBox id={"targetGroupPupils"}
                                                      label={this.context.translate(translations.text.marketplace.target_pupils)}
                                                      labelClass={"label"}
                                                      checked={filter.targetPupils}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("targetPupils")}
                                            />
                                            <CheckBox id={"targetGroupTeachers"}
                                                      label={this.context.translate(translations.text.marketplace.target_teacher)}
                                                      labelClass={"label"}
                                                      checked={filter.targetTeacher}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("targetTeacher")}
                                            />
                                            <CheckBox id={"targetGroupParents"}
                                                      label={this.context.translate(translations.text.marketplace.target_parents)}
                                                      labelClass={"label"}
                                                      checked={filter.targetParents}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("targetParents")}
                                            />
                                        </div>
                                        <div className={"form-group"} style={{width: 250, paddingLeft: 20}}>

                                            {/* School level */}
                                            <label className="label-bold" style={{paddingTop: 10}}>
                                                {this.context.translate(translations.text.worksheet_settings.school_level)}</label>

                                            <CheckBox id={"noSchoolLevel"}
                                                      label={this.context.translate(translations.text.marketplace.no_school_level)}
                                                      labelClass={"label"}
                                                      checked={filter.noSchoolLevel}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, paddingTop: 5, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("noSchoolLevel")}
                                            />

                                            <TreeView
                                                id={"lstSubSchoolLevel"}
                                                data={this.state.schoolLevels || []}
                                                selectedData={filter.schoolLevels || []}
                                                required={false}
                                                readonly={false}
                                                width={250}
                                                parent={"parent"}
                                                onChange={this.onChangeFilterSchoolLevel}
                                            />
                                        </div>
                                    </div>

                                    {/* Filter flags */}
                                    <div className={"form-row form-separator"} style={{marginBottom: 0}}>
                                        <div className={"form-group"} style={{width: 224, flexShrink: 0}}>
                                            <CheckBox id={"onlyMy"}
                                                      label={this.context.translate(translations.text.marketplace.my_worksheets)}
                                                      labelClass={"label"}
                                                      checked={filter.onlyMyWorksheets}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("onlyMyWorksheets")}
                                            />

                                            <CheckBox id={"blackAndWhite"}
                                                      label={this.context.translate(translations.text.worksheet_settings.black_white)}
                                                      labelClass={"label"}
                                                      checked={filter.blackAndWhite}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("blackAndWhite")}
                                            />
                                        </div>
                                        <div className={"form-group"}>
                                            <CheckBox id={"editingAllowed"}
                                                      label={this.context.translate(translations.text.marketplace.editing_allowed)}
                                                      labelClass={"label"}
                                                      checked={filter.isEditingAllowed}
                                                      readonly={false}
                                                      required={false}
                                                      style={{padding: 0, width: 200}}
                                                      onChange={() => this.onChangeFilterFlag("isEditingAllowed")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Search Button */}
                            <div className={"marketplace-filter-buttons"}>
                                <div className={"form-row form-separator"}
                                     style={{marginBottom: 0, justifyContent: "flex-end"}}>

                                    <ButtonList
                                        buttons={[new ButtonInfo(
                                            "marketplaceSearch",
                                            "button button-save",
                                            "button",
                                            true,
                                            false,
                                            this.context.translate(translations.command.search),
                                            this.onFilter,
                                            {marginRight: 20}
                                        )]}
                                        buttonGroupStyle={{width: "100%"}}
                                        containerStyle={{width: "100%", paddingRight: 10}}
                                        loading={this.state.loading}/>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Details */}
                    {this.state.selectedItem &&
                        <div className={"marketplace-details-container"}>
                            <div className={"marketplace-details-scroll"}>
                                <div className="marketplace-details">
                                    <div className={"ws-designer-sidebar-images-filter-navigation-button-container-right"}>
                                        <CloseButton close={() => this.onToggleSelection()}/>
                                    </div>
                                    <div className={"marketplace-details-thumbnail "}>
                                        <img src={this.state.selectedItem.thumbnail
                                            ? ("data:image/jpeg;base64," + this.state.selectedItem.thumbnail)
                                            : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"}
                                             alt={"Preview"}
                                             className={"card-thumbnail" + (this.state.selectedItem.orientation ? "" : " card-thumbnail-landscape")}
                                             onContextMenu={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className="marketplace-details-content">
                                        <div className={"form-row"} style={{marginBottom: 0}}>
                                            <div className={"form-group"} style={{paddingBottom: 0}}>
                                                {/* Title */}
                                                <div className={"label-bold"} style={{marginBottom: "2px"}}>
                                                    {this.state.selectedItem.name}
                                                </div>

                                                {/* Description */}
                                                <div style={{marginBottom: "5px"}}>
                                                    {this.state.selectedItem.description}
                                                </div>

                                                {/* Author */}
                                                {this.state.selectedItem.author &&
                                                    <div style={{marginBottom: "5px"}}>
                                                        {this.context.translate(translations.text.from)} {this.state.selectedItem.author}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={"form-row"}>
                                            <div className={"form-group"} style={{width: 200}}>
                                                {/* Number of Pages */}
                                                <div>
                                                    {this.state.selectedItem.pages?.length || 1}
                                                    &nbsp;
                                                    {(this.state.selectedItem.pages?.length || 1) > 1 ? this.context.translate(translations.text.pages) : this.context.translate(translations.text.page)}
                                                </div>

                                                {/* Subject */}
                                                <label className="label-bold"
                                                       style={{paddingTop: 20}}>{this.context.translate(translations.text.worksheet_settings.subject)}</label>
                                                <SelectBox id={"selSubject"}
                                                           width={200}
                                                           data={this.state.lessonSubjects || []}
                                                           value={this.state.selectedItem.subject?.id}
                                                           style={{padding: 0}}
                                                           required={false}
                                                           readonly={true}
                                                           positioningRow={false}
                                                />

                                                {/* Format */}
                                                <label className="label-bold"
                                                       style={{paddingTop: 15}}>{this.context.translate(translations.text.worksheet_settings.format)}</label>
                                                <SelectBox id={"selFormat"}
                                                           width={200}
                                                           data={EnumToEntityArray(WSPageFormat, translations.enum.ws_size, this.context.translate)}
                                                           value={EnumValueToValueNumber(WSPageFormat, this.state.selectedItem.format)}
                                                           style={{padding: 0}}
                                                           required={false}
                                                           readonly={true}
                                                           positioningRow={false}
                                                />

                                                {/* Language */}
                                                <label className="label-bold"
                                                       style={{paddingTop: 15}}>{this.context.translate(translations.text.worksheet_settings.language)}</label>
                                                <SelectBox id={"selLanguage"}
                                                           width={200}
                                                           data={this.state.languages || []}
                                                           value={this.state.selectedItem.language?.id}
                                                           style={{padding: 0}}
                                                           required={false}
                                                           readonly={true}
                                                           positioningRow={false}
                                                />

                                                {/* Difficulty */}
                                                <label className="label-bold"
                                                       style={{paddingTop: 15}}>{this.context.translate(translations.fields.difficulty)}</label>
                                                <SelectBox id={"selDifficulty"}
                                                           width={200}
                                                           data={EnumToEntityArray(Difficulty, translations.enum.difficulty, this.context.translate)}
                                                           value={EnumValueToValueNumber(Difficulty, this.state.selectedItem.difficulty)}
                                                           style={{padding: 0}}
                                                           required={false}
                                                           readonly={true}
                                                           positioningRow={false}
                                                />
                                            </div>
                                            <div className={"form-group"} style={{width: 250, paddingLeft: 20}}>
                                                {/* Editing allowed */}
                                                <div>
                                                    {this.state.selectedItem.marketplaceIsEditingAllowed ?
                                                        this.context.translate(translations.text.marketplace.editing_allowed) :
                                                        this.context.translate(translations.text.marketplace.editing_not_allowed)
                                                    }
                                                </div>

                                                {/* School level */}
                                                <label className="label-bold"
                                                       style={{paddingTop: 20}}>{this.context.translate(translations.text.worksheet_settings.school_level)}</label>
                                                <TreeView
                                                    id={"lstSubSchoolLevel"}
                                                    data={this.state.schoolLevels || []}
                                                    selectedData={this.state.selectedItem.schoolLevels || []}
                                                    required={false}
                                                    readonly={true}
                                                    width={250}
                                                    style={{paddingTop: 5}}
                                                    parent={"parent"}
                                                />
                                            </div>
                                        </div>

                                        <div className={"form-row"}>
                                            <div className={"form-group"} style={{flexGrow: 1}}>
                                                {/* Target group */}
                                                <label className="label-bold">{this.context.translate(translations.text.marketplace.target_group)}</label>
                                                <TextBox id={"txtTargetGroup"}
                                                         value={targetGroups.join(", ")}
                                                         multiline={true}
                                                         style={{padding: 0, paddingTop: 5}}
                                                         required={false}
                                                         readonly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className={"form-row"}>
                                            <div className={"form-group"} style={{flexGrow: 1}}>
                                                <label
                                                    className="label-bold">{this.context.translate(translations.text.worksheet_settings.keywords)}</label>
                                                <TextBox id={"tags"}
                                                         data={this.state.selectedItem.tags}
                                                         tags={this.state.selectedItem.tags}
                                                         value={""}
                                                         required={false}
                                                         readonly={true}
                                                         mode={{removeInputField: true, tags: true}}
                                                         style={{padding: 0, paddingTop: 5}}
                                                />
                                            </div>
                                        </div>

                                        <div className={"form-row"} style={{marginBottom: 10}}>
                                            <div className={"form-group"} style={{flexGrow: 1}}>
                                                <Rating rating={this.state.selectedItem.rating || 0}
                                                        legend={(this.state.selectedItem.rating || 0).toFixed(1) + " (" + (this.state.selectedItem.ratingCount || 0) + " " + this.context.translate(translations.text_fragment.ratings) + ")"}
                                                        iconSize={20}
                                                        fontSize={14}
                                                />
                                            </div>
                                        </div>
                                        <div className={"form-row"}>
                                            <div className={"form-group"} style={{flexGrow: 1}}>
                                                <RatingList ratings={this.state.ratings || []}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Download Button */}
                            <div className={"marketplace-filter-buttons"}>
                                <div className={"form-row form-separator"}
                                     style={{marginBottom: 0, justifyContent: "flex-end"}}>
                                    <button id={"marketplaceDownload"}
                                            className={"button button-save"}
                                            style={{marginRight: 20, width: 150}}
                                            type={"button"}
                                            onClick={() => this.onDownloadItem(this.state.selectedItem!.id)}>
                                        {this.context.translate(translations.toolbar.download)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.showDownloadedDialog && this.state.showDownloadedDialog &&
                        <Modal id={"confirmationDialog"}
                               onFormSubmit={this.onSubmitDownloadedDialog}
                               onFormCancel={this.onCloseDownloadedDialog}
                               title={this.context.translate(translations.text.marketplace.downloaded_title)}
                               buttons={[
                                   new ButtonInfo("btnCancel", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onCloseDownloadedDialog, {}),
                                   new ButtonInfo("btnSave", "button button-save", "submit", true, false, this.context.translate(translations.command.ok), undefined, {marginLeft: "10px"})
                               ]}
                               dialogStyle={{width: "25%", height: "220px", minWidth: "500px"}}
                               contentAlignment={"flex-start"}
                        >
                            {this.context.translate(translations.text.marketplace.downloaded_description)}
                        </Modal>
                    }
                </div>
            </div>
        </div>
    }
}

