import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {AdminObjectType, ColumnRenderOption, Status} from "../../Framework/Enums";
import {Notification} from "../../Components/Notification/NotificationHandler";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import Exercise from "../../_model/Exercise";
import {CloneExercise, GetExercise, GetExerciseListByParent, UpdateExercise} from "../../_endpoint/ExerciseEndpoint";
import User from "../../_model/User";
import Const from "../../Framework/Const";
import {RouteComponentProps} from "react-router-dom";
import TextExerciseChildListItem from "./TextExerciseChildListItem";
import {RibbonButtonData} from "../../Components/Ribbon/RibbonButtonData";
import {ImagePath} from "../../Framework/CategoryImage";
import {WSContextType} from "../../Designer/Elements/WSContext";
import {AdminUtils} from "../Maintenance/AdminUtils";

interface MatchParams {
    id: string
}

export interface MatchProps extends RouteComponentProps<MatchParams> { }

interface IState {
    items: Exercise[]
    pageSize: number
    parent?: Exercise
    listItems?: TextExerciseChildListItem[]
}

export default class TextExerciseChildList extends React.Component<MatchProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 25, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise.difficulty), "difficulty", 15, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.exercise.menuRelation), "menuRelation", 20, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 13, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.owner), "owner", 14, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 13, false, true, ColumnRenderOption.DateTime)
    ]
    constructor(props: MatchProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            pageSize: 100
        }
    }

    componentDidMount() {
        this.context.log.info("ID = " + this.props.match.params.id)
        this.context.log.flush()

        this.fetchData()

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = () => {
        this.setState({ items: [] })

        let parent = +this.props.match.params.id

        GetExercise(parent).then(
            (itemData) => {
                this.setState({ parent: itemData })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )

        GetExerciseListByParent(parent).then(
            (itemData) => {

                const listItems = itemData.map(item => {
                    // Status-Text (translated)
                    let status = translations.enum.status[item.status]

                    let menuRelation = ""
                    if(item.menuEntries) {
                        menuRelation = item.menuEntries
                            .map(r => r.name).join(", ")
                    }

                    let owner = ""
                    if (item.ownerId) {
                        owner = item.ownerId.firstname + " " + item.ownerId.lastname
                    }

                    return new TextExerciseChildListItem(
                        item.id!,
                        item.name,
                        this.context.translate(status),
                        item.difficulty ? this.context.translate(translations.enum.difficulty[item.difficulty]) : "",
                        menuRelation,
                        owner,
                        item.modifiedOn || item.createdOn!
                    )
                })

                this.setState({ items: itemData, listItems: listItems })
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    onCopyItem = async(items: number[]) => {
        try {
            for (const id of items) {
                const exercise = await GetExercise(id)
                if (exercise && exercise.status !== Status.deactivated) {

                    let clone: Exercise = { ...exercise }
                    clone.id = undefined
                    clone.status = Status.draft

                    await CloneExercise(clone, clone.name + " - " + this.context.translate(translations.text_fragment.copy), WSContextType.text_exercise_child)
                }
            }

            this.fetchData()
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.saved)))
        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
        }
    }

    onSetStatus = async(item: number, status: Status, user?: User) => {
        try {
            const exercise = this.state.items.find(d => d.id === item)
            if (exercise) {
                Exercise.setStatus(exercise, status, user)
                await UpdateExercise(exercise)

                return true
            }
        } catch (e) { }

        return false
    }
    onRefresh = () => {
        this.fetchData()
    }
    onEditParent = () => {
        this.props.history.push(AdminUtils.getObjectTypeUrl(AdminObjectType.text_exercise_main) + this.state.parent?.id)
    }
    onBackToList = () => {
        this.props.history.push(AdminUtils.getObjectTypeUrl(AdminObjectType.text_exercise_main))
    }

    renderHeader = () => {
        return <div className={"list-header-card"}>
            {this.state.parent?.name}
            {this.state.parent && this.state.parent?.status !== Status.published &&
                <label className={"bold-label"}>{" - " + this.context.translate(translations.enum.status[this.state.parent?.status])}</label>
            }
        </div>
    }

    render() {
        let newItemUrl: string | undefined = undefined
        if (this.state.parent?.status === Status.published) {
            newItemUrl = AdminUtils.getObjectTypeUrl(AdminObjectType.text_exercise_main) + this.state.parent?.id + "/" + Const.CreateNewDataUrl
        }

        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              newItemUrl={newItemUrl}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.text_exercise_child) + "{0}"}
                              objectType={AdminObjectType.text_exercise_child}
                              onCopyItem={this.onCopyItem}
                              onSetStatus={this.onSetStatus}
                              onRefresh={this.onRefresh}
                              renderHeader={this.renderHeader}
                              paginationOptions={{
                                  allowPagination: true,
                                  showPagination: true,
                                  pageSize: this.state.pageSize
                              }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "name",
                                  sortDescending: false
                              }}
                              filterOptions={{ externalFilter: false }}
                              customRibbonButtons={[
                                  new RibbonButtonData(
                                      "edit_main",
                                      process.env.PUBLIC_URL + ImagePath.getMenuUrl() + "exercise.svg",
                                      1,
                                      true,
                                      "Hauptaufgabe bearbeiten",
                                      undefined,
                                      this.onEditParent
                                  ),
                                  new RibbonButtonData(
                                      "back_to_list",
                                      process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "arrow_previous.svg",
                                      2,
                                      true,
                                      "Zurück zur Liste",
                                      undefined,
                                      this.onBackToList
                                  )
                              ]}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={true}
                              showActivate={true}
                              showDeactivate={true}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}

