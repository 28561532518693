import React from 'react';
import {MainContext} from "../../_base/MainContext";

interface IProps {
    text: string
}

export default class ListFooter extends React.Component<IProps> {
    
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    render() {
        return <div className={"list-footer"}>
                    {this.props.text}
                </div>
    }
}

