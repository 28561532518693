import {SidebarContentBaseListFilter} from "../../Designer/Sidebar/SidebarContentBase";
import {
    ImageAI,
    ImageAlignment,
    ImageBackground,
    ImageColor,
    ImageLicense,
    ImageProperty
} from "../../_model/Image";

export class ImageListFilter extends SidebarContentBaseListFilter {
    originatorId: number
    collectionId: number
    alignments: ImageAlignment[]
    properties: ImageProperty[]
    colors: ImageColor[]
    backgrounds: ImageBackground[]
    licenses: ImageLicense[]
    aiGenerated?: ImageAI[]
    pageFrame?: boolean

    static all_alignments = [ImageAlignment.quadratic, ImageAlignment.portrait, ImageAlignment.landscape, ImageAlignment.panorama]
    static all_properties = [ImageProperty.vector, ImageProperty.photo, ImageProperty.illustration]
    static all_colors = [ImageColor.color, ImageColor.black_white, ImageColor.coloring_picture, ImageColor.contouring]
    static all_colors_except_contouring = [ImageColor.color, ImageColor.black_white, ImageColor.coloring_picture]
    static all_backgrounds = [ImageBackground.withBackground, ImageBackground.withoutBackground]
    static all_licenses = [ImageLicense.free, ImageLicense.license]
    static all_ai = [ImageAI.aiGenerated, ImageAI.manualGenerated]

    constructor(search: string,
                originatorId: number,
                collectionId: number,
                alignments: ImageAlignment[],
                properties: ImageProperty[],
                colors: ImageColor[],
                backgrounds: ImageBackground[],
                licenses: ImageLicense[],
                aiGenerated: ImageAI[]) {
        super()

        this.search = search
        this.originatorId = originatorId
        this.collectionId = collectionId
        this.alignments = alignments
        this.properties = properties
        this.colors = colors
        this.backgrounds = backgrounds
        this.licenses = licenses
        this.aiGenerated = aiGenerated
    }

    static initFilter() {
        return new ImageListFilter("", -1, -1,
            ImageListFilter.all_alignments, ImageListFilter.all_properties, ImageListFilter.all_colors_except_contouring,
            ImageListFilter.all_backgrounds, ImageListFilter.all_licenses, ImageListFilter.all_ai)
    }
    static initWritingCourseImageFilter() {
        return new ImageListFilter("Buchstabe", -1, -1,
            ImageListFilter.all_alignments, ImageListFilter.all_properties, ImageListFilter.all_colors_except_contouring,
            ImageListFilter.all_backgrounds, ImageListFilter.all_licenses, ImageListFilter.all_ai)
    }
}
