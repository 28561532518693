import React from 'react';
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {
    AdminObjectType,
    EnumToEntityArray,
    EnumToListFilterDefinitionItem,
    WSMarketplaceStatus, WSStatus
} from "../../Framework/Enums";
import AdminDataList from "../Maintenance/AdminDataList";
import {MainContext} from "../../_base/MainContext";
import {AdminUtils} from "../Maintenance/AdminUtils";
import {Worksheet} from "../../_model/Worksheet";
import MPWorksheetListItem from "./MPWorksheetListItem";
import {GetMPWorksheetListAdmin} from "../../_endpoint/WorksheetEndpoint";
import {ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import Entity from "../../_model/Entity";
import {RouteComponentProps} from "react-router-dom";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Worksheet[]
    listItems?: MPWorksheetListItem[]
    pageSize: number
    filterDefinition: ListFilterDefinition[]
}

export default class MPWorksheetList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 30, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.mp_status), "marketplaceStatus", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.lesson_subject), "lessonSubject", 15, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.school_level), "schoolLevel", 35, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.submitted_by), "submittedBy", 10, false, true),
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            pageSize: 100,
            filterDefinition: this.getFilterDefinition()
        }
    }

    componentDidMount() {
        this.fetchData()

        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })
    }

    fetchData = () => {
        this.setState({items: []})

        GetMPWorksheetListAdmin().then(
            (itemData) => {
                const listItems = itemData.map(item => {
                    let schoolLevel = ""
                    if (item.schoolLevels) {
                        schoolLevel = item.schoolLevels
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(r => r.name).join(", ")
                    }

                    let submittedBy = ""
                    if (item.ownerId) {
                        submittedBy = item.ownerId.firstname + " " + item.ownerId.lastname
                    }
                    else if (item.createdBy) {
                        submittedBy = item.createdBy.firstname + " " + item.createdBy.lastname
                    }

                    return new MPWorksheetListItem(
                        item.id!,
                        item.name,
                        this.context.translate(translations.enum.ws_status[item.status]),
                        item.marketplaceStatus ? this.context.translate(translations.enum.marketplace_ws_status[item.marketplaceStatus]) : "",
                        item.subject ? item.subject.name : "",
                        schoolLevel,
                        submittedBy
                    )
                })

                this.setState({items: itemData, listItems: listItems})
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.loading_error))
            }
        )
    }

    getFilterDefinition = () : ListFilterDefinition[] => {
        let filterDefinition: ListFilterDefinition[] = []

        let statusValues: ListFilterDefinitionItem[] = EnumToListFilterDefinitionItem(WSStatus, Object.values(WSStatus), translations.enum.ws_status, this.context.translate)

        filterDefinition.push(new ListFilterDefinition(
            "status",
            statusValues,
            this.onFilterStatus,
            0))

        let marketplaceStatusValues: ListFilterDefinitionItem[] = EnumToListFilterDefinitionItem(WSMarketplaceStatus, Object.values(WSMarketplaceStatus), translations.enum.marketplace_ws_status, this.context.translate)
        marketplaceStatusValues.unshift(new ListFilterDefinitionItem(-1,this.context.translate(translations.fields.filter.all_status)))

        filterDefinition.push(new ListFilterDefinition(
            "marketplaceStatus",
            marketplaceStatusValues,
            this.onFilterMarketplaceStatus,
            -1))

        return filterDefinition
    }

    onFilterStatus = (items: Entity[], value: number) : Entity[] => {
        let statusArray = EnumToEntityArray(WSStatus, translations.enum.ws_status, this.context.translate)
        let definition = statusArray.find(d => d.id === value)

        if (definition) {
            return items.filter(item => item["status"] === definition!.name)
        }

        return items
    }
    onFilterMarketplaceStatus = (items: Entity[], value: number) : Entity[] => {
        let statusArray = EnumToEntityArray(WSMarketplaceStatus, translations.enum.marketplace_ws_status, this.context.translate)
        let definition = statusArray.find(d => d.id === value)

        if (definition) {
            return items.filter(item => item["marketplaceStatus"] === definition!.name)
        }
        else {
            return items.filter(item => item["marketplaceStatus"] !== this.context.translate(translations.enum.marketplace_ws_status.rejected))
        }
    }
    onRefresh = () => {
        this.fetchData()
    }

    render() {
        return <AdminDataList items={this.state.listItems}
                              definitions={this.columnDefinition}
                              redirectUrl={AdminUtils.getObjectTypeUrl(AdminObjectType.marketplace_worksheet) + "{0}"}
                              objectType={AdminObjectType.marketplace_worksheet_admin}
                              onRefresh={this.onRefresh}
                              allowStatusChangeToUserItself={false}
                              allowMultiSelect={false}
                              showActivate={false}
                              showDeactivate={false}
                              paginationOptions={{
                                  allowPagination: true,
                                  showPagination: true,
                                  pageSize: this.state.pageSize
                              }}
                              searchOptions={{ showSearch: true, typeAheadSearch: true, showSearchOptions: false }}
                              sortOptions={{
                                  allowSort: true,
                                  sortField: "modifiedOn",
                                  sortDescending: true
                              }}
                              filterOptions={{
                                  externalFilter: false,
                                  filterDefinitions: this.state.filterDefinition,
                                  removeDefaultFilter: true
                              }}
                              history={this.props.history}
                              location={this.props.location}
                              match={this.props.match}
        />
    }
}
