import React from "react";
import {MenuContentType, MenuItem, MenuItemText} from "../../_model/MenuItem";
import {ImageToggleButton} from "../Controls/ImageToggleButton";
import {LastLevelItem, MenuEntry} from "./MenuEntry";
import {GetMenuGroups} from "../../_endpoint/MenuEndpoint";
import {getMenuItemCSS, MenuContext, MenuExerciseType, MenuType} from "./Menu";
import {Link} from "react-router-dom";
import {Tooltips, TooltipText} from "../Tooltips";
import translations from "../../Framework/translations.json";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../Framework/CategoryImage";
import {SearchInput} from "../Controls/SearchInput";
import {MainContext} from "../../_base/MainContext";
import {WDWritingLineatureData} from "../../Designer/Elements/Lineature/WritingLineature/WDWritingLineature";
import {WDMathLineatureData} from "../../Designer/Elements/Lineature/MathLineature/WDMathLineatureData";
import {WDTextExerciseData} from "../../Designer/Elements/TextExercise/WDTextExerciseData";
import {WorksheetItemTypeEnum} from "../../_model/WorksheetItemType";
import {GetFilteredWritingCourseList} from "../../_endpoint/WritingCourseEndpoint";
import {WritingCourseFilter} from "../../_model/WritingCourseFilter";
import {GetWorksheetThumbnail} from "../../_endpoint/WorksheetEndpoint";
import {Hint, NotificationData} from "../Notification/Hint";
import {ExerciseType, NotificationStatus} from "../../Framework/Enums";
import LessonSubject from "../../_model/LessonSubject";
import {GetImagesByToolboxItem} from "../../_endpoint/ImageEndpoint";
import {Image, ImageAlignment, ImageToolboxFilter} from "../../_model/Image";
import {WDUtils} from "../../Designer/Utils/WDUtils";
import {ExerciseFilter} from "../../_model/ExerciseFilter";
import {GetFilteredExerciseLookupList} from "../../_endpoint/ExerciseEndpoint";
import {RuleFilter} from "../../_model/RuleFilter";
import {GetFilteredRuleList} from "../../_endpoint/RuleEndpoint";
import RuleCategory from "../../_model/RuleCategory";
import RuleType from "../../_model/RuleType";

export enum CollapseState {
    open,
    manualCollapsed,
    automaticCollapsed
}

export class CallstackItem {
    item: MenuItem
    closeMenu?: () => void

    constructor(item: MenuItem, closeMenu?: () => void) {
        this.item = item
        this.closeMenu = closeMenu
    }
}

interface IProps {
    level: number
    isCollapsable: boolean
    menuType: MenuType
    menuContext: MenuContext

    className: string
    collapseState: CollapseState

    callstack: CallstackItem[]
    parentItem?: MenuItem
    lastLevel: boolean

    onItemClicked?: (item?: MenuItem) => void
    onBack?: () => void
    closeMenu?: () => void

    isEditingAllowed?: boolean
}
interface IState {
    search: string
    burgerButtonStatus: boolean
    clickedItem?: MenuItem

    items?: MenuItem[]
    lastLevelItemIcon?: LastLevelItem
    pageNumber?: number
    lastImage: boolean

    collapseState: CollapseState
    loading: boolean
}

export class MenuLevel extends React.Component<IProps, IState>{
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    PAGE_SIZE: number = 50

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            burgerButtonStatus: true,
            collapseState: props.collapseState,
            search: "",
            loading: false,
            lastImage: false
        }
    }
    componentDidMount() {
        // Do not fetch menu items because they are not needed if the worksheet is not allowed to be changed
        if(this.props.isEditingAllowed !== undefined && !this.props.isEditingAllowed) {
            return
        }

        (this.props.lastLevel && this.props.menuContext === MenuContext.toolbox) ? this.mapMenuItems() : this.fetchData()
    }
    // Parent has changed, reload toolbox completely
    componentDidUpdate(prevProps: IProps) {
        if(this.props.parentItem === prevProps.parentItem) {
            return
        }

        // only get data from database when it is not the last level otherwise get data from category image
        (this.props.lastLevel && this.props.menuContext === MenuContext.toolbox) ? this.mapMenuItems() : this.fetchData()
    }
    fetchData() {
        this.setState({loading: true}, () => {
            GetMenuGroups(this.props.menuType, this.props.menuContext, this.props.parentItem).then(
                (itemData) => {

                    this.setState({
                        items: itemData,
                        clickedItem: undefined,
                        search: "",
                        loading: false
                    })
                },
                (error) => {
                    this.context.handleError(error, this.context.translate(translations.notification.menu_not_loaded))
                }
            )
        })
    }

    onScrollContent = (e: React.UIEvent<HTMLDivElement>) => {
        if (this.props.lastLevel && this.state.pageNumber !== undefined && !this.state.lastImage) {
            let element = e.target as HTMLDivElement

            if (element.scrollTop + 10 >= (element.scrollHeight - element.offsetHeight) && !this.state.loading) {
                this.context.log.info("Loading page: " + (this.state.pageNumber + 1))
                this.context.log.flush()

                this.mapMenuItems()
            }
        }
    }
    onSearchLoadContent = () => {
        if (this.props.lastLevel && !this.state.lastImage && this.props.parentItem?.worksheetItemTypeId === WorksheetItemTypeEnum.IMAGE) {
            this.mapMenuItems()
        }
    }

    async mapMenuItems() {
        if(this.props.parentItem?.worksheetItemTypeId) {
            let categoryImages, categoryImageId, categoryImagePath, category, lastLevelItemIcon, categoryImagePlaceholder
            let loading = false
            let pageNumber : number | undefined = undefined

            categoryImageId = Date.now()

            switch (this.props.parentItem.worksheetItemTypeId) {
                case WorksheetItemTypeEnum.BALLOON:
                    if(this.props.parentItem.configData) {
                        let balloonConfig = JSON.parse(this.props.parentItem.configData)
                        category = +ImageCategory[balloonConfig.imageCategory]
                        categoryImages = CategoryImageValue.getImagesByCategory(category)
                    }
                    categoryImagePath = ImagePath.getBalloonUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.WRITING_LINEATURE:
                    category = ImageCategory.WRITING_LINEATURE_THUMBNAIL_EVENLY

                    if(this.props.parentItem.configData) {
                        let lineatureConfig = JSON.parse(this.props.parentItem.configData)
                        category = WDWritingLineatureData.getImageCategory(lineatureConfig.lineatureSize)
                        categoryImages = CategoryImageValue.getImagesByCategory(category)
                    }

                    categoryImagePath = ImagePath.getLineatureUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 70, 10, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.MATH_LINEATURE:
                    category = ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY

                    if(this.props.parentItem.configData) {
                        let lineatureConfig = JSON.parse(this.props.parentItem.configData)
                        category = WDMathLineatureData.getImageCategory(lineatureConfig.lineatureSize)
                        categoryImages = CategoryImageValue.getImagesByCategory(category)
                    }

                    categoryImagePath = ImagePath.getLineatureUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 15, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.CALCULATION_TRIANGLE:
                    category = ImageCategory.CALCULATION_TRIANGLE
                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getTriangleUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.CALCULATION_TOWER:
                    category = ImageCategory.CALCULATION_TOWER_THUMBNAIL
                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getTowerUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130,15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.TEXT_EXERCISE:
                    category = ImageCategory.TEXT_EXERCISE_ZR10

                    if(this.props.parentItem.configData) {
                        let textExerciseConfig = JSON.parse(this.props.parentItem.configData)
                        category = WDTextExerciseData.getImageCategory(textExerciseConfig.level)
                    }

                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getTextExerciseUrl()
                    lastLevelItemIcon = new LastLevelItem(287, 135,15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.WRITING_COURSE:
                    loading = true

                    let filter = new WritingCourseFilter()

                    if(this.props.parentItem.configData) {
                        let config = JSON.parse(this.props.parentItem.configData)

                        filter.characterType = config.characterType
                        filter.previewSymbol = config.previewSymbol
                        filter.syllableMethod = config.syllableMethod
                        filter.lessonSubject = new LessonSubject("", "", config.lessonSubject)
                    }

                    GetFilteredWritingCourseList(filter).then(
                        async (writingCourses) => {
                            categoryImages = []
                            let configData: string[] = []

                            for (const course of writingCourses) {
                                filter.parentWritingCourse = course
                                configData.push(JSON.stringify(filter))

                                if (course.worksheet && course.worksheet.id) {
                                    let thumbnail = await GetWorksheetThumbnail(course.worksheet?.id);

                                    if (thumbnail && thumbnail.thumbnail) {
                                        categoryImages.push(thumbnail.thumbnail);
                                    }
                                }
                            }

                            lastLevelItemIcon = new LastLevelItem(135, 190, 15, 10, 15, "")
                            this.setMenuItemsThumbnails(categoryImages, categoryImageId, configData, lastLevelItemIcon)
                        }
                    )

                    break

                case WorksheetItemTypeEnum.IMAGE:
                    loading = true

                    let imageToolboxFilter = new ImageToolboxFilter(this.props.parentItem.id, this.state.search)
                    pageNumber = this.state.pageNumber !== undefined ? this.state.pageNumber + 1 : 0

                    let dimensions = MenuItem.getMenuImageSizeByAlignment(ImageAlignment.quadratic)
                    if(this.props.parentItem.alignment) {
                        dimensions = MenuItem.getMenuImageSizeByAlignment(this.props.parentItem.alignment)
                    }

                    GetImagesByToolboxItem(imageToolboxFilter, pageNumber, this.PAGE_SIZE).then(
                        async (images) => {
                            let lastItemReached = WDUtils.lastItemResultReached(images.length, this.PAGE_SIZE)

                            lastLevelItemIcon = new LastLevelItem(dimensions.width, dimensions.height, 15, 0, 15, "")
                            this.setMenuItemImages(images, categoryImageId, lastLevelItemIcon, pageNumber!, lastItemReached)
                        }
                    )

                    break

                case WorksheetItemTypeEnum.RULE:
                    loading = true

                    let ruleFilter = new RuleFilter()
                    ruleFilter.search = this.state.search

                    if(this.props.parentItem.configData) {
                        let config = JSON.parse(this.props.parentItem.configData)

                        ruleFilter.ruleType = new RuleType("", config.ruleType)
                        ruleFilter.lessonSubject = new LessonSubject("","", config.lessonSubject)
                        ruleFilter.ruleCategory = new RuleCategory("", 1) // = rule
                    }

                    GetFilteredRuleList(ruleFilter).then(
                        (rules) => {
                            lastLevelItemIcon = new LastLevelItem(287, 135, 15, 15, 15, "")

                            let menuItemTexts: MenuItemText[] = []
                            rules.forEach(rule => {
                                if(rule.worksheet && rule.description) {
                                    menuItemTexts.push(new MenuItemText(rule.worksheet?.id, rule.name, rule.description))
                                }
                            })

                            this.setMenuItemText(menuItemTexts, lastLevelItemIcon, categoryImageId)
                        }
                    )
                    break

                case WorksheetItemTypeEnum.TEXTBOX:
                    if(this.props.parentItem.exerciseType === MenuExerciseType.automatic) {
                        categoryImagePath = ImagePath.getMenuUrl()
                        category = ImageCategory.TEXTBOX
                        categoryImages = CategoryImageValue.getImagesByCategory(category)
                        lastLevelItemIcon = new LastLevelItem(287, 135, 15, 15, 15, categoryImagePath)
                    }
                    else {
                        loading = true
                        this.getExercisePatternThumbnails(categoryImages, lastLevelItemIcon, categoryImageId)
                    }
                    break

                case WorksheetItemTypeEnum.TABLE:
                    if(this.props.parentItem.exerciseType === MenuExerciseType.automatic) {
                        categoryImagePath = ImagePath.getMenuUrl()
                        category = ImageCategory.TABLE
                        categoryImages = CategoryImageValue.getImagesByCategory(category)
                        lastLevelItemIcon = new LastLevelItem(287, 135, 15, 15, 15, categoryImagePath)
                    }
                    else {
                        loading = true
                        this.getExercisePatternThumbnails(categoryImages, lastLevelItemIcon, categoryImageId)
                    }
                    break

                case WorksheetItemTypeEnum.LINE:
                    category = ImageCategory.LINE
                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getLineUrl()
                    lastLevelItemIcon = new LastLevelItem(287, 50, 15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.SHAPE2D:
                    category = ImageCategory.FORMS_2D
                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getFormsUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.SHAPE3D:
                    category = ImageCategory.FORMS_3D
                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getFormsUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK:
                    category = ImageCategory.FORMS_BUILDING_BRICK
                    categoryImagePlaceholder = ["gelb"]
                    categoryImages = CategoryImageValue.getImagesByCategory(category, categoryImagePlaceholder)
                    categoryImagePath = ImagePath.getFormsUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 10, 15, categoryImagePath)
                    break

                case WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN:
                    category = ImageCategory.FORMS_CRAFT_PATTERN
                    categoryImages = CategoryImageValue.getImagesByCategory(category)
                    categoryImagePath = ImagePath.getFormsUrl()
                    lastLevelItemIcon = new LastLevelItem(130, 130, 15, 10, 15, categoryImagePath)
                    break
            }

            if(categoryImages !== undefined
                && categoryImageId !== undefined
                && lastLevelItemIcon !== undefined)
            {
                this.setMenuItems(categoryImages, categoryImageId, category, lastLevelItemIcon, categoryImagePlaceholder)

            } else if(loading) {
                this.setState({loading: true, pageNumber: pageNumber})
            }
        }
    }
    getExercisePatternThumbnails = (categoryImages: string[], lastLevelItemIcon: LastLevelItem, categoryImageId: number) => {
        let exerciseFilter = new ExerciseFilter(ExerciseType.exercise_pattern)
        exerciseFilter.menuEntry = this.props.parentItem

        GetFilteredExerciseLookupList(exerciseFilter).then(
            async (exercises) => {
                categoryImages = []
                let configData: string[] = []

                for (const exercise of exercises) {
                    if (exercise.worksheet && exercise.worksheet.id) {
                        configData.push(JSON.stringify({"worksheetId": exercise.worksheet.id}))

                        let thumbnail = await GetWorksheetThumbnail(exercise.worksheet.id);

                        if (thumbnail && thumbnail.thumbnail) {
                            categoryImages.push(thumbnail.thumbnail);
                        }
                    }
                }

                lastLevelItemIcon = new LastLevelItem(287, 405, 15, 10, 15, "")

                this.setMenuItemsThumbnails(categoryImages, categoryImageId, configData, lastLevelItemIcon)
            }
        )
    }
    setMenuItems = (categoryImages: string[], categoryImageId: number, category: ImageCategory,
                    lastLevelItemIcon: LastLevelItem, categoryImagePlaceholder?: string[]) => {
        let lastLevelItems = categoryImages.map(image => {
            categoryImageId++

            let categoryKey = CategoryImageValue.getKeyOfImage(category, image, categoryImagePlaceholder)
            let configData = this.props.parentItem?.configData?.substring(0, this.props.parentItem.configData?.indexOf("}"))

            if(configData) {
                configData = configData.length > 1 ? configData + ', ' : configData
                configData += '"image": "' + categoryKey + '"}'
            }

            return new MenuItem(
                true,
                categoryImageId,
                categoryKey,
                undefined,
                undefined,
                image,
                undefined,
                MenuContentType.icon,
                this.props.parentItem,
                this.props.parentItem?.worksheetItemTypeId,
                configData,
            )
        })

        this.setState({items: lastLevelItems, lastLevelItemIcon: lastLevelItemIcon, loading: false, pageNumber: undefined})
    }
    setMenuItemsThumbnails = (categoryImages: string[], categoryImageId: number, configData: string[],
                    lastLevelItemIcon: LastLevelItem) => {

        let configAtPosition = -1

        let lastLevelItems = categoryImages.map(image => {
            categoryImageId++
            configAtPosition++

            return new MenuItem(
                true,
                categoryImageId,
                "",
                undefined,
                undefined,
                image,
                undefined,
                MenuContentType.thumbnail,
                this.props.parentItem,
                this.props.parentItem?.worksheetItemTypeId,
                configData[configAtPosition],
            )
        })

        this.setState({items: lastLevelItems, lastLevelItemIcon: lastLevelItemIcon, loading: false})
    }
    setMenuItemImages = (images: Image[], categoryImageId: number, lastLevelItemIcon: LastLevelItem, pageNumber: number, lastImage: boolean) => {
        let lastLevelItems = images.map(image => {
            categoryImageId++

            let configData = '{'
            configData += '"id": "' + image.id + '"'
            configData += ', "width": "' + image.width + '"'
            configData += ', "height": "' + image.height + '"'
            configData += ', "status": "' + image.status + '"'
            configData += '}'

            return new MenuItem(
                true,
                categoryImageId,
                image.name,
                undefined,
                undefined,
                image.thumbnail ? image.thumbnail : image.url,
                undefined,
                MenuContentType.image,
                this.props.parentItem,
                this.props.parentItem?.worksheetItemTypeId,
                configData,
            )
        })

        let previousLevelItems = this.state.items
        if(previousLevelItems && pageNumber > 0) {
            lastLevelItems = previousLevelItems.concat(lastLevelItems)
        }

        this.setState({
            items: lastLevelItems,
            lastLevelItemIcon: lastLevelItemIcon,
            loading: false,
            pageNumber: pageNumber,
            lastImage: lastImage
        })
    }
    setMenuItemText = (menuItemTexts: MenuItemText[], lastLevelItemIcon: LastLevelItem, categoryImageId: number) => {
        let lastLevelItems = menuItemTexts.map(textItem => {
            categoryImageId++

            let configData = '{'
            configData += '"worksheetId": "' + textItem.worksheetId + '"'
            configData += '}'

            return new MenuItem(
                true,
                categoryImageId,
                textItem.name,
                undefined,
                undefined,
                undefined,
                textItem.description,
                MenuContentType.text,
                this.props.parentItem,
                this.props.parentItem?.worksheetItemTypeId,
                configData,
            )
        })

        this.setState({
            items: lastLevelItems,
            lastLevelItemIcon: lastLevelItemIcon,
            loading: false
        })
    }

    onToggleCollapse = (toggleStatus: boolean) => {
        // close all menu levels if burger button is clicked
        if(toggleStatus) {
            this.setState({
                burgerButtonStatus: toggleStatus,
                clickedItem: this.props.menuContext === MenuContext.toolbox ? undefined : this.state.clickedItem,
                collapseState: this.props.isCollapsable ? CollapseState.manualCollapsed : CollapseState.open})
        } else {
            if (this.props.isCollapsable && this.state.collapseState === CollapseState.open) {
                this.setState({
                    burgerButtonStatus: toggleStatus,
                    collapseState: CollapseState.manualCollapsed})
            }
            else {
                this.setState({
                    burgerButtonStatus: toggleStatus,
                    collapseState: CollapseState.open})
            }
        }
    }
    onItemClicked = (item?: MenuItem) => {
        let collapseStatus = this.state.collapseState;

        if(this.props.isCollapsable) {
            if(collapseStatus === CollapseState.open && item) {
                collapseStatus = CollapseState.automaticCollapsed
            } else if(collapseStatus === CollapseState.automaticCollapsed && !item) {
                collapseStatus = CollapseState.open
            } else if(collapseStatus === CollapseState.manualCollapsed) {
                collapseStatus = CollapseState.manualCollapsed
            }
        }

        // Propagate item clicked event
        this.props.onItemClicked?.(item)

        // Automatically collapse a collapsable level if item was clicked
        this.setState({
            collapseState: collapseStatus,
            clickedItem: item,
            burgerButtonStatus: (item === undefined && collapseStatus !== CollapseState.open) && true
        })
    }
    onSearch = (value: string) => {
        this.setState({search: value, pageNumber: undefined, lastImage: false}, () => this.onSearchLoadContent())
    }
    onCloseMenu = () => {
        this.onItemClicked(undefined)
    }
    onBack = () => {
        if (this.props.closeMenu) {
            this.props.closeMenu()
        }
    }

    hasSubItems(): boolean {
        if(this.props.menuContext === MenuContext.toolbox) {
            return this.state.clickedItem !== undefined && !this.props.lastLevel
        }

        return false
    }
    isLastLevel(): boolean {
        if(this.props.menuContext === MenuContext.toolbox) {
            return this.state.items && this.state.items[0].draggable ? this.state.items[0].draggable : false
        }

        return true
    }

    render() {
        if (this.state && this.props.menuType === MenuType.app &&
            ((this.state.items === undefined && !this.state.loading) || (this.state.items !== undefined && this.state.items.length === 0))) {
            return <></>
        }

        // Get right css classes depending on menuContext and selection
        const menuItemClass = getMenuItemCSS(this.props.menuContext, false, true)

        let levelClass = this.props.className
        let homeSrc = process.env.PUBLIC_URL + ImagePath.getMenuUrl() + "home.svg"
        let backSrc = process.env.PUBLIC_URL + ImagePath.getMenuUrl() + "navigate_back.svg"

        // Render top level menu
        let menu

        if (this.props.level === 0) {
            menu = <div className={levelClass + (this.props.menuContext === MenuContext.admin ? " menu-level-admin" : "")}>

                {this.props.isCollapsable &&
                    <div className="menu-group">
                        <div className="menu-item" style={{paddingTop: 15}}>
                            <div className={"menu-item-link"}>
                                <ImageToggleButton
                                    id={"menu-collapse"}
                                    imageUrlSelected= {ImagePath.getMenuUrl() + "burger_plus.svg"}
                                    imageClassSelected="menu-burger"
                                    imageUrlUnselected={ImagePath.getMenuUrl() + "burger_minus.svg"}
                                    imageClassUnselected="menu-burger"
                                    arrow={false}
                                    altText={this.context.translate(translations.text.menu)}
                                    toggleHandler={this.onToggleCollapse}
                                    defaultStatus={this.state.burgerButtonStatus}
                                />
                            </div>
                        </div>
                    </div>
                }

                {/*Only show menu items if editing of worksheet is allowed - otherwise menu should not show anything besides back buttons*/}
                {this.state.items && (this.props.isEditingAllowed === undefined || this.props.isEditingAllowed) &&
                <ul className="menu-group">
                    {this.state.items.map((item: MenuItem) => (
                        <MenuEntry
                            key={item.name + item.id}
                            item={item}
                            menuContext={this.props.menuContext}
                            collapseState={this.state.collapseState}
                            setItemClicked={this.onItemClicked}
                            itemClicked={this.state.clickedItem}
                        />
                    ))}
                </ul>
                }

                <div className={"menu-group menu-group-bottom"}>
                    {this.props.menuType === MenuType.toolbox &&
                        <li className={menuItemClass} onClick={this.props.onBack}>
                            <div className="menu-item-link" draggable="false">
                                <img className={"menu-item-img"}
                                     src={backSrc}
                                     alt={this.context.translate(translations.text.home)}
                                     draggable="false"
                                     onContextMenu={(e) => e.preventDefault() }
                                />
                                {this.state.collapseState !== CollapseState.open
                                    ? <Tooltips text={new TooltipText(this.context.translate(translations.text.back))} />
                                    : <p className={"menu-item-text"}>{this.context.translate(translations.text.back)}</p>}
                            </div>
                        </li>
                    }

                    <li className={menuItemClass}>
                        <Link to="/dashboard" className="menu-item-link" draggable="false">
                            <img className={"menu-item-img"}
                                 src={homeSrc}
                                 alt={this.context.translate(translations.text.home)}
                                 draggable="false"
                                 onContextMenu={(e) => e.preventDefault() }
                            />
                            {this.state.collapseState !== CollapseState.open
                                ? <Tooltips text={new TooltipText(this.context.translate(translations.text.home))}/>
                                : <p className={"menu-item-text"}>{this.context.translate(translations.text.home)}</p>}
                        </Link>
                    </li>
                </div>
            </div>
        }

        // Render sub menu (level > 0)
        else {
            menu = <div className={"menu-sublevel"} style={{ display: this.hasSubItems() ? "none" : "flex" }} >
                <div className={"menu-sublevel-header"}>
                    <div className={"menu-sublevel-path"}>
                        <input className={"menu-sublevel-path-img"} type={"image"}
                               src={process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "double_arrow_left.svg"}
                               style={{width: "12px", paddingRight: "5px"}} alt={this.context.translate(translations.command.back)}
                               onClick={this.onBack}
                        />

                        <div className={"menu-sublevel-path-text"}>
                            {this.props.callstack
                                .filter(item => item.item !== undefined)
                                .map((item, i, array) => {
                                if (i < (array.length - 1)) {
                                    return <div key={item.item.id}>
                                        <input type={"button"} onClick={array[i + 1].closeMenu} value={item.item.name} />
                                        &nbsp;-&nbsp;
                                    </div>
                                }
                                else {
                                    return <div key={item.item.id}>{item.item.name}</div>
                                }
                            })}
                        </div>
                    </div>

                    <SearchInput onSearch={this.onSearch}
                                 width={250}
                                 value={this.state.search}
                                 typeAheadSearch={true}
                                 showFieldOptions={false}
                                 maxLength={50}
                    />
                </div>

                {this.state.loading && (this.state.pageNumber === undefined || this.state.pageNumber === 0) &&
                    <div>
                        <Hint id={"list-loading"}
                              notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                    </div>
                }

                <div className={"menu-sublevel-group"} onScroll={this.onScrollContent}>
                    <ul className="menu-sublevel-item-group">
                        {this.state.items && this.state.items.length > 0 &&
                            this.state.items
                                .filter(item => item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
                                .map(item => (
                                    <MenuEntry
                                        key={item.id}
                                        item={item}
                                        lastLevelItemIcon={this.state.lastLevelItemIcon}
                                        menuContext={this.props.menuContext}
                                        collapseState={this.state.collapseState}
                                        setItemClicked={this.onItemClicked}
                                        itemClicked={this.state.clickedItem}
                                    />
                                ))
                        }

                        {(!this.state.items || this.state.items.length === 0) && !this.state.loading &&
                            <Hint id={"no-entries"}
                                  notificationData={new NotificationData(NotificationStatus.info, this.context.translate(translations.notification.image_search_no_result))} />
                        }
                    </ul>
                </div>
            </div>
        }

        if (this.state.clickedItem && (this.props.menuContext !== MenuContext.toolbox || !this.props.lastLevel)) {
            return <>
                {menu}
                <MenuLevel
                    className="menu-sublevel"
                    menuType={this.props.menuType}
                    menuContext={this.props.menuContext}
                    level={this.props.level + 1}
                    lastLevel={this.isLastLevel()}
                    isCollapsable={false}
                    onBack={this.props.onBack}
                    callstack={[...this.props.callstack, new CallstackItem(this.state.clickedItem!, this.onCloseMenu)]}
                    parentItem={this.state.clickedItem}
                    collapseState={CollapseState.open}
                    closeMenu={this.onCloseMenu}
                    isEditingAllowed={this.props.isEditingAllowed}
                />
            </>
        }

        return menu
    }
}
