import React from "react";
import {WDElementBase, WDElementBaseData, WDElementBaseProps, WDElementBaseState} from "../WDElementBase";
import {MainContext} from "../../../_base/MainContext";
import {WDTableCellData, WDTableCellPromiseData, WDTableColumnData, WDTableData, WDTableRowData} from "./WDTableData";
import {ResizeInfo, WDElementContainer} from "../WDElementContainer";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {Coords} from "../../../Framework/Coords";
import Const from "../../../Framework/Const";
import {WDTextbox, WDTextboxData} from "../Textbox/WDTextbox";
import {LogLevel} from "../../../Framework/Log";
import {WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import {WorksheetItem} from "../../../_model/WorksheetItem";
import Syllable from "../../../_model/Dictionary/Syllable";
import {SyllableManualDialog} from "../../../Components/Controls/SyllableManualDialog";
import {UserSettings} from "../../../_model/UserSettings";
import {Hint, NotificationData} from "../../../Components/Notification/Hint";
import {
    ManualBorderMode,
    NotificationStatus,
    VerticalAlignment,
    WDTableDimensionMode,
    WSPageFormat
} from "../../../Framework/Enums";
import translations from "../../../Framework/translations.json";
import _ from "lodash";
import {WorksheetItemUpdate} from "../../Utils/WorksheetItemUpdate";
import {RESIZE_NODE} from "../Enum/WDElementContainerResizeNode";
import {WDHistoryAction} from "../../History/Enum/WDHistoryAction";
import {WDElementHistoryItem} from "../../History/WDElementHistoryItem";
import {WDUtils} from "../../Utils/WDUtils";
import {BorderType} from "../../Toolbar/Button/Lineature/WDToolbarButtonCellBorder";
import {WDDataCellBorder} from "../General/WDDataCellBorder";
import {Util} from "../../../Framework/Util";
import Converter from "../../../Framework/Converter";
import {BorderUtils} from "../../Utils/BorderUtils";
import {WDActionLogCategory} from "../../ActionLog/WDActionLogEntry";
import {ToolboxElementValues} from "../../../Components/Menu/ToolboxElement";

export class ToolboxElementValuesTable extends ToolboxElementValues {
    rowAmount: number
    columnAmount: number

    constructor(width: number, height: number, numberOfElementsHeight: number, numberOfElementsWidth: number,
                rowAmount: number, columnAmount: number, posX?: number, posY?: number) {
        super(width, height, numberOfElementsHeight, numberOfElementsWidth, posX, posY);

        this.rowAmount = rowAmount
        this.columnAmount = columnAmount
    }
}

interface WDTableProps extends WDElementBaseProps {
    data: WDTableData
    isIndependentElement: boolean
    hasResizeOnCreate: boolean

    onFocus?: () => void
    addElementToDesigner: (element: WorksheetItem) => void
    addElementToGroup: (groupKey: string, element: WorksheetItem) => void
}

interface WDTableState extends WDElementBaseState {
    selectedCells: Coords[]
    handleFocusEvent: boolean

    notFoundWords: Syllable[]
    syllableLoading: boolean
    syllableManuallyClosedDialog: boolean

    borderMode: ManualBorderMode
    borderColor: string

    userSettings?: UserSettings
}

enum WDTableColumnAddMode {
    LEFT,
    RIGHT
}

enum WDTableRowAddMode {
    ABOVE,
    BELOW
}

export class WDTable extends WDElementBase<WDTableProps, WDTableState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    isExecutingAction: boolean = false
    mouseDownCoords: Coords | undefined = undefined

    // Coords of the click on the resizer
    resizerClickCoords: Coords | undefined
    // Resize mode (row or column)
    resizerElementMode: WDTableDimensionMode | undefined
    // Index of the row or column that is being resized
    resizerElementIndex: number | undefined
    // Value of the resized row or column when drag starts
    resizerElementValue: number | undefined
    resizerElementHistory: boolean = false

    // Selector mode (row or column) and start index
    selectorElementMode?: WDTableDimensionMode = undefined
    selectorStartIndex?: number

    borderStack: BorderUtils[] = []

    resizeNodes: ResizeInfo = new ResizeInfo(true, true, true, true, true, true, true, true,
        50, Const.MaxElementSize, 50, Const.MaxElementSize)

    constructor(props: WDTableProps) {
        super(props)

        this.state = {
            isEdited: false,
            showNonPrintableObjects: this.props.showNonPrintableObjects,
            selectedCells: [],
            borderMode: ManualBorderMode.Off,
            borderColor: "",
            elementRef: React.createRef(),
            handleFocusEvent: true,
            notFoundWords: [],
            syllableLoading: false,
            syllableManuallyClosedDialog: false
        }
    }

    shouldComponentUpdate(nextProps: Readonly<WDTableProps>, nextState: Readonly<WDTableState>): boolean {
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    static getDefaultWidth = () => {
        return Converter.toMmGrid(540)
    }
    static getDefaultHeight = () => {
        return Converter.toMmGrid(190)
    }
    static getDefaultRowAmount = () => {
        return 5
    }
    static getDefaultColumnAmount = () => {
        return 4
    }

    getMinWidth = () => {
        // return this.state.data.cols.length * 34
        return 10 + (2 * Const.ELEMENT_PADDING)
    }
    getMinHeight = () => {
        return 10 + (2 * Const.ELEMENT_PADDING)
    }

    static getDefaultContent = (): string => {
        return WDTable.getContent(
            WDTable.getDefaultWidth(),
            WDTable.getDefaultHeight(),
            WDTable.getDefaultRowAmount(),
            WDTable.getDefaultColumnAmount()
        )
    }
    static getContent = (width: number, height: number, rowAmount: number, columnAmount: number): string => {
        width -= (2 * Const.ELEMENT_PADDING)
        height -= (2 * Const.ELEMENT_PADDING)

        // Create rows and columns when the element was created
        let rowData: WDTableRowData[] = new Array(rowAmount).fill(0).map(()=> (new WDTableRowData(height / rowAmount)))
        let colData: WDTableColumnData[] = new Array(columnAmount).fill(0).map(()=> (new WDTableColumnData(width / columnAmount)))

        let cellData: WDTableCellData[][] = []
        for (let i = 0; i < rowData.length; i++) {
            cellData[i] = [];
            for (let j = 0; j < colData.length; j++) {
                cellData[i][j] = new WDTableCellData()
            }
        }

        return WDTableData.serialize(new WDTableData(
            rowData,
            colData,
            cellData,
            false
        ))
    }
    static getToolboxEntryTableInfo = (toolboxImage: string) : ToolboxElementValuesTable | undefined => {
        let toolboxImageTextArray = toolboxImage.split("TABLE-")

        if (toolboxImageTextArray.length === 2) {
            let toolboxInfo = toolboxImageTextArray[1].split("-")

            let isDefaultTable = toolboxInfo[3] === "STANDARD"
            let format = WSPageFormat[toolboxInfo[0]]
            let portrait = toolboxInfo[1] === "H"
            let heightAmount = isDefaultTable ? 1 : +toolboxInfo[2]
            let widthAmount = isDefaultTable ? 1 : +toolboxInfo[3]

            let reduceWorkspaceHeight = heightAmount < 1 ? heightAmount : 1
            let reduceWorkspaceWidth = widthAmount < 1 ? widthAmount : 1

            let toolboxValues = new ToolboxElementValuesTable(
                isDefaultTable ? WDTable.getDefaultWidth() : WDUtils.getWorksheetPageWidth(format, portrait) * reduceWorkspaceWidth,
                isDefaultTable ? WDTable.getDefaultHeight() : WDUtils.getWorksheetPageHeight(format, portrait) * reduceWorkspaceHeight,
                heightAmount,
                widthAmount,
                WDTable.getDefaultRowAmount(),
                WDTable.getDefaultColumnAmount(),
                isDefaultTable ? undefined : 0,
                isDefaultTable ? undefined : 0,
            )

            if (!isDefaultTable) {
                let widthForElement = ToolboxElementValues.getWidth(toolboxValues)
                let heightForElement = ToolboxElementValues.getHeight(toolboxValues)

                let cellHeight = WDTable.getDefaultHeight() / WDTable.getDefaultRowAmount()
                let cellWidth = WDTable.getDefaultWidth() / WDTable.getDefaultColumnAmount()

                toolboxValues.columnAmount = Math.round(widthForElement / cellWidth)
                toolboxValues.rowAmount = Math.round(heightForElement / cellHeight)
            }

            return toolboxValues
        }
    }

    doAction = (action: WDToolbarAction, data?: any) => {
        let newTableData = {...this.props.data}
        let update = new WorksheetItemUpdate(this.props.id, {})

        switch (action) {
            case WDToolbarAction.ADD_COLUMN_LEFT:
                update = this.addColumn(WDTableColumnAddMode.LEFT)
                break
            case WDToolbarAction.ADD_COLUMN_RIGHT:
                update = this.addColumn(WDTableColumnAddMode.RIGHT)
                break
            case WDToolbarAction.DELETE_COLUMN:
                if (this.props.data.cols.length <= 1) {
                    return update
                }

                update = this.deleteColumn()
                break

            case WDToolbarAction.ADD_ROW_ABOVE:
                update = this.addRow(WDTableRowAddMode.ABOVE)
                break
            case WDToolbarAction.ADD_ROW_BELOW:
                update = this.addRow(WDTableRowAddMode.BELOW)
                break
            case WDToolbarAction.DELETE_ROW:
                if (this.props.data.rows.length <= 1) {
                    return update
                }

                update = this.deleteRow()
                break

            case WDToolbarAction.DISTRIBUTE_COLUMNS:
                update = this.distributeColumns()
                break
            case WDToolbarAction.DISTRIBUTE_ROWS:
                update = this.distributeRows()
                break
            case WDToolbarAction.CONNECT_CELLS:
                this.disconnectCells()
                update = this.connectCells()
                break
            case WDToolbarAction.DISCONNECT_CELLS:
                update = this.disconnectCells()
                break

            // TODO: Border action
            case WDToolbarAction.CELL_BORDER_COLOR:
                if (data && data["type"] !== undefined) {
                    let type = data["type"].toLowerCase()

                    let borderMode = ManualBorderMode.Off
                    if (type === BorderType.manual && this.state.borderMode === ManualBorderMode.Off) {
                        borderMode = ManualBorderMode.Border
                    } else if (type === BorderType.erase && this.state.borderMode === ManualBorderMode.Off) {
                        borderMode = ManualBorderMode.Erase
                    }
                    this.toggleBorderMode(borderMode, data["color"])

                    update = this.setBorder(type, data["color"])
                }
                break
            case WDToolbarAction.CELL_BORDER_MANUAL:
                let borderModeManual = ManualBorderMode.Border
                if (this.state.borderMode === ManualBorderMode.Border) {
                    borderModeManual = ManualBorderMode.Off
                } else {
                    this.onEditElement(true)
                    this.state.elementRef.current?.onEdit()
                }

                this.toggleBorderMode(borderModeManual, data["color"])
                break
            case WDToolbarAction.CELL_BORDER_MANUAL_COLOR:
                this.toggleBorderMode(this.state.borderMode, data["color"])
                break
            case WDToolbarAction.CELL_BORDER_ERASE:
                let borderModeErase = ManualBorderMode.Erase
                if (this.state.borderMode === ManualBorderMode.Erase) {
                    borderModeErase = ManualBorderMode.Off
                } else {
                    this.onEditElement(true)
                    this.state.elementRef.current?.onEdit()
                }

                this.toggleBorderMode(borderModeErase)
                break

            case WDToolbarAction.BORDER_OPTIONS:
                this.setBorderPadding(data)

                update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newTableData)})
                break

            case WDToolbarAction.FILL_COLOR:
                if (data && data["color"] !== undefined) {
                    update = this.setBackgroundColor(data.color)
                }
                break

            case WDToolbarAction.SELECT_IMAGE:
                //     this.context.log.info("Adding image " + data.image + " in cell")
                //     update = this.changeCellType(WorksheetItemTypeEnum.IMAGE, data.image)
                break

            case WDToolbarAction.CONVERT_TO_SYLLABLE:
                newTableData.syllableActivated = !this.props.data.syllableActivated

                this.textToSyllableText(newTableData, newTableData.syllableActivated)

                this.setState({syllableLoading: newTableData.syllableActivated})
                break

            case WDToolbarAction.CELL_SIZE:
                if (this.state.selectedCells.length > 0) {
                    let width: number | undefined = undefined
                    let height: number | undefined = undefined

                    if (data && data["width"] !== undefined) {
                        width = Converter.mmToPx(data["width"])
                    }
                    if (data && data["height"] !== undefined) {
                        height = Converter.mmToPx(data["height"])
                    }

                    let selectionSize = this.getSelectedCellsSize()
                    let rIndex: number[] = [], cIndex: number[] = []
                    for (let i = 0; i < this.state.selectedCells.length; i++) {
                        const c = this.state.selectedCells[i]

                        if (width && cIndex.find(r => r === c.y) === undefined) {
                            newTableData.cols[c.y].width = (width / selectionSize.x) * newTableData.cols[c.y].width
                            cIndex.push(c.y)
                        }
                        if (height && rIndex.find(r => r === c.x) === undefined) {
                            newTableData.rows[c.x].height = (height / selectionSize.y) * newTableData.rows[c.x].height
                            rIndex.push(c.x)
                        }
                    }

                    if (this.props.isIndependentElement) {
                        let size = this.recalculateContainerSize(newTableData)
                        update.value.width = size.x
                        update.value.height = size.y
                    }
                    update.value.content = this.serializeElementData(newTableData)
                }
                break

            default:
                let isAnyCellSelected = this.state.selectedCells.length > 0

                // when more than one cell is selected, apply action to the whole text
                if (data && this.getUniqueSelectedCells().length > 1) {
                    data.wholeText = true
                }

                this.isExecutingAction = true
                if (isAnyCellSelected) {
                    this.context.log.info("Cell(s) are selected")
                    // Get element with lowest Coords x and y from selectedCells array

                    if (data.command === WDToolbarAction.BOLD || data.command === WDToolbarAction.ITALIC) {
                        let minX = this.state.selectedCells.reduce((min, p) => p.x < min ? p.x : min, this.state.selectedCells[0].x)
                        let minY = this.state.selectedCells
                            .filter(c => c.x === minX)
                            .reduce((min, p) => p.y < min ? p.y : min, this.state.selectedCells[0].y)

                        let cell = this.props.data.cells[minX][minY]
                        let commandStateFirst = cell.textboxRef?.current?.queryCommandState(data.command)
                        this.context.log.info("State of first cell " + minX + ", " + minY + ": " + commandStateFirst)
                        this.state.selectedCells.forEach(c => {
                            this.context.log.info("Action " + action + " in cell " + c.x + ", " + c.y, data)

                            let commandStateCell = newTableData.cells[c.x][c.y].textboxRef?.current?.queryCommandState(data.command)
                            if (commandStateFirst === commandStateCell) {
                                let u = newTableData.cells[c.x][c.y].textboxRef?.current?.doAction(action, data)
                                if (u && u.value.content !== undefined) {
                                    let o = JSON.parse(u.value.content) as WDTextboxData
                                    Object.keys(o).forEach(key => {
                                        newTableData.cells[c.x][c.y].data[key] = o[key]
                                    })
                                }
                            }
                        })
                    } else {
                        for (let i = 0; i < this.state.selectedCells.length; i++) {
                            const c = this.state.selectedCells[i]

                            let u = newTableData.cells[c.x][c.y].textboxRef?.current?.doAction(action, data)
                            if (u && u.value.content !== undefined) {
                                let o = JSON.parse(u.value.content) as WDTextboxData
                                Object.keys(o).forEach(key => {
                                    newTableData.cells[c.x][c.y].data[key] = o[key]
                                })

                                // Both assignments cause reference errors when going through the array, values are reset after
                                // the next index is assigned (weird problem I spent 3 days on)
                                // newTableData.cells[c.x][c.y].data = new WDTextboxData(o.text, o.showSolution, o.syllableActivated, o.verticalAlignment)
                                // newTableData.cells[c.x][c.y].data = JSON.parse(u.value.content)
                            }
                        }
                    }
                } else {
                    // isExecutingAction is used to avoid other events triggered from textbox action (e.g. onFocus)
                    for (let i = 0; i < this.props.data.rows.length; i++) {
                        for (let j = 0; j < this.props.data.cols.length; j++) {
                            // Do action for all cells because no cell is selected
                            let u = newTableData.cells[i][j].textboxRef?.current?.doAction(action, data)
                            if (u && u.value.content !== undefined) {
                                let o = JSON.parse(u.value.content) as WDTextboxData
                                Object.keys(o).forEach(key => {
                                    newTableData.cells[i][j].data[key] = o[key]
                                })
                            }
                        }
                    }
                }
                this.isExecutingAction = false

                update.value.content = this.serializeElementData(newTableData)
        }
        this.context.log.flush(LogLevel.DEBUG)

        return update
    }
    textToSyllableText = (newData: WDTableData, changeToSyllableText: boolean) => {
        let promises: Promise<WDTableCellPromiseData>[] = []
        let isAnyCellSelected = this.state.selectedCells.length > 0

        for (let i = 0; i < this.props.data.rows.length; i++) {
            for (let j = 0; j < this.props.data.cols.length; j++) {
                if (isAnyCellSelected) {
                    // Do action only if cell is one of the selected
                    if (this.isCellSelected(i, j) && newData.cells[i][j].textboxRef?.current) {
                        promises.push(
                            new Promise<WDTableCellPromiseData>(resolve => {
                                newData.cells[i][j].textboxRef?.current?.textToSyllableText(changeToSyllableText)
                                    .then((value) => resolve(new WDTableCellPromiseData(i, j, value)))
                            })
                        )
                    }
                } else {
                    // Do action for all cells because no cell is selected
                    promises.push(
                        new Promise<WDTableCellPromiseData>(resolve => {
                            newData.cells[i][j].textboxRef?.current?.textToSyllableText(changeToSyllableText)
                                .then((value) => resolve(new WDTableCellPromiseData(i, j, value)))
                        })
                    )
                }
            }
        }

        // When all syllabification calls are finished update the table data with history
        Promise.all(promises).then(cell => {
            cell.forEach(c => {
                newData.cells[c.row][c.col].data = c.data
            })

            let update = new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
            this.props.onUpdateElement(update, {
                historyAction: WDHistoryAction.CONTENT_CHANGED,
                actionCategory: WDActionLogCategory.syllable
            })
        })
    }

    onResizeElement = (proportional: boolean, x: number, y: number) => {
        this.props.onElementResize?.(false, x, y)
    }

    getDataFromTextUpdate = (row: number, col: number, update: WorksheetItemUpdate) => {
        let itemUpdate = new WorksheetItemUpdate(this.props.id, {})

        if (update.value.content) {
            let newData = {...this.props.data}
            newData.cells[row][col].data = JSON.parse(update.value.content)
            itemUpdate.value.content = this.serializeElementData(newData)
        }
        return itemUpdate
    }
    pushHistory = (row: number, col: number, before: WorksheetItemUpdate[], after: WorksheetItemUpdate[]) => {
        this.props.pushHistory(
            [this.getDataFromTextUpdate(row, col, before[0])],
            [this.getDataFromTextUpdate(row, col, after[0])]
        )
    }
    updateHistory = (row: number, col: number, value: WDElementHistoryItem) => {
        this.props.updateHistory(
            new WDElementHistoryItem([this.getDataFromTextUpdate(row, col, value.updates[0])]))
    }

    getCellsByCoords = (data: WDTableData, coords: Coords) => {
        // Get click coords and calculate cell
        coords = new Coords(
            (coords.x / this.context.getZoom()) - this.props.element.posX - Const.ELEMENT_PADDING,
            (coords.y / this.context.getZoom()) - this.props.element.posY - Const.ELEMENT_PADDING
        )

        let x = 0, y = 0
        for (let i = 0; i < data.rows.length; i++) {
            for (let j = 0; j < data.cols.length; j++) {
                let height = data.rows[i].height
                let rowspan = data.cells[i][j].rowspan || 1

                for (let k = 1; k < rowspan; k++) {
                    height += data.rows[i + k].height
                }

                let width = data.cols[j].width
                if (coords.y <= y + height && coords.y >= y) {
                    let colspan = data.cells[i][j].colspan || 1

                    for (let k = 1; k < colspan; k++) {
                        width += data.cols[j + k].width
                    }

                    if (coords.x < x + width && coords.x >= x) {
                        return this.selectCell(i, j)
                    }
                }
                x += data.cols[j].width
            }
            x = 0
            y += data.rows[i].height
        }
    }
    getAdditionalToolbarData = () => {
        return JSON.stringify({
            borderMode: this.state.borderMode,
            selectedCells: JSON.stringify(this.state.selectedCells),
            color: this.state.selectedCells.length > 0
                ? this.props.data.cells[this.state.selectedCells[0].x][this.state.selectedCells[0].y].background
                : this.props.data.cells[0][0].background
        })
    }
    getAdditionalActionDataElement = (): any => {
        return {
            selectedCells: this.state.selectedCells.map(c => {
                return {x: c.x, y: c.y}
            })
        }
    }

    updateTextData = async (row: number, col: number, update: WorksheetItemUpdate) => {
        let itemUpdate = this.getDataFromTextUpdate(row, col, update)

        this.props.onUpdateElement(itemUpdate,
            {
                actionCategory: WDActionLogCategory.content,
                actionData: {x: row, y: col, value: JSON.stringify(update.value) }
            }
        )
    }
    onEditElement = async (editMode: boolean) => {
        this.context.log.info("onEditElement - " + editMode)
        this.context.log.flush()

        for (let i = 0; i < this.props.data.rows.length; i++) {
            if (this.props.data.cells[i] === undefined) {
                continue
            }

            for (let j = 0; j < this.props.data.cols.length; j++) {
                this.props.data.cells[i][j].textboxRef?.current?.onEditElement(editMode)
                if (!editMode) {
                    this.props.data.cells[i][j].textboxRef?.current?.blur()
                }
            }
        }

        let selectedCells: Coords[] = []
        if (editMode) {
            let coords = this.state.elementRef.current?.state.editCoords
            if (coords) {
                let cells = this.getCellsByCoords(this.props.data, coords)
                if (cells) {
                    selectedCells = cells
                }
            }

            document.addEventListener("keydown", this.onKeyDown)
        } else {
            this.state.elementRef.current?.onStopEdit()

            // Cancel border mode
            this.toggleBorderMode(ManualBorderMode.Off)

            document.removeEventListener("keydown", this.onKeyDown)
            document.removeEventListener("mousemove", this.onMouseMoveCell)
            document.removeEventListener("mouseup", this.onMouseUpCell)
        }

        this.setState({isEdited: editMode, selectedCells: selectedCells}, () => {
            // Tell designer this is in edit mode
            // Cancels all event handlers to avoid element being dragged while in edit mode
            this.props.onElementEdit?.(this.props.id, editMode)

            if (this.state.isEdited && this.state.selectedCells.length > 0) {
                let coords = this.state.selectedCells[0]
                this.props.data.cells[coords.x][coords.y].textboxRef?.current?.setFocus()
            }
        })

        this.context.log.flush()
    }
    onFocusTextbox = async (row: number, col: number) => {
        if (this.isExecutingAction || (!this.state.isEdited && this.props.isIndependentElement)) {
            return
        }

        this.context.log.info("Focusing " + col + ", " + row)
        this.context.log.flush()

        this.setState({selectedCells: this.selectCell(row, col)})

        // Tell parent element that the table has the focus
        this.props.onFocus?.()
    }
    setResizeState = (state: boolean, nodeType?: RESIZE_NODE) => {
        this.state.elementRef.current?.setResizeState(state, nodeType)

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(this.props.data),
            width: this.props.element.width,
            height: this.props.element.height,
            resized: state
        })
    }

    isCellSelected = (row: number, col: number) => {
        return this.state.selectedCells.find(c => c.x === row && c.y === col) !== undefined
    }
    selectCell = (x: number, y: number): Coords[] => {
        let rowspan = this.props.data.cells[x][y].rowspan || 1
        let colspan = this.props.data.cells[x][y].colspan || 1

        let cells: Coords[] = []
        for (let i = 0; i < rowspan; i++) {
            cells.push(new Coords(x + i, y))

            for (let j = 1; j < colspan; j++) {
                cells.push(new Coords(x + i, y + j))
            }
        }

        this.expandSelection(cells)
        return cells
    }
    expandSelection = (cells: Coords[]) => {
        let changed = true
        while (changed) {
            changed = false

            let minX = cells.map(c => c.x).reduce((a, b) => a < b ? a : b)
            let maxX = cells.map(c => c.x).reduce((a, b) => a > b ? a : b)
            let minY = cells.map(c => c.y).reduce((a, b) => a < b ? a : b)
            let maxY = cells.map(c => c.y).reduce((a, b) => a > b ? a : b)

            for (let i = minX; i <= maxX; i++) {
                for (let j = minY; j <= maxY; j++) {
                    if (cells.find(c => c.x === i && c.y === j) === undefined) {
                        cells.push(new Coords(i, j))
                        changed = true
                    }
                }
            }

            for (const c of cells) {
                let cellData = this.props.data.cells[c.x][c.y]

                if (cellData.connectedRow && c.x > 0 && cells.find(s => s.x === c.x - 1 && s.y === c.y) === undefined) {
                    cells.push(new Coords(c.x - 1, c.y))
                    changed = true
                }
                if (cellData.rowspan > 1) {
                    for (let i = 1; i < cellData.rowspan; i++) {
                        if (cells.find(s => s.x === c.x + i && s.y === c.y) === undefined) {
                            cells.push(new Coords(c.x + i, c.y))
                            changed = true
                        }
                    }
                }

                if (cellData.connectedCol && c.y > 0 && cells.find(s => s.x === c.x && s.y === c.y - 1) === undefined) {
                    cells.push(new Coords(c.x, c.y - 1))
                    changed = true
                }
                if (cellData.colspan > 1) {
                    for (let i = 1; i < cellData.colspan; i++) {
                        if (cells.find(s => s.x === c.x && s.y === c.y + i) === undefined) {
                            cells.push(new Coords(c.x, c.y + i))
                            changed = true
                        }
                    }
                }
            }
        }
    }
    getUniqueSelectedCells = () => {
        return this.state.selectedCells.filter(c => {
            let cell = this.props.data.cells[c.x][c.y]
            return !cell.connectedRow && !cell.connectedCol
        })
    }

    distributeColumns = () => {
        let newData = {...this.props.data}

        // TODO: Border Width?
        const borderWidth = newData.cols.length * 1
        const width = this.props.element.width - (2 * Const.ELEMENT_PADDING) - borderWidth
        newData.cols.forEach(c => {
            c.width = width / newData.cols.length
        })

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    distributeRows = () => {
        let newData = {...this.props.data}

        const borderWidth = newData.cols.length * 1
        const height = this.props.element.height - (2 * Const.ELEMENT_PADDING) - borderWidth
        newData.rows.forEach(r => {
            r.height = height / newData.rows.length
        })

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }

    addColumn = (mode: WDTableColumnAddMode) => {
        this.context.log.info("addColumn " + mode)

        let newData = {...this.props.data}
        let colIndex = -1, rowIndex = 0

        if (this.state.isEdited) {
            for (let i = 0; i < newData.rows.length; i++) {
                for (let j = 0; j < newData.cols.length; j++) {
                    if (this.isCellSelected(i, j)) {
                        if (mode === WDTableColumnAddMode.RIGHT || colIndex === -1) {
                            rowIndex = i
                            colIndex = j
                        }
                    }
                }
            }

            // index and width of selected cell
            colIndex = Math.max(colIndex, 0)
            if (mode === WDTableColumnAddMode.RIGHT) {
                colIndex++
            }
        } else {
            if (mode === WDTableColumnAddMode.RIGHT) {
                colIndex = newData.cols.length
            } else if (mode === WDTableColumnAddMode.LEFT) {
                colIndex = 0
            }
        }

        let originColumn = Math.min(colIndex, newData.cols.length - 1)
        let width = newData.cols[originColumn].width

        // Add new cells to cells collection
        this.context.log.debug("Adding " + newData.cols.length + " cells to " + colIndex)

        let refIndex = (mode === WDTableColumnAddMode.LEFT ? Math.min(colIndex + 1, newData.cols.length) : Math.max(colIndex - 1, 0))
        this.context.log.debug("Ref-Index = " + refIndex)
        for (let i = 0; i < newData.rows.length; i++) {

            newData.cells[i].splice(colIndex, 0, new WDTableCellData())

            // If the cell to the left is connected (colspan), add the new cell to the same group
            if (newData.cells[i][refIndex].connectedCol ||
                (newData.cells[i][refIndex].colspan > 1 && mode === WDTableColumnAddMode.RIGHT)) {

                for (let k = colIndex; k >= 0; k--) {
                    if (newData.cells[i][k].colspan > 1) {
                        newData.cells[i][k].colspan++
                        break
                    }
                }
                newData.cells[i][colIndex].connectedCol = true
            }

            newData.cells[i][colIndex].border = {...newData.cells[i][refIndex].border}
            newData.cells[i][colIndex].paddingLeft = newData.cells[i][refIndex].paddingLeft
            newData.cells[i][colIndex].paddingRight = newData.cells[i][refIndex].paddingRight
            newData.cells[i][colIndex].paddingTop = newData.cells[i][refIndex].paddingTop
            newData.cells[i][colIndex].paddingBottom = newData.cells[i][refIndex].paddingBottom
            newData.cells[i][colIndex].background = newData.cells[i][refIndex].background
            if (newData.cells[i][colIndex].data) {
                newData.cells[i][colIndex].data.verticalAlignment = newData.cells[i][refIndex].data.verticalAlignment
            }
        }

        // Add new column to column collection
        newData.cols.splice(colIndex, 0, new WDTableColumnData(width))

        // Select top cell of new column
        if (this.state.isEdited) {
            this.setState({selectedCells: [new Coords(rowIndex, colIndex)]})
        }

        let size = this.recalculateContainerSize(newData)
        this.context.log.flush()

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: size.x,
            height: size.y
        })
    }
    addRow = (mode: WDTableRowAddMode) => {
        this.context.log.info("addRow " + mode)

        let newData = {...this.props.data}
        let rowIndex = -1, colIndex = 0

        if (this.state.isEdited) {
            for (let i = 0; i < newData.rows.length; i++) {
                for (let j = 0; j < newData.cols.length; j++) {
                    if (this.isCellSelected(i, j)) {
                        if (mode === WDTableRowAddMode.BELOW || rowIndex === -1) {
                            rowIndex = i
                            colIndex = j
                        }
                    }
                }
            }

            // index and height of selected cell
            if (mode === WDTableRowAddMode.BELOW) {
                rowIndex++
            }
        } else {
            if (mode === WDTableRowAddMode.BELOW) {
                rowIndex = newData.rows.length
            } else if (mode === WDTableRowAddMode.ABOVE) {
                rowIndex = 0
            }
        }

        rowIndex = Math.max(rowIndex, 0)

        this.context.log.info("Adding row at " + rowIndex)

        let originRow = Math.min(rowIndex, newData.rows.length - 1)
        let height = newData.rows[originRow].height

        // Add new cells to cells collection
        let cells: WDTableCellData[] = new Array(newData.cols.length)
        this.context.log.debug("Adding " + newData.cols.length + " cells to " + rowIndex)

        let refIndex = (mode === WDTableRowAddMode.ABOVE ? Math.min(rowIndex + 1, newData.rows.length) : Math.max(rowIndex - 1, 0))
        newData.cells.splice(rowIndex, 0, cells)
        for (let i = 0; i < cells.length; i++) {
            newData.cells[rowIndex][i] = new WDTableCellData()
            newData.cells[rowIndex][i].colspan = newData.cells[refIndex][i].colspan

            // If the cell above is connected (rowspan), add the new cell to the same group
            if (newData.cells[refIndex][i].connectedRow ||
                (newData.cells[refIndex][i].rowspan > 1 && mode === WDTableRowAddMode.BELOW)) {

                for (let k = rowIndex; k >= 0; k--) {
                    if (newData.cells[k][i].rowspan > 1) {
                        newData.cells[k][i].rowspan++
                        break
                    }
                }
                newData.cells[rowIndex][i].colspan = newData.cells[refIndex][i].colspan
                newData.cells[rowIndex][i].connectedRow = true
            }

            newData.cells[rowIndex][i].connectedCol = newData.cells[refIndex][i].connectedCol
            newData.cells[rowIndex][i].border = {...newData.cells[refIndex][i].border}
            newData.cells[rowIndex][i].paddingLeft = newData.cells[refIndex][i].paddingLeft
            newData.cells[rowIndex][i].paddingRight = newData.cells[refIndex][i].paddingRight
            newData.cells[rowIndex][i].paddingTop = newData.cells[refIndex][i].paddingTop
            newData.cells[rowIndex][i].paddingBottom = newData.cells[refIndex][i].paddingBottom
            newData.cells[rowIndex][i].background = newData.cells[refIndex][i].background
            if (newData.cells[rowIndex][i].data) {
                newData.cells[rowIndex][i].data.verticalAlignment = newData.cells[refIndex][i].data.verticalAlignment
            }
        }

        // Add new row to row collection
        newData.rows.splice(rowIndex, 0, new WDTableRowData(height))

        // Select top cell of new column
        if (this.state.isEdited) {
            this.setState({selectedCells: [new Coords(rowIndex, colIndex)]})
        }

        let size = this.recalculateContainerSize(newData)
        this.context.log.flush()

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: size.x,
            height: size.y
        })
    }
    deleteColumn = () => {
        let newData = {...this.props.data}

        let cols: number[] = []
        if (this.state.isEdited) {
            for (let i = 0; i < this.state.selectedCells.length; i++) {
                if (cols.find(c => c === this.state.selectedCells[i].y) === undefined) {
                    cols.push(this.state.selectedCells[i].y)
                }
            }
        }

        // No column selected, delete last column
        if (cols.length === 0) {
            cols.push(newData.cols.length - 1)
        }

        // Sort descending and delete columns
        cols.sort((a, b) => b - a)
            .forEach(col => {
                for (let i = 0; i < newData.rows.length; i++) {
                    let cell = newData.cells[i][col]

                    // Cell of column to delete was connected (colspan): reduce the colspan
                    if (cell.connectedCol) {
                        for (let k = col - 1; k >= 0; k--) {
                            if (newData.cells[i][k].colspan > 1) {
                                newData.cells[i][k].colspan--
                                break
                            }
                        }
                    }
                    // Cell of column deleted had a colspan: start with colspan on next column
                    else if (cell.colspan > 1) {
                        newData.cells[i][col + 1].rowspan = cell.rowspan
                        newData.cells[i][col + 1].colspan = cell.colspan - 1
                        newData.cells[i][col + 1].connectedCol = false

                        let o = newData.cells[i][col].data
                        Object.keys(o).forEach(key => {
                            newData.cells[i][col + 1].data[key] = o[key]
                        })
                    }

                    newData.cells[i].splice(col, 1)
                }

                newData.cols.splice(col, 1)
            })

        if (this.state.isEdited) {
            this.setState({selectedCells: []})
        }

        let size = this.recalculateContainerSize(newData)
        this.context.log.flush()

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: size.x,
            height: size.y
        })
    }
    deleteRow = () => {
        this.context.log.info("deleteRow")

        let newData = {...this.props.data}

        let rows: number[] = []
        if (this.state.isEdited) {
            for (let i = 0; i < this.state.selectedCells.length; i++) {
                if (rows.find(r => r === this.state.selectedCells[i].x) === undefined) {
                    rows.push(this.state.selectedCells[i].x)
                }
            }
        }

        // No row selected, delete last column
        if (rows.length === 0) {
            rows.push(newData.rows.length - 1)
        }

        // Sort descending and delete columns
        rows.sort((a, b) => b - a)
            .forEach(row => {

                for (let j = 0; j < newData.cols.length; j++) {
                    let cell = newData.cells[row][j]

                    // Cell of row to delete was connected (rowspan): reduce the rowspan
                    if (cell.connectedRow) {
                        for (let k = row - 1; k >= 0; k--) {
                            if (newData.cells[k][j].rowspan > 1) {
                                newData.cells[k][j].rowspan--
                                break
                            }
                        }
                    } else if (cell.rowspan > 1) {
                        newData.cells[row + 1][j].rowspan = cell.rowspan - 1
                        // newData.cells[row + 1][j].colspan = cell.colspan
                        newData.cells[row + 1][j].connectedRow = false

                        let o = newData.cells[row][j].data
                        Object.keys(o).forEach(key => {
                            newData.cells[row + 1][j].data[key] = o[key]
                        })
                    }
                }

                newData.rows.splice(row, 1)
                newData.cells.splice(row, 1)
            })

        if (this.state.isEdited) {
            this.setState({selectedCells: []})
        }

        let size = this.recalculateContainerSize(newData)
        this.context.log.flush()

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: size.x,
            height: size.y
        })
    }
    // changeCellType = (type: WorksheetItemTypeEnum, id: number) => {
    //     let rowIndex = -1, colIndex = -1
    //     for (let i = 0; i < this.props.data.rows.length && rowIndex < 0; i++) {
    //         for (let j = this.props.data.cols.length - 1; j >= 0 && colIndex < 0; j--) {
    //             let cell = this.props.data.cells[i][j]
    //             if (this.isCellSelected(i, j)) {
    //                 cell.worksheetElementType = type
    //
    //                 if (type === WorksheetItemTypeEnum.IMAGE) {
    //                     let image = await GetImageDetails(id)
    //
    //                     const alignment = image.alignment || ImageAlignment.quadratic
    //                     let renderCoords = Image.getSizeByAlignment(alignment)
    //                     if (image.width && image.height) {
    //                         renderCoords = Util.calculateRenderSize(new Coords(image.width, image.height), alignment)
    //                     }
    //
    //                     let data = new WDImageData(id, renderCoords.x, renderCoords.y, image.status)
    //
    //                     let element = new WorksheetItem("image-" + Date.now(), "-", WorksheetItemTypeEnum.IMAGE,
    //                         0, 0, Const.MinElementWidth, Const.MinElementHeight, JSON.stringify(data), true, true)
    //                     let cell = document.getElementById(this.props.id + "-td-" + i + "-" + j + "-container")
    //
    //                     this.props.addElementToGroup(this.props.id, element)
    //                     await this.props.addElementToDesigner(element, WDElementOrigin.loaded, WDElementOrigin.loaded, cell!)
    //                 }
    //             }
    //         }
    //     }
    // }

    connectCells = () => {
        let newData = {...this.props.data}
        let selectedCells = this.state.selectedCells

        if (selectedCells.length > 1) {

            let minX = selectedCells.map(c => c.x).reduce((prev, current) => {
                return (prev < current) ? prev : current
            })
            let minY = selectedCells.map(c => c.y).reduce((prev, current) => {
                return (prev < current) ? prev : current
            })
            let maxX = selectedCells.map(c => c.x).reduce((prev, current) => {
                return (prev > current) ? prev : current
            })
            let maxY = selectedCells.map(c => c.y).reduce((prev, current) => {
                return (prev > current) ? prev : current
            })

            let rowspan = maxX - minX + 1
            let colspan = maxY - minY + 1

            // Reset current cells spans and connections
            let cellContent = newData.cells[minX][minY].data.text

            for (let i = minX; i <= maxX; i++) {
                for (let j = minY; j <= maxY; j++) {
                    newData.cells[i][j].rowspan = 1
                    newData.cells[i][j].colspan = 1
                    newData.cells[i][j].connectedRow = (rowspan > 1 && i > minX)
                    newData.cells[i][j].connectedCol = (colspan > 1 && j > minY)

                    if (i !== minX || j !== minY) {
                        cellContent += "<div>" + newData.cells[i][j].data.text + "</div>"
                        newData.cells[i][j].data = WDTextboxData.defaultContent()
                    }
                }
            }
            newData.cells[minX][minY].data.text = cellContent

            newData.cells[minX][minY].rowspan = rowspan
            newData.cells[minX][minY].colspan = colspan

            // When rowspan and colspan are set for the first cell, set colspan for all cells in the first row
            if (rowspan > 1 && colspan > 1) {
                for (let i = minX; i <= maxX; i++) {
                    if (newData.cells[i][minY]) {
                        newData.cells[i][minY].colspan = colspan
                    }
                }
            }
        }

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    disconnectCells = () => {
        let newData = {...this.props.data}

        if (this.state.selectedCells.length > 0) {
            let minX = this.state.selectedCells.map(c => c.x).reduce((prev, current) => {
                return (prev < current) ? prev : current
            })
            let minY = this.state.selectedCells.map(c => c.y).reduce((prev, current) => {
                return (prev < current) ? prev : current
            })
            let maxX = this.state.selectedCells.map(c => c.x).reduce((prev, current) => {
                return (prev > current) ? prev : current
            })
            let maxY = this.state.selectedCells.map(c => c.y).reduce((prev, current) => {
                return (prev > current) ? prev : current
            })

            for (let i = minX; i <= maxX; i++) {
                for (let j = minY; j <= maxY; j++) {
                    newData.cells[i][j].rowspan = 1
                    newData.cells[i][j].colspan = 1
                    newData.cells[i][j].connectedRow = false
                    newData.cells[i][j].connectedCol = false
                }
            }
        }

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }

    setBorder = (borderType: BorderType, color: string) => {
        let newData = {...this.props.data}
        let current: boolean | undefined = undefined

        // When a cell is selected, set border to selected cell
        if (this.state.isEdited && this.state.selectedCells && this.state.selectedCells.length > 0) {

            // Get min/max coordinates of selection
            let minY = this.state.selectedCells.reduce((prev, current) => {
                return (prev.y < current.y) ? prev : current
            }).y
            let minX = this.state.selectedCells.reduce((prev, current) => {
                return (prev.x < current.x) ? prev : current
            }).x
            let maxY = this.state.selectedCells.reduce((prev, current) => {
                return (prev.y > current.y) ? prev : current
            }).y
            let maxX = this.state.selectedCells.reduce((prev, current) => {
                return (prev.x > current.x) ? prev : current
            }).x

            for (const item of this.state.selectedCells) {
                if ((borderType === BorderType.left || borderType === BorderType.outer || borderType === BorderType.all) && item.y === minY) {
                    current = this.updateCellBorder(newData.cells, BorderType.left, color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if ((borderType === BorderType.right || borderType === BorderType.outer || borderType === BorderType.all) && item.y === maxY) {
                    current = this.updateCellBorder(newData.cells, BorderType.right, color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if ((borderType === BorderType.top || borderType === BorderType.outer || borderType === BorderType.all) && item.x === minX) {
                    current = this.updateCellBorder(newData.cells, BorderType.top, color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if ((borderType === BorderType.bottom || borderType === BorderType.bottom_double || borderType === BorderType.outer || borderType === BorderType.all) && item.x === maxX) {
                    current = this.updateCellBorder(newData.cells,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? BorderType.bottom : borderType,
                        color, item.x, item.y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
                if (borderType === BorderType.middle) {
                    current = this.updateCellBorder(newData.cells, BorderType.middle, color, item.x, item.y, current)
                }
                if (borderType === BorderType.inner || borderType === BorderType.all) {
                    if (item.x < maxX) {
                        current = this.updateCellBorder(newData.cells, BorderType.bottom, color, item.x, item.y, false)
                    }
                    if (item.y < maxY) {
                        current = this.updateCellBorder(newData.cells, BorderType.right, color, item.x, item.y, false)
                    }
                }
                if (borderType === BorderType.no) {
                    current = this.updateCellBorder(newData.cells, BorderType.no, color, item.x, item.y, current)
                }
            }
        } else if (!this.state.isEdited) {
            if (borderType === BorderType.left || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let x = 0; x < newData.rows.length; x++) {
                    current = this.updateCellBorder(newData.cells, BorderType.left, color, x, 0,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.top || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let y = 0; y < newData.cols.length; y++) {
                    current = this.updateCellBorder(newData.cells, BorderType.top, color, 0, y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.right || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let x = 0; x < newData.rows.length; x++) {
                    current = this.updateCellBorder(newData.cells, BorderType.right, color, x, newData.cols.length - 1,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.bottom || borderType === BorderType.outer || borderType === BorderType.all) {
                for (let y = 0; y < newData.cols.length; y++) {
                    current = this.updateCellBorder(newData.cells, BorderType.bottom, color, newData.rows.length - 1, y,
                        (borderType === BorderType.outer || borderType === BorderType.all) ? false : current)
                }
            }
            if (borderType === BorderType.inner || borderType === BorderType.all) {
                for (let x = 0; x < newData.rows.length; x++) {
                    for (let y = 0; y < newData.cols.length; y++) {
                        if (x < newData.rows.length - 1) {
                            current = this.updateCellBorder(newData.cells, BorderType.bottom, color, x, y,
                                (borderType === BorderType.all) ? false : current)
                        }
                        if (y < newData.cols.length - 1) {
                            current = this.updateCellBorder(newData.cells, BorderType.right, color, x, y,
                                (borderType === BorderType.all) ? false : current)
                        }
                    }
                }
            }
            if (borderType === BorderType.no) {
                for (let x = 0; x < newData.rows.length; x++) {
                    for (let y = 0; y < newData.cols.length; y++) {
                        current = this.updateCellBorder(newData.cells, BorderType.no, color, x, y, current)
                    }
                }
            }
        }

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    updateCellBorder = (data: WDTableCellData[][], borderType: BorderType, color: string, x: number, y: number, current?: boolean) => {
        let border = data[x][y].border
        if (border === undefined || border === null) {
            border = new WDDataCellBorder()
        }

        if (borderType === BorderType.left) {
            if (current === undefined) {
                current = border.leftShow
            }
            border.leftShow = (this.state.borderMode === ManualBorderMode.Erase ? false : (border.leftColor === color) ? !current : true)
            border.leftColor = color
        }
        if (borderType === BorderType.top) {
            if (current === undefined) {
                current = border.topShow
            }
            border.topShow = (this.state.borderMode === ManualBorderMode.Erase ? false : (border.topColor === color) ? !current : true)
            border.topColor = color
        }
        if (borderType === BorderType.right) {
            if (current === undefined) {
                current = border.rightShow
            }
            if (y < data[x].length - 1) {
                data[x][y + 1].border.leftShow = (this.state.borderMode === ManualBorderMode.Erase ? false : (data[x][y + 1].border.rightColor === color) ? !current : true)
                data[x][y + 1].border.leftColor = color
            } else {
                border.rightShow = (this.state.borderMode === ManualBorderMode.Erase ? false : (border.rightColor === color) ? !current : true)
                border.rightColor = color
            }
        }
        if (borderType === BorderType.bottom || borderType === BorderType.bottom_double) {
            if (current === undefined) {
                current = border.bottomShow
            }

            if (borderType === BorderType.bottom_double) {
                border.bottomShow = (this.state.borderMode === ManualBorderMode.Erase ? false : (border.bottomColor === color) ? !current : true)
                border.bottomColor = color
                border.bottomDouble = true
            } else {
                if (x < data.length - 1) {
                    data[x + 1][y].border.topShow = this.state.borderMode !== ManualBorderMode.Erase
                    data[x + 1][y].border.topColor = color
                } else {
                    border.bottomShow = (this.state.borderMode === ManualBorderMode.Erase ? false : (border.bottomColor === color) ? !current : true)
                    border.bottomColor = color
                }
            }
        }
        if (borderType === BorderType.no) {
            border.leftShow = false
            border.topShow = false

            if (y < data[x].length - 1) {
                data[x][y + 1].border.leftShow = false
            } else {
                border.rightShow = false
            }

            if (x < data.length - 1) {
                data[x + 1][y].border.topShow = false
            } else {
                border.bottomShow = false
            }
        }

        data[x][y].border = border

        return current
    }

    setBackgroundColor = (color: string) => {
        let newData = {...this.props.data}

        for (let i = 0; i < newData.rows.length; i++) {
            for (let j = 0; j < newData.cols.length; j++) {
                if (!this.state.isEdited || this.isCellSelected(i, j)) {
                    newData.cells[i][j].background = color
                }
            }
        }

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }
    setBorderPadding = (data: any) => {
        let newData = {...this.props.data}

        for (let i = 0; i < newData.rows.length; i++) {
            for (let j = 0; j < newData.cols.length; j++) {
                if (!this.state.isEdited || this.isCellSelected(i, j)) {
                    let cell = newData.cells[i][j]

                    if (data && data.paddingLeft !== undefined) {
                        cell.paddingLeft = data.paddingLeft
                    }
                    if (data && data.paddingTop !== undefined) {
                        cell.paddingTop = data.paddingTop
                    }
                    if (data && data.paddingRight !== undefined) {
                        cell.paddingRight = data.paddingRight
                    }
                    if (data && data.paddingBottom !== undefined) {
                        cell.paddingBottom = data.paddingBottom
                    }
                }
            }
        }

        return new WorksheetItemUpdate(this.props.id, {content: this.serializeElementData(newData)})
    }

    getSelectedCellsSize = () => {
        let result: Coords = new Coords(0, 0)
        let rIndex: number[] = [], cIndex: number[] = []
        for (let i = 0; i < this.state.selectedCells.length; i++) {
            if (cIndex.find(c => c === this.state.selectedCells[i].y) === undefined) {
                cIndex.push(this.state.selectedCells[i].y)
                if (this.props.data.cols[this.state.selectedCells[i].y]) {
                    result.x += this.props.data.cols[this.state.selectedCells[i].y].width
                }
            }
            if (rIndex.find(r => r === this.state.selectedCells[i].x) === undefined) {
                rIndex.push(this.state.selectedCells[i].x)
                if (this.props.data.rows[this.state.selectedCells[i].x]) {
                    result.y += this.props.data.rows[this.state.selectedCells[i].x].height
                }
            }
        }
        return result
    }
    calculateCellSizes = (width: number, height: number): WDTableData => {
        // TODO: Border width?
        const borderWidth = 0
        const borderHeight = 0

        const newWidth = width - (2 * Const.ELEMENT_PADDING) - borderWidth
        const newHeight = height - (2 * Const.ELEMENT_PADDING) - borderHeight

        let newData = {...this.props.data}
        let w = newData.cols
            .map(c => c.width)
            .reduce((previousValue, currentValue) => currentValue + previousValue)
        let h = newData.rows
            .map(c => c.height)
            .reduce((previousValue, currentValue) => currentValue + previousValue)

        if (Math.floor(w) === Math.floor(newWidth) && Math.floor(h) === Math.floor(newHeight)) {
            return this.props.data
        }

        newData.cols.forEach(c => {
            let factor = c.width / w
            c.width = newWidth * factor
        })
        newData.rows.forEach(r => {
            let factor = r.height / h
            r.height = newHeight * factor
        })

        return newData
    }
    recalculateSize = (width: number, height: number): WorksheetItemUpdate => {
        let newData = this.calculateCellSizes(width, height)

        return new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData),
            width: width,
            height: height
        })
    }
    resizeElement = (proportional: boolean, x: number, y: number) => {
        // Ignore nodeType so the table is not resized when a group containing the table is resized
        let update = this.state.elementRef.current?.resizeElement(false, x, y)
        if (update) {
            let newData = this.calculateCellSizes(update.value.width!, update.value.height!)
            if (newData) {
                update.value.content = this.serializeElementData(newData)
            }
        }

        return update
    }
    recalculateContainerSize = (data: WDTableData) => {
        let width = WDTableData.calculateWidth(data)
        let height = WDTableData.calculateHeight(data)

        this.context.log.debug("WDTable.recalculateContainerSize - w: " + width + ", h: " + height)

        return new Coords(width, height)
    }

    onKeyDown = async(e: KeyboardEvent) => {
        let selectedCells = this.getUniqueSelectedCells()
        if ((e.key === "Delete" || e.key === "Backspace") && selectedCells.length > 1) {
            let newTableData = {...this.props.data}

            this.state.selectedCells.forEach(c => {
                newTableData.cells[c.x][c.y].data.text = ""
            })

            let update = new WorksheetItemUpdate(this.props.id, {})
            update.value.content = this.serializeElementData(newTableData)
            await this.props.onUpdateElement(update, { historyAction: WDHistoryAction.CONTENT_CHANGED })
        }
    }

    onMouseDownCell = (i: number, j: number) => {
        if (this.isBorderModeOff() && !this.props.element.locked) {
            this.mouseDownCoords = new Coords(i, j)

            this.props.data.cells[i][j].textboxRef?.current?.setFocus()

            document.addEventListener("mousemove", this.onMouseMoveCell)
            document.addEventListener("mouseup", this.onMouseUpCell)
        }
    }
    onMouseMoveCell = (e: MouseEvent) => {
        if (this.isBorderModeOff() && !this.props.element.locked) {
            if (this.mouseDownCoords) {
                let coords = WDUtils.convertCoordsToAnchorObjectCoordinates(this.props.id, new Coords(e.clientX, e.clientY))
                let cells = this.getCellsByCoords(this.props.data, coords)
                if (cells) {
                    let minX = Math.min(cells.map(c => c.x).reduce((a, b) => a < b ? a : b), this.mouseDownCoords.x)
                    let maxX = Math.max(cells.map(c => c.x).reduce((a, b) => a > b ? a : b), this.mouseDownCoords.x)
                    let minY = Math.min(cells.map(c => c.y).reduce((a, b) => a < b ? a : b), this.mouseDownCoords.y)
                    let maxY = Math.max(cells.map(c => c.y).reduce((a, b) => a > b ? a : b), this.mouseDownCoords.y)

                    let selectedCells: Coords[] = []
                    for (let i = minX; i <= maxX; i++) {
                        for (let j = minY; j <= maxY; j++) {
                            selectedCells.push(new Coords(i, j))
                        }
                    }

                    this.expandSelection(selectedCells)

                    // When more than one unique cell is selected, blur all textboxes
                    let uniqueCells = selectedCells.filter(c => {
                        let cell = this.props.data.cells[c.x][c.y]
                        return !(cell.connectedRow || cell.connectedCol)
                    })
                    if (uniqueCells.length > 1) {
                        selectedCells.forEach(c => {
                            this.props.data.cells[c.x][c.y].textboxRef?.current?.blur()
                        })
                    }

                    this.setState({selectedCells: selectedCells})
                }
            }
        }
    }
    onMouseUpCell = (e: MouseEvent) => {
        this.mouseDownCoords = undefined
        document.removeEventListener("mousemove", this.onMouseMoveCell)
        document.removeEventListener("mouseup", this.onMouseUpCell)
    }

    onMouseDownResizer = (e: React.MouseEvent<HTMLDivElement>, index: number, mode: WDTableDimensionMode) => {
        this.context.log.debug("onMouseDownResizer")

        this.resizerClickCoords = new Coords(e.clientX, e.clientY)
        this.context.log.debug("X: " + this.resizerClickCoords.x + " , Y: " + this.resizerClickCoords.y)

        this.resizerElementHistory = false
        this.resizerElementMode = mode
        this.resizerElementIndex = index
        if (mode === WDTableDimensionMode.ROW) {
            this.resizerElementValue = this.props.data.rows[index].height
        } else if (mode === WDTableDimensionMode.COLUMN) {
            this.resizerElementValue = this.props.data.cols[index].width
        }

        document.addEventListener("mousemove", this.onMouseMoveResizer)
        document.addEventListener("mouseup", this.onMouseUpResizer)

        this.context.log.flush(LogLevel.INFO)
    }
    onMouseMoveResizer = async (e: MouseEvent) => {
        let newData = {...this.props.data}

        if (this.resizerElementMode === WDTableDimensionMode.ROW) {
            newData.rows[this.resizerElementIndex || 0].height = (this.resizerElementValue || 0) - ((this.resizerClickCoords?.y || 0) - e.clientY)
        } else if (this.resizerElementMode === WDTableDimensionMode.COLUMN) {
            newData.cols[this.resizerElementIndex || 0].width = (this.resizerElementValue || 0) - ((this.resizerClickCoords?.x || 0) - e.clientX)
        }

        let update = new WorksheetItemUpdate(this.props.id, {
            content: this.serializeElementData(newData)
        })

        if (this.props.isIndependentElement) {
            let size = this.recalculateContainerSize(newData)
            update.value.width = size.x
            update.value.height = size.y
        }

        await this.props.onUpdateElement(update)

        // Create or update history entry
        if (!this.resizerElementHistory) {
            this.props.pushHistory(
                [new WorksheetItemUpdate(this.props.id, {
                    content: this.serializeElementData(newData),
                    width: this.props.element.width,
                    height: this.props.element.height
                })],
                [update]
            )
            this.resizerElementHistory = true
        }
        else {
            this.props.updateHistory(new WDElementHistoryItem([update]))
        }

        this.context.log.flush(LogLevel.INFO)
    }
    onMouseUpResizer = () => {
        this.context.log.debug("onMouseUp")

        this.resizerClickCoords = undefined
        this.resizerElementMode = undefined
        this.resizerElementIndex = undefined
        this.resizerElementValue = undefined

        document.removeEventListener("mousemove", this.onMouseMoveResizer)
        document.removeEventListener("mouseup", this.onMouseUpResizer)

        this.context.log.flush()
    }

    toggleBorderMode = (mode: ManualBorderMode, color?: string) => {
        if (mode) {
            document.addEventListener("mousedown", this.onMouseDownBorder)
            document.addEventListener("mousemove", this.onMouseMoveBorder)
        } else {
            document.removeEventListener("mousedown", this.onMouseDownBorder)
            document.removeEventListener("mousemove", this.onMouseMoveBorder)
        }

        this.setState({
            borderMode: mode,
            borderColor: color || "",
            selectedCells: (mode === ManualBorderMode.Border || mode === ManualBorderMode.Erase) ? [] : this.state.selectedCells
        })
    }
    isBorderModeOff = () => {
        return this.state.borderMode === undefined || this.state.borderMode === ManualBorderMode.Off
    }
    drawBorder = async (e: MouseEvent, borderMode: ManualBorderMode) => {
        if (e.target) {
            let cell: HTMLElement | undefined

            // Get table cell of element clicked
            let td = Util.getParentByTag(e.target as HTMLElement, "td", this.props.id)
            if (td) {
                cell = td.querySelector("div.ws-designer-table-cell-container") as HTMLElement
            }

            if (cell) {
                e.preventDefault()
                e.stopPropagation()

                let rect = cell.getBoundingClientRect()
                let offsetX = e.clientX - rect.left, offsetY = e.clientY - rect.top

                let newData = {...this.props.data}
                let current = (borderMode === ManualBorderMode.Erase)

                let coords = WDUtils.convertCoordsToAnchorObjectCoordinates(this.props.id, new Coords(e.clientX, e.clientY))
                let cells = this.getCellsByCoords(newData, coords)
                for (const item of cells || []) {
                    let thresholdX = rect.width * 0.2, thresholdY = rect.height * 0.2
                    if (offsetX < thresholdX && offsetY > thresholdY && offsetY < rect.height - thresholdY) {
                        this.updateCellBorder(newData.cells, BorderType.left, this.state.borderColor, item.x, item.y, current)
                    } else if (offsetX > rect.width - thresholdX && offsetY > thresholdY && offsetY < rect.height - thresholdY) {
                        this.updateCellBorder(newData.cells, BorderType.right, this.state.borderColor, item.x, item.y, current)
                    } else if (offsetY < thresholdY && offsetX > thresholdX && offsetX < rect.width - thresholdX) {
                        this.updateCellBorder(newData.cells, BorderType.top, this.state.borderColor, item.x, item.y, current)
                    } else if (offsetY > rect.height - thresholdY && offsetX > thresholdX && offsetX < rect.width - thresholdX) {
                        this.updateCellBorder(newData.cells, BorderType.bottom, this.state.borderColor, item.x, item.y, current)
                    }
                }

                this.props.onUpdateElement(new WorksheetItemUpdate(
                    this.props.id, {content: this.serializeElementData(newData)}),
                    { actionCategory: WDActionLogCategory.content }
                )
            }
        }
    }
    onMouseDownBorder = async (e: MouseEvent) => {
        this.borderStack = []
        await this.drawBorder(e, this.state.borderMode)
    }
    onMouseMoveBorder = async (e: MouseEvent) => {
        if (e.buttons === 1) {
            await this.drawBorder(e, this.state.borderMode)
        }
    }

    selectDimension = (selection: Coords[], index: number) => {
        if (this.selectorElementMode === WDTableDimensionMode.ROW) {
            this.onSelectRow(selection, index)
        } else if (this.selectorElementMode === WDTableDimensionMode.COLUMN) {
            this.onSelectColumn(selection, index)
        }
    }

    onMouseDownSelector = async (mode: WDTableDimensionMode, index: number) => {
        this.selectorElementMode = mode
        this.selectorStartIndex = index

        let selection: Coords[] = []
        this.selectDimension(selection, index)

        this.expandSelection(selection)
        this.setState({selectedCells: selection})

        document.addEventListener("mouseup", this.onMouseUpSelector)
    }
    onMouseMoveSelector = async (e: React.MouseEvent<HTMLDivElement>, index: number) => {
        if (this.selectorElementMode !== undefined && e.buttons === 1) {
            let start = Math.min(this.selectorStartIndex || 0, index)
            let end = Math.max(this.selectorStartIndex || 0, index)

            let selection: Coords[] = []
            for (let i = start; i <= end; i++) {
                this.selectDimension(selection, i)
            }

            this.expandSelection(selection)
            this.setState({selectedCells: selection})
        }
    }
    onMouseUpSelector = () => {
        this.selectorElementMode = undefined

        document.removeEventListener("mouseup", this.onMouseUpSelector)
    }

    onSelectRow = (selection: Coords[], row: number) => {
        for (let j = 0; j < this.props.data.cols.length; j++) {
            if (selection.find(c => c.x === row && c.y === j) === undefined) {
                selection.push(new Coords(row, j))
            }
        }
    }
    onSelectColumn = (selection: Coords[], col: number) => {
        for (let i = 0; i < this.props.data.rows.length; i++) {
            if (selection.find(c => c.x === i && c.y === col) === undefined) {
                selection.push(new Coords(i, col))
            }
        }
    }

    getNameConfigInstances = (): number[] => {
        // TODO: Get name config elements from each textbox
        return []
    }
    serializeElementData = (data: WDElementBaseData): string => {
        return WDTableData.serialize(data as WDTableData)
    }
    onFinishSyllabification = (notFoundWords?: Syllable[]) => {
        let cellNotFoundWords = this.state.notFoundWords
        cellNotFoundWords = notFoundWords ? cellNotFoundWords.concat(notFoundWords) : cellNotFoundWords

        this.setState({
            notFoundWords: cellNotFoundWords,
            syllableLoading: false
        })
    }
    addSyllableTextManually = (originalWord: string, currentSyllableManually: string) => {
        // TODO: Check onChangeTextData
        for (let i = 0; i < this.props.data.rows.length; i++) {
            for (let j = 0; j < this.props.data.cols.length; j++) {
                this.props.data.cells[i][j].textboxRef?.current?.addSyllableTextManually(originalWord, currentSyllableManually)
            }
        }

        let filteredNotFoundWord = this.state.notFoundWords.filter(word => word.originalValue !== originalWord)

        this.setState({
            notFoundWords: filteredNotFoundWord,
            syllableLoading: false,
            syllableManuallyClosedDialog: false,
        })
    }
    closeSyllableNotFoundDialog = () => {
        this.setState({syllableManuallyClosedDialog: true})
    }

    evaluateBorder = (x: number, y: number) => {
        let borders: JSX.Element[] = []

        let cell = this.props.data.cells[x][y]
        let colspan = cell.colspan || 1
        let rowspan = cell.rowspan || 1

        let offsetX = 0, offsetY = 0
        for (let r = 0; r < rowspan; r++) {
            let height = this.props.data.rows[x + r].height

            offsetX = 0
            for (let c = 0; c < colspan; c++) {
                cell = this.props.data.cells[x + r][y + c]
                if (cell === undefined) {
                    break
                }

                let width = this.props.data.cols[y + c].width

                if (c === 0) {
                    if (cell.border.leftShow) {
                        borders.push(
                            <div className={"ws-designer-table-cell-border-left"}
                                 key={this.props.id + "-border-left-" + (x + r) + "-" + (y + c)}
                                 style={{
                                     top: offsetY,
                                     height: height,
                                     borderLeftWidth: "thin",
                                     borderLeftColor: cell.border.leftColor,
                                     borderLeftStyle: "solid"
                                 }}
                            />
                        )
                    } else if (this.props.showNonPrintableObjects) {
                        borders.push(
                            <div
                                className={"ws-designer-table-cell-border-left ws-designer-table-cell-border-left-gone"}
                                key={this.props.id + "-border-left-" + (x + r) + "-" + (y + c)}
                                style={{top: offsetY, height: height}}
                            />
                        )
                    }
                }

                if (r === 0) {
                    if (cell.border.topShow) {
                        borders.push(
                            <div className={"ws-designer-table-cell-border-top"}
                                 key={this.props.id + "-border-top-" + (x + r) + "-" + (y + c)}
                                 style={{
                                     left: offsetX,
                                     width: width,
                                     borderTopWidth: "thin",
                                     borderTopColor: cell.border.topColor,
                                     borderTopStyle: "solid"
                                 }}
                            />
                        )
                    } else if (this.props.showNonPrintableObjects) {
                        borders.push(
                            <div className={"ws-designer-table-cell-border-top ws-designer-table-cell-border-top-gone"}
                                 key={this.props.id + "-border-top-" + (x + r) + "-" + (y + c)}
                                 style={{left: offsetX, width: width}}
                            />
                        )
                    }
                }

                if (y + c === this.props.data.cols.length - 1) {
                    if (cell.border.rightShow) {
                        borders.push(
                            <div className={"ws-designer-table-cell-border-right"}
                                 key={this.props.id + "-border-right-" + (x + r) + "-" + (y + c)}
                                 style={{
                                     top: offsetY,
                                     height: height,
                                     borderRightWidth: "thin",
                                     borderRightColor: cell.border.rightColor,
                                     borderRightStyle: "solid"
                                 }}
                            />)
                    } else if (this.props.showNonPrintableObjects) {
                        borders.push(
                            <div
                                className={"ws-designer-table-cell-border-right ws-designer-table-cell-border-right-gone"}
                                key={this.props.id + "-border-right-" + (x + r) + "-" + (y + c)}
                                style={{top: offsetY, height: height}}
                            />
                        )
                    }
                }

                if (x + r === this.props.data.rows.length - 1) {
                    if (cell.border.bottomShow) {
                        borders.push(
                            <div className={"ws-designer-table-cell-border-bottom"}
                                 key={this.props.id + "-border-bottom-" + (x + r) + "-" + (y + c)}
                                 style={{
                                     left: offsetX,
                                     width: width,
                                     borderBottomWidth: "thin",
                                     borderBottomColor: cell.border.bottomColor,
                                     borderBottomStyle: "solid"
                                 }}
                            />
                        )
                    } else if (this.props.showNonPrintableObjects) {
                        borders.push(
                            <div
                                className={"ws-designer-table-cell-border-bottom ws-designer-table-cell-border-bottom-gone"}
                                key={this.props.id + "-border-bottom-" + (x + r) + "-" + (y + c)}
                                style={{left: offsetX, width: width}}
                            />
                        )
                    }
                }

                offsetX += width
            }
            offsetY += height
        }

        return borders
    }
    renderCells = () => {
        let rows: JSX.Element[] = []
        let cells: JSX.Element[] = []

        // Add table row with height 0 so tables with colspan in first row are rendered correct on thumbnails
        rows.push(<tr style={{height: 0}} key={this.props.id + "-tr-f"}>
            {this.props.data.cols.map((c, i) => <td key={this.props.id + "-td-f-" + i} width={c.width}></td>)}
        </tr>)

        for (let i = 0; i < this.props.data.rows.length; i++) {
            const row = this.props.data.rows[i]

            cells = []
            for (let j = 0; j < this.props.data.cols.length; j++) {
                const col = this.props.data.cols[j]

                if (this.props.data.cells[i] === undefined) {
                    continue
                }
                const cell = this.props.data.cells[i][j]

                if (cell.connectedRow || cell.connectedCol) {
                    continue
                }

                let width = col.width
                let colspan = cell.colspan || 1
                for (let k = 1; k < colspan; k++) {
                    width += this.props.data.cols[j + k].width
                }

                let height = row.height
                let rowspan = cell.rowspan || 1
                for (let k = 1; k < rowspan; k++) {
                    height += this.props.data.rows[i + k].height
                }

                let element: JSX.Element = <></>
                let overflow = false
                if (cell.worksheetElementType === WorksheetItemTypeEnum.TEXTBOX) {
                    if (cell.textboxRef === undefined) {
                        cell.textboxRef = React.createRef()
                    }

                    // Legacy support - data was stored as string
                    try {
                        cell.data = typeof cell.data === "string" ? JSON.parse(cell.data) : cell.data
                    } catch {
                        cell.data = new WDTextboxData("", false, false, VerticalAlignment.top)
                    }

                    let cellTextbox = new WorksheetItem(this.props.id + "-" + i + "-" + j,
                        this.props.element.worksheetPageKey, WorksheetItemTypeEnum.TEXTBOX, 0, 0, width,
                        height, "{}", false, false, false, false)
                    cellTextbox.paddingLeft = cell.paddingLeft
                    cellTextbox.paddingRight = cell.paddingRight
                    cellTextbox.paddingTop = cell.paddingTop
                    cellTextbox.paddingBottom = cell.paddingBottom

                    element = <WDTextbox
                        className={"ws-designer-textbox ws-designer-table-cell-text"}
                        isIndependentElement={false}
                        id={this.props.id + "-" + i + "-" + j}
                        hasResizeOnCreate={false}
                        hasSpellCheck={cell.spellCheck}
                        element={cellTextbox}
                        fireUpdateOnBlur={true}
                        resizeOptions={{showResizer: false, showError: false}}
                        isReadOnly={this.props.isReadOnly || !this.isBorderModeOff()}
                        onFocus={() => this.onFocusTextbox(i, j)}
                        onUpdateElement={(update) => this.updateTextData(i, j, update)}
                        onShowAttentionInForeground={this.props.onShowAttentionInForeground}
                        solutionForceMode={this.props.solutionForceMode}
                        inPresentationMode={this.props.inPresentationMode}
                        showNonPrintableObjects={this.state.showNonPrintableObjects}
                        onFinishSyllabification={this.onFinishSyllabification}
                        updateHistory={(value) => this.updateHistory(i, j, value)}
                        pushHistory={(before, after) => this.pushHistory(i, j, before, after)}
                        data={cell.data}
                        context={this.props.context}
                        ref={cell.textboxRef}
                    />
                }
                // else if (cell.data !== "") {
                //     if (cell.imageRef === undefined) {
                //         cell.imageRef = React.createRef()
                //     }
                //
                //     element = <WDImage isReadonly={false}
                //                        id={this.props.id + "-img-" + i + "-" + j}
                //                        context={this.props.context}
                //                        initialLayout={new ElementLayout(0, 0, 100, 100)}
                //                        initialTransformation={this.props.initialTransformation}
                //                        initialColor={new ElementFillStyle("transparent", this.props.initialColor.transparency)}
                //                        isSelected={false}
                //                        isDeleted={false}
                //                        isLocked={false}
                //                        data={cell.data}
                //                        ref={cell.imageRef}
                //    />
                // }

                // let borderBottomShow = cell.border.bottomShow
                // let borderBottomColor = cell.border.bottomColor
                // if (cell.rowspan > 1) {
                //     let bottomCell = this.props.data.cells[i + cell.rowspan - 1][j]
                //     borderBottomShow = bottomCell.border.bottomShow
                //     borderBottomColor = bottomCell.border.bottomColor
                // }

                let selected = this.isCellSelected(i, j) && this.props.element.selected
                let background = cell.background
                if (selected) {
                    if (cell.background === undefined || cell.background === "transparent") {
                        background = "#DADFF255"
                    } else {
                        background = Util.shadeColor(background, -15)
                    }
                }

                cells.push(<td
                    className={"ws-designer-table-cell" + (overflow ? " ws-designer-table-cell-overflow" : "")}
                    style={{backgroundColor: background}}
                    width={width + "px"}
                    height={height + "px"}
                    colSpan={cell.colspan}
                    rowSpan={cell.rowspan}
                    key={this.props.id + "-td-" + i + "-" + j}
                    onMouseDown={() => this.onMouseDownCell(i, j)}
                >

                    <div id={this.props.id + "-td-" + i + "-" + j + "-container"}
                         className={"ws-designer-table-cell-container"}
                         style={{
                             width: width - (cell.paddingLeft || 0) - (cell.paddingRight || 0),
                             height: height - (cell.paddingTop || 0) - (cell.paddingBottom || 0),
                             marginLeft: cell.paddingLeft,
                             marginRight: cell.paddingRight,
                             marginTop: cell.paddingTop,
                             marginBottom: cell.paddingBottom
                         }}
                    >
                        <div
                            style={{
                                width: width - (cell.paddingLeft || 0) - (cell.paddingRight || 0),
                                height: height - (cell.paddingTop || 0) - (cell.paddingBottom || 0),
                                overflow: "hidden"
                            }}
                        >
                            {element}
                        </div>
                    </div>

                    {this.evaluateBorder(i, j).map(b => b)}
                </td>)
            }
            rows.push(<tr
                style={{height: row.height}}
                key={this.props.id + "-tr-" + i}>

                {cells.map(c => c)}
            </tr>)
        }

        return rows
    }
    renderResizer = () => {
        if (this.state.borderMode !== ManualBorderMode.Off) {
            return
        }

        let resizer: JSX.Element[] = []
        let rowspan = 1, colspan = 1
        let skipY = 0

        // Row height resizer
        let posY = Const.ELEMENT_PADDING
        for (let i = 0; i < this.props.data.rows.length; i++) {
            let row = this.props.data.rows[i]

            if (this.props.data.cells[i] === undefined) {
                continue
            }

            let posX = Const.ELEMENT_PADDING
            for (let j = 0; j < this.props.data.cols.length; j++) {
                let col = this.props.data.cols[j]
                let cell = this.props.data.cells[i][j]

                colspan = cell.colspan || 1

                let skipResizerY = false
                rowspan = cell.rowspan || 1
                if (rowspan > 1) {
                    skipY = colspan
                }

                if (skipY > 0) {
                    skipResizerY = true
                    skipY--
                }
                skipResizerY = skipResizerY || (cell.connectedRow && (i < this.props.data.rows.length - 1 && this.props.data.cells[i + 1][j].connectedRow))

                if (!skipResizerY) {
                    // Row resizer
                    resizer.push(<div className={"ws-designer-table-resizer-y"}
                                      key={this.props.id + "-resizer-y-" + i + "-" + j}
                                      style={{
                                          width: col.width,
                                          height: "10px",
                                          top: posY - 5 + row.height,
                                          left: posX
                                      }}
                                      onMouseDown={(e) => this.onMouseDownResizer(e, i, WDTableDimensionMode.ROW)}
                    />)
                }

                posX += col.width

                let skipResizerX = (cell.colspan || 1) > 1
                skipResizerX = skipResizerX || (cell.connectedCol && (j < this.props.data.cols.length - 1 && this.props.data.cells[i][j + 1].connectedCol))
                if (!skipResizerX) {
                    // Column resizer
                    resizer.push(<div className={"ws-designer-table-resizer-x"}
                                      key={this.props.id + "-resizer-x-" + i + "-" + j}
                                      style={{
                                          width: "10px",
                                          height: row.height,
                                          top: posY,
                                          left: posX - 5
                                      }}
                                      onMouseDown={(e) => this.onMouseDownResizer(e, j, WDTableDimensionMode.COLUMN)}
                    />)
                }
            }
            posY += row.height
        }

        return resizer
    }
    renderSelector = () => {
        if (this.state.borderMode !== ManualBorderMode.Off) {
            return
        }

        let selectors: JSX.Element[] = []
        let data = this.props.data

        // Row selector
        let posY = Const.ELEMENT_PADDING + 2
        for (let i = 0; i < data.rows.length; i++) {
            let row = data.rows[i]

            if (data.cells[i] === undefined) {
                continue
            }

            let className = "ws-designer-table-selector-y"

            let selectedRow = false
            for (let j = 0; j < data.cols.length; j++) {
                selectedRow = selectedRow || this.isCellSelected(i, j)
            }

            if (selectedRow && this.props.element.selected) {
                className += " ws-designer-table-row-highlight"
            }

            selectors.push(<div className={className}
                                key={this.props.id + "-selector-y-" + i}
                                style={{height: row.height, top: posY, left: 0}}
                                onMouseDown={() => this.onMouseDownSelector(WDTableDimensionMode.ROW, i)}
                                onMouseMove={(e) => this.onMouseMoveSelector(e, i)}
            />)

            posY += row.height
        }

        // Column selector
        let posX = Const.ELEMENT_PADDING
        for (let i = 0; i < data.cols.length; i++) {
            let col = data.cols[i]
            let className = "ws-designer-table-selector-x"

            let selectedColumn = false
            for (let j = 0; j < data.rows.length; j++) {
                selectedColumn = selectedColumn || this.isCellSelected(j, i)
            }

            if (selectedColumn && this.props.element.selected) {
                className += " ws-designer-table-col-highlight"
            }

            selectors.push(<div className={className}
                                key={this.props.id + "-selector-x-" + i}
                                style={{width: col.width, top: 0, left: posX}}
                                onMouseDown={() => this.onMouseDownSelector(WDTableDimensionMode.COLUMN, i)}
                                onMouseMove={(e) => this.onMouseMoveSelector(e, i)}
            />)
            posX += col.width
        }

        return selectors
    }

    render() {
        // if element is marked as deleted, do not render
        if (this.props.element.deleted || this.props.data.rows === undefined || this.props.data.cols === undefined) {
            return <></>
        }

        let tableElement: JSX.Element = <div className={"ws-designer-table print"}
                                             style={{padding: Const.ELEMENT_PADDING}}>
            <table className={"ws-designer-table-table " + BorderUtils.getCursorClass(this.state.borderMode)}
                   id={this.props.id + "-table"}
            >
                <tbody>
                {this.renderCells()}
                </tbody>
            </table>

            {/* Resizer for columns and rows */}
            {this.renderResizer()}

            {/* Selector for columns and rows */}
            {this.renderSelector()}
        </div>

        let loading: JSX.Element = <></>
        let overlay: JSX.Element = <></>
        let syllableManually: JSX.Element = <></>

        const minWidth = this.getMinWidth()
        const minHeight = this.getMinHeight()

        if (this.props.isIndependentElement) {

            // Check if there are words which could not be found in database for syllabification
            if (this.props.data.syllableActivated && !this.state.syllableManuallyClosedDialog &&
                this.props.element.selected && this.state.notFoundWords.length > 0) {

                // Check if loading should be active
                if (this.state.syllableLoading) {
                    loading = <div className={"ws-designer-textbox-loading-container"}>
                        <Hint id={"list-loading"}
                              notificationData={new NotificationData(NotificationStatus.loading, this.context.translate(translations.notification.loading))}/>
                    </div>
                }

                overlay = <div className={"ws-designer-overlay"}/>

                syllableManually = <SyllableManualDialog
                    notFoundWords={this.state.notFoundWords}
                    colorFirstSyllable={this.state.userSettings?.syllableColor1 || Const.COLOR_PRIMARY}
                    colorSecondSyllable={this.state.userSettings?.syllableColor2 || Const.COLOR_RED}
                    closeSyllableNotFoundDialog={this.closeSyllableNotFoundDialog}
                    addSyllableTextManually={this.addSyllableTextManually}/>
            }

            // if the container is too small render nothing
            if (this.props.element.width <= minWidth || this.props.element.height <= minHeight) {
                tableElement = <></>
            }

            return <WDElementContainer id={this.props.id}
                                       element={this.props.element}
                                       hasResizeOnCreate={this.props.hasResizeOnCreate}
                                       onEdit={this.onEditElement}
                                       resizeInfo={this.resizeNodes}
                                       renderWrapper={true}
                                       onUnlockElement={this.unlockElement}
                                       onResizeStateChanged={this.props.onResizeStateChanged}
                                       onResizeElement={this.props.onElementResize}
                                       isEditModeAllowed={this.isEditModeAllowed}
                                       isReadOnly={this.props.isReadOnly}
                                       onContextMenu={this.props.onContextMenu}
                                       ref={this.state.elementRef}
            >
                {overlay}
                {loading}
                {tableElement}
                {syllableManually}
            </WDElementContainer>
        }

        return tableElement
    }
}
