import React from "react";
import {MenuContentType, MenuItem} from "../../_model/MenuItem";
import {CollapseState} from "./MenuLevel";
import {Tooltips, TooltipText} from "../Tooltips";
import {Link} from "react-router-dom";
import {getMenuItemCSS, MenuContext} from "./Menu";
import Const from "../../Framework/Const";
import {
    WDCalculationTriangle,
    WDCalculationTriangleData,
    WDCalculationTriangleTextData
} from "../../Designer/Elements/Math/CalculationTriangle/WDCalculationTriangle";
import {CategoryImageValue, ImageCategory, ImagePath} from "../../Framework/CategoryImage";
import {CalculationType} from "../../Designer/Toolbar/Button/Math/WDToolbarButtonCalculationType";
import {
    WDWritingLineature,
    WDWritingLineatureData
} from "../../Designer/Elements/Lineature/WritingLineature/WDWritingLineature";
import {
    getDefaultCorrectionMargin
} from "../../Designer/Elements/Lineature/WritingLineature/WDWritingLineatureCorrectionMargin";
import {Difficulty, ExerciseCalculationType, Status} from "../../Framework/Enums";
import {
    getDefaultFontName,
    getDefaultFontValue,
    getFontValue
} from "../../Designer/Elements/Lineature/WritingLineature/WDWritingLineatureFontValues";
import {WDMathLineature} from "../../Designer/Elements/Lineature/MathLineature/WDMathLineature";
import {WDMathLineatureData} from "../../Designer/Elements/Lineature/MathLineature/WDMathLineatureData";
import {
    WDCalculationTower,
    WDCalculationTowerData
} from "../../Designer/Elements/Math/CalculationTower/WDCalculationTower";
import {WDTextExerciseData} from "../../Designer/Elements/TextExercise/WDTextExerciseData";
import {NumberPlacement} from "../../Designer/Toolbar/Button/Math/WDToolbarButtonNumberPlacementDifficulty";
import {WorksheetItemTypeEnum} from "../../_model/WorksheetItemType";
import WritingCourse from "../../_model/WritingCourse";
import {WDLine} from "../../Designer/Elements/Line/WDLine";
import {WDTextbox, WDTextboxData} from "../../Designer/Elements/Textbox/WDTextbox";
import Conf from "../../Framework/Conf";
import {WDImageData} from "../../Designer/Elements/Image/WDImage";
import {WDBalloon} from "../../Designer/Elements/Balloon/WDBalloon";
import {WDShape2d, WDShapeData2d} from "../../Designer/Elements/Shape/WDShape2d";
import {WDShape3d, WDShapeData3d} from "../../Designer/Elements/Shape/WDShape3d";
import {WDShapeBuildingBrick, WDShapeDataBuildingBrick} from "../../Designer/Elements/Shape/WDShapeBuildingBrick";
import {WDShapeCraftPatternData} from "../../Designer/Elements/Shape/WDShapeCraftPattern";
import {WDTableData} from "../../Designer/Elements/Table/WDTableData";
import {WDTable} from "../../Designer/Elements/Table/WDTable";

interface IProps {
    collapseState: CollapseState
    itemClicked?: MenuItem
    item: MenuItem
    lastLevelItemIcon?: LastLevelItem
    menuContext: MenuContext
    setItemClicked: (v?: MenuItem) => void
}

interface IState {
    hoverDraggable: boolean
}

export class LastLevelItem {
    width: number
    height: number
    marginLeft: number
    marginRight: number
    marginBottom: number
    iconPath: string

    constructor(width: number, height: number, marginLeft: number, marginRight: number, marginBottom: number, iconPath: string) {
        this.width = width
        this.height = height
        this.marginBottom = marginBottom
        this.marginLeft = marginLeft
        this.marginRight = marginRight
        this.iconPath = iconPath
    }
}

export class MenuEntry extends React.Component<IProps, IState> {

    images: HTMLImageElement[] = []
    tooltip: TooltipText

    constructor(iProps: IProps) {
        super(iProps);

        this.state = {hoverDraggable: false}

        this.checkItemPath();
        this.tooltip = new TooltipText(this.props.item.name)
    }

    componentDidMount() {
        this.preloadDragImages()
    }

    preloadDragImages = () => {
        // Images are already preloaded
        if (this.images.length > 0) {
            return
        }

        if (this.props.item.worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TRIANGLE) {
            new Image().src = process.env.PUBLIC_URL + ImagePath.getTriangleUrl() + CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TRIANGLE], "RD01A")
        } else if (this.props.item.worksheetItemTypeId === WorksheetItemTypeEnum.WRITING_LINEATURE) {
            if (this.props.item.configData) {
                let configData = JSON.parse(this.props.item.configData)

                let imageName = WDWritingLineature.getImageNameByKey(configData.lineatureSize, configData)

                let image = new Image()
                image.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-l.svg"
                this.images.push(image)

                image = new Image()
                image.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-m.svg"
                this.images.push(image)

                image = new Image()
                image.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-r.svg"
                this.images.push(image)
            }
        } else if (this.props.item.worksheetItemTypeId === WorksheetItemTypeEnum.CALCULATION_TOWER) {
            // TODO wie Writing Lineature
        }
    }

    checkItemPath = () => {
        // Checks if navigation path is same as linkTo for displaying checked
        if (window.location.pathname === (this.props.item.linkTo)) {
            this.props.setItemClicked(this.props.item)
        }
    }

    onClick = () => {
        if (this.props.item.draggable) {
            this.props.setItemClicked(this.props.item)
        }

        if (!this.props.item.draggable && !this.props.item.linkTo) {
            if (this.isOpen()) {
                this.props.setItemClicked(undefined);
            } else {
                this.props.setItemClicked(this.props.item);
            }
        }
    }
    onMouseOver = (event: React.MouseEvent) => {
        this.setState({hoverDraggable: true})
    }
    onMouseLeave = (event: React.MouseEvent) => {
        this.setState({hoverDraggable: false})
    }
    onDragStart = (event: React.DragEvent) => {
        // Set what is visible while dragging
        if (event.dataTransfer) {
            let imageKey: string

            let canvas = document.getElementById("dragImageContainer")
            if (canvas === null || canvas === undefined) {
                throw new Error("Cannot find drag image canvas.")
            }

            if (this.props.item.worksheetItemTypeId) {
                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, this.props.item.worksheetItemTypeId.toString())

                // Clear content of drag image container
                canvas.innerHTML = ""

                // defines default values per item type when dragged from menu
                switch (this.props.item.worksheetItemTypeId) {
                    case WorksheetItemTypeEnum.CALCULATION_TRIANGLE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let maxValue = configData.maxValue
                            imageKey = "M-AT-RD-STANDARD1"

                            if (configData.image) {
                                imageKey = configData.image
                            }

                            let data = new WDCalculationTriangleData(maxValue,
                                maxValue, 0, CalculationType.ADD, Difficulty.none, NumberPlacement.none, [], true,
                                false, true, true, true, true, imageKey,
                                new WDCalculationTriangleTextData(
                                    12,
                                    "#1e1f28",
                                    false,
                                    false,
                                    false,
                                ))
                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

                            // Generate drag image
                            let img = document.createElement("img")
                            img.src = process.env.PUBLIC_URL + ImagePath.getTriangleUrl() + CategoryImageValue.getImageByKey([ImageCategory.CALCULATION_TRIANGLE], imageKey)
                            img.width = WDCalculationTriangle.getDefaultWidth()
                            img.height = WDCalculationTriangle.getDefaultHeight()

                            // Append image to canvas div
                            canvas.append(img)
                            canvas.style.width = img.width + "px"
                            canvas.style.height = img.height + "px"

                            event.dataTransfer.setDragImage(canvas, 0, 0)
                        }
                        break

                    case WorksheetItemTypeEnum.WRITING_LINEATURE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let lineatureSize = configData.lineatureSize
                            imageKey = "D-AT-" + lineatureSize + "-STANDARD"

                            if (configData.image) {
                                imageKey = WDWritingLineature.replaceThumbImage(configData.image, lineatureSize)
                            }

                            let data = new WDWritingLineatureData("",
                                WDTextboxData.defaultContent(),
                                imageKey, getDefaultFontName(), lineatureSize, 1, getDefaultCorrectionMargin(), 0)
                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

                            // Generate drag image
                            let fontValue = getFontValue(getDefaultFontName(), lineatureSize)
                            if (fontValue === undefined) {
                                fontValue = getDefaultFontValue()
                            }

                            let imageName = WDWritingLineature.getImageNameByKey(lineatureSize, configData)

                            let imgLeft = document.createElement("img")
                            imgLeft.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-l.svg"
                            imgLeft.width = 19
                            imgLeft.height = fontValue.rowHeight
                            canvas.append(imgLeft)

                            let imgMiddle = document.createElement("img")
                            imgMiddle.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-m.svg"
                            imgMiddle.width = WDWritingLineature.getDefaultWidth() - 38
                            imgMiddle.height = fontValue.rowHeight
                            canvas.append(imgMiddle)

                            let imgRight = document.createElement("img")
                            imgRight.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-r.svg"
                            imgRight.width = 19
                            imgRight.height = fontValue.rowHeight
                            canvas.append(imgRight)

                            canvas.style.marginTop = fontValue.rowSpacing + "px"
                            canvas.style.width = WDWritingLineature.getDefaultWidth() + "px"
                            canvas.style.height = fontValue.rowHeight + "px"

                            event.dataTransfer.setDragImage(canvas, 0, -fontValue.rowSpacing)
                        }
                        break;

                    case WorksheetItemTypeEnum.MATH_LINEATURE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let lineatureSize = configData.lineatureSize

                            let width = 5, height = 5
                            if (lineatureSize === "6x6") {
                                width = 6
                                height = 6
                            } else if (lineatureSize === "7x10") {
                                width = 7
                                height = 10
                            } else if (lineatureSize === "10x10") {
                                width = 10
                                height = 10
                            }

                            let imageName = WDMathLineature.getImageNameByKey(lineatureSize, configData)

                            let data = new WDMathLineatureData(width, height, 2, configData.colAmount,
                                WDMathLineatureData.mapLineStyle(imageName))
                            data.toolboxInfo = WDMathLineature.getToolboxEntryMathLineatureInfo(configData, data)
                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

                            // Generate drag image
                            let img = document.createElement("img")
                            img.src = process.env.PUBLIC_URL + ImagePath.getLineatureUrl() + imageName + "-thumb.svg"
                            img.width = WDMathLineature.getDefaultWidth(lineatureSize)
                            img.height = WDMathLineature.getDefaultHeight(lineatureSize) * 2
                            canvas.append(img)

                            canvas.style.width = WDMathLineature.getDefaultWidth(lineatureSize) + "px"
                            canvas.style.height = WDMathLineature.getDefaultHeight(lineatureSize) * 2 + "px"

                            event.dataTransfer.setDragImage(canvas, 0, 0)
                        }
                        break;

                    case WorksheetItemTypeEnum.CALCULATION_TOWER:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let maxValue = configData.maxValue
                            imageKey = "M-AT-RT-STANDARD"

                            if (configData.image) {
                                imageKey = configData.image
                            }

                            let data = new WDCalculationTowerData(
                                maxValue,
                                maxValue,
                                0,
                                CalculationType.COMPLEMENT,
                                Difficulty.none,
                                [],
                                true, false, true, true, true,
                                imageKey,
                                new WDCalculationTriangleTextData(12,
                                    "#1e1f28",
                                    false,
                                    false,
                                    false,),
                                3)
                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

                            let imageName = WDCalculationTower.getImageNameByKey(configData)

                            // Generate drag image
                            let img = document.createElement("img")
                            img.src = process.env.PUBLIC_URL + ImagePath.getTowerUrl() + imageName + "-thumb.svg"
                            img.width = WDCalculationTower.getDefaultWidth()
                            img.height = WDCalculationTower.getDefaultHeight()
                            canvas.append(img)

                            canvas.style.width = img.width + "px"
                            canvas.style.height = img.height + "px"

                            event.dataTransfer.setDragImage(canvas, 0, 0)
                        }
                        break

                    case WorksheetItemTypeEnum.TEXT_EXERCISE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)

                            if (configData.image) {
                                let keyParts = configData.image.split("-")

                                let calculationType = ExerciseCalculationType.none
                                if (keyParts[3].toUpperCase().startsWith("A")) {
                                    calculationType = ExerciseCalculationType.add
                                } else if (keyParts[3].toUpperCase().startsWith("S")) {
                                    calculationType = ExerciseCalculationType.subtract
                                } else if (keyParts[3].toUpperCase().startsWith("M")) {
                                    calculationType = ExerciseCalculationType.multiply
                                } else if (keyParts[3].toUpperCase().startsWith("D")) {
                                    calculationType = ExerciseCalculationType.divide
                                } else if (keyParts[3].toUpperCase().startsWith("G1")) {
                                    calculationType = ExerciseCalculationType.add_subtract
                                } else if (keyParts[3].toUpperCase().startsWith("G2")) {
                                    calculationType = ExerciseCalculationType.multiply_divide
                                }

                                let data = new WDTextExerciseData(keyParts[4], calculationType, configData.exerciseType, configData.toolboxItemId)
                                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                            } else {
                                let data = new WDTextExerciseData(configData.level, ExerciseCalculationType.none, configData.exerciseType, configData.toolboxItemId)
                                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                            }
                        }
                        break

                    case WorksheetItemTypeEnum.WRITING_COURSE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let data = new WritingCourse(this.props.item.name, Status.published)

                            data.characterType = configData.characterType
                            data.previewSymbol = configData.previewSymbol
                            data.syllableMethod = configData.syllableMethod
                            data.parentWritingCourse = configData.parentWritingCourse
                            data.lessonSubject = configData.lessonSubject

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.SHAPE2D:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let data = new WDShapeData2d(configData.type)

                            if(configData.image !== undefined) {
                                data.type = WDShape2d.mapImageKeyToShapeType(configData.image)
                            }

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.SHAPE3D:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let data = new WDShapeData3d(configData.type)

                            if(configData.image !== undefined) {
                                data.type = WDShape3d.mapImageKeyToShapeType(configData.image)
                            }

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.SHAPE_BUILDING_BRICK:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let data = new WDShapeDataBuildingBrick(configData.type)

                            if(configData.image !== undefined) {
                                data.type = WDShapeBuildingBrick.mapImageKeyToShapeType(configData.image)
                            }

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.SHAPE_CRAFT_PATTERN:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let image = configData.image !== undefined ? configData.image : configData.imageKey
                            let data = new WDShapeCraftPatternData(image, false, false)

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.LINE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let data = WDLine.getDefaultContentData()

                            if(configData.image !== undefined) {
                                data.type = WDLine.mapImageKeyToLineType(configData.image)
                            }

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.IMAGE:
                        if(this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let data = new WDImageData(+configData.id, +configData.width, +configData.height, configData.status)
                            data.url = this.props.item.icon
                            data.toolboxItems = [this.props.item]

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }

                        break

                    case WorksheetItemTypeEnum.BALLOON:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            let image = configData.image

                            if(image === undefined || image === "") {
                                let category = +ImageCategory[configData.imageCategory]

                                switch (category) {
                                    case ImageCategory.BALLOON:
                                        image = "SPEAK01"
                                        break
                                    case ImageCategory.BALLOON_COMIC:
                                        image = "COMIC01"
                                        break
                                    case ImageCategory.BALLOON_THINK:
                                        image = "THINK01"
                                        break
                                }
                            }

                            let data = WDBalloon.getDefaultContentData()
                            data.image = image

                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                        }
                        break

                    case WorksheetItemTypeEnum.RULE:
                        event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.RULE.toString())

                        if(this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)
                            event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(configData))
                        }
                        break

                    case WorksheetItemTypeEnum.TEXTBOX:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)

                            // Is dragged from toolbox?
                            if(configData.image !== undefined) {
                                let data = WDTextbox.getDefaultData()
                                data.toolboxInfo = WDTextbox.getToolboxEntryTextboxInfo(configData.image)
                                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))
                            } else {
                                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.WORKSHEET_ITEMS.toString())
                                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(configData))
                            }
                        }
                        break

                    case WorksheetItemTypeEnum.TABLE:
                        if (this.props.item.configData) {
                            let configData = JSON.parse(this.props.item.configData)

                            // Is dragged from toolbox?
                            if (configData.image !== undefined) {
                                let toolboxInfo = WDTable.getToolboxEntryTableInfo(configData.image)
                                if (toolboxInfo === undefined) { return }

                                let data = WDTableData.createEmptyTableData(
                                    toolboxInfo.rowAmount,
                                    toolboxInfo.columnAmount,
                                    (toolboxInfo.height - (2 * Const.ELEMENT_PADDING)) / toolboxInfo.rowAmount,
                                    (toolboxInfo.width - (2 * Const.ELEMENT_PADDING)) / toolboxInfo.columnAmount
                                    )
                                data.toolboxInfo = toolboxInfo

                                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(data))

                            } else {
                                event.dataTransfer.setData(Const.DATA_TRANSFER_WS_ITEM_ID, WorksheetItemTypeEnum.WORKSHEET_ITEMS.toString())
                                event.dataTransfer.setData(Const.DATA_TRANSFER_CONFIG_DATA, JSON.stringify(configData))
                            }
                        }
                        break
                }
            }
        }
    }

    // Define how menu entry looks
    getValueRender(isOpen: boolean) {
        let icon = process.env.PUBLIC_URL + (this.props.lastLevelItemIcon?.iconPath || ImagePath.getMenuUrl()) + this.props.item.icon;
        if (this.props.item.menuContentType === MenuContentType.thumbnail && this.props.item.icon) {
            icon = ImagePath.getThumbnailUrl() + this.props.item.icon
        }
        if (this.props.item.menuContentType === MenuContentType.image && this.props.item.icon) {
            icon = Conf.IMAGE_URL() + this.props.item.icon
        }

        let imageClass = "menu-item-img"
        let textClass = "menu-item-text"

        // check css classes for rendering - depending on entry values
        if (this.props.item.icon != null && this.props.item.draggable) {
            imageClass = "menu-item-img-draggable"
            textClass = "menu-item-text-draggable"
        }

        // every menu layer except last one
        if(this.props.lastLevelItemIcon === undefined) {
            return <>
                {/* check if menu entry has an image */}
                {(this.props.item.icon !== null)
                    ? <img className={imageClass}
                           src={icon} draggable="false" alt={this.props.item.name}
                           onContextMenu={(e) => e.preventDefault()}
                    />
                    : <></>}

                {/* check if menu entry is collapsed to show text */}
                {(this.props.collapseState !== CollapseState.open)
                    ? <Tooltips text={this.tooltip} />
                    : <p className={textClass}>{this.props.item.name}</p>}

                {/* show hover effect in 3. layer */}
                {(this.props.item.draggable && this.state.hoverDraggable && true)
                    ? <div className="menu-item-draggable-hover">
                        <div>{this.props.item.name}</div>
                    </div>
                    : <></>}
            </>
        }

        // for last menu layer
        return <div className={"menu-item-img-draggable"}
                    style={{
                        width: this.props.lastLevelItemIcon.width,
                        height: this.props.lastLevelItemIcon.height
        }}>
            {/* check if menu entry has an image */}
            {(this.props.item.icon !== undefined)
                ? <img
                    style={{
                        width: "auto",
                        maxWidth: this.props.lastLevelItemIcon.width,
                        height: "100%"
                    }}
                    src={icon} draggable="false" alt={this.props.item.name}
                    onContextMenu={(e) => e.preventDefault()}
                />
                : (this.props.item.description !== undefined
                    ? <div key={this.props.item.id} className={"menu-last-level-item-text-group"}
                           style={{width: this.props.lastLevelItemIcon.width, height: this.props.lastLevelItemIcon.height - 20}}>
                        <div className={"menu-last-level-item-text-group-title"}>{this.props.item.name}</div>
                        <div className={"menu-last-level-item-text-group-text"}>{this.props.item.description}</div>
                    </div>
                    : <></>
                )
            }
        </div>
    }

    isOpen = () => {
        return (this.props.item.id === this.props.itemClicked?.id)
            || window.location.pathname.startsWith(process.env.PUBLIC_URL + this.props.item.linkTo!)
    }

    render() {
        // condition for item is image in last level - rendering is different
        let renderImageCondition = this.props.item.draggable && this.props.item.icon !== null

        // check if menu item should be shown clicked - when it is selected or if url has linkTo path in it
        const isOpen = this.isOpen()
        const menuItemClass = getMenuItemCSS(this.props.menuContext, isOpen, !(renderImageCondition))

        //check if menu item-container is rendered in last level -> hover effect
        const menuContainerClass = this.props.lastLevelItemIcon ? "menu-sublevel-item-container menu-last-level-hover" : "menu-item-container"

        // check if menu item has a link to another page (like app items)
        let valueRender = this.getValueRender(isOpen)
        if (this.props.item.linkTo) {
            valueRender = <Link to={this.props.item.linkTo} className="menu-item-link" draggable="false">
                {valueRender}
            </Link>
        } else {
            valueRender = <div className={menuContainerClass}>
                {valueRender}
            </div>
        }

        return <li id={"menuItem" + this.props.item.id} className={menuItemClass} draggable={this.props.item.draggable}
                   style={{
                       width: this.props.lastLevelItemIcon?.width,
                       height: this.props.lastLevelItemIcon?.height,
                       marginLeft: this.props.lastLevelItemIcon?.marginLeft,
                       marginRight: this.props.lastLevelItemIcon?.marginRight,
                       marginBottom: this.props.lastLevelItemIcon?.marginBottom
                   }}
                   onClick={this.onClick}
                   onMouseOver={(this.props.item.icon != null && this.props.item.draggable) ? this.onMouseOver : undefined}
                   onMouseLeave={(this.props.item.icon != null && this.props.item.draggable) ? this.onMouseLeave : undefined}
                   onDragStart={this.props.item.draggable ? this.onDragStart : undefined}>

            {valueRender}
        </li>
    }
}
