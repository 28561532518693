import React, {RefObject} from "react";
import {WDElementContainer} from "./WDElementContainer";
import {WDToolbarAction} from "../Toolbar/WDToolbarAction";
import Const from "../../Framework/Const";
import {ElementDefinition} from "../Toolbar/Toolbar/WDToolbarElement";
import {RESIZE_NODE} from "./Enum/WDElementContainerResizeNode";
import {WSContextType} from "./WSContext";
import {Worksheet} from "../../_model/Worksheet";
import {WorksheetItemUpdate, WorksheetItemUpdateOptions} from "../Utils/WorksheetItemUpdate";
import {WorksheetItem} from "../../_model/WorksheetItem";
import {WDPresentationAction} from "../Presentation/WDPresentationAction";
import {WDElementHistoryItem} from "../History/WDElementHistoryItem";
import {SolutionForceMode} from "../../Framework/Enums";

/**
 * Base class for all elements - elements extend from base
 * includes default implementation for element methods
 * interface between designer and elements
 */

export class WDElementBaseData {
    static serialize(data: WDElementBaseData): string {
        return JSON.stringify(data)
    }
}

export interface WDElementBaseProps {
    id: string
    element: WorksheetItem
    worksheetItemTypeId?: number
    data?: WDElementBaseData
    context: WSContextType
    isReadOnly: boolean

    solutionForceMode: SolutionForceMode
    showNonPrintableObjects: boolean
    inPresentationMode: boolean

    onUpdateElement: (update: WorksheetItemUpdate, options?: WorksheetItemUpdateOptions) => Promise<void>
    updateHistory: (value: WDElementHistoryItem) => void
    pushHistory: (before: WorksheetItemUpdate[], after: WorksheetItemUpdate[]) => void

    onElementLoaded?: (id: string) => void
    onElementSelect?: (id: string, newState: boolean, resetSelection: boolean) => void
    onElementEdit?: (id: string, editMode: boolean) => void
    onElementResize?: (proportional: boolean, x: number, y: number) => void

    onResizeStateChanged?: (state: boolean, nodeType?: RESIZE_NODE) => void
    onContextMenu?: (id: string, e: React.MouseEvent) => void

    onShowAttentionInForeground: (itemKey: string, showInForeground: boolean) => void
}
export interface WDElementBaseState {
    isEdited: boolean
    showNonPrintableObjects: boolean

    elementRef: RefObject<WDElementContainer>
}

export class WDElementBase<T extends WDElementBaseProps = WDElementBaseProps,
    U extends WDElementBaseState = WDElementBaseState> extends React.Component<T, U> {

    static getDefaultContent = (): string => {
        return "{}"
    }

    onEditElement = async(editMode: boolean) => {
        if (editMode !== this.state.isEdited) {

            // Set focus when entering edit mode
            if (!editMode) {
                this.state.elementRef.current?.onStopEdit()
            }

            this.setState({isEdited: editMode}, () => {
                // Tell designer this is in edit mode
                // Cancels all event handlers to avoid element being dragged while in edit mode
                this.props.onElementEdit?.(this.props.id, editMode)
            })
        }
    }

    resizeElement = (proportional: boolean, x: number, y:number, nodeType?: RESIZE_NODE) => {
        // Tell embedded WSDesignerElementContainer to resize
        return this.state.elementRef.current?.resizeElement(proportional, x, y, nodeType)
    }
    recalculateSize = (width: number, height: number, adjustSize: boolean): WorksheetItemUpdate => {
        return new WorksheetItemUpdate(this.props.id, {
            width: width, height: height
        })
    }
    isEditModeAllowed = (): boolean => {
        return true
    }

    doAction = (action: WDToolbarAction, data?: string): WorksheetItemUpdate => {
        return new WorksheetItemUpdate(this.props.id, {})
    }
    doPresentationAction = (action: WDPresentationAction, data?: string): WorksheetItemUpdate => {
        return new WorksheetItemUpdate(this.props.id, {})
    }

    setElementDeleted = () => {
        this.onEditElement(false)
    }
    setResizeState = (state: boolean, nodeType?: RESIZE_NODE) => {
        this.state.elementRef.current?.setResizeState(state, nodeType)

        return new WorksheetItemUpdate(this.props.id, {
            width: this.props.element.width,
            height: this.props.element.height,
            resized: state
        })
    }
    setLoaded = () => { }
    setAttentionInForeground = (showInForeground: boolean) => {

    }

    serializeElementData = (data: WDElementBaseData) => {
        return WDElementBaseData.serialize(data)
    }
    getElementExerciseWorksheet = () : Worksheet | undefined => {
        return undefined
    }
    getSourceRecordId = () : number | undefined => {
        return undefined
    }
    getAdditionalToolbarData = (): string => {
        return "{}"
    }
    getAdditionalActionData = (): any => {
        let baseData = {
            isEdited: this.state.isEdited,
            isReadOnly: this.props.isReadOnly
        }
        let elementData = this.getAdditionalActionDataElement()

        return { ...baseData, ...elementData }
    }
    getAdditionalActionDataElement = (): any => {
        return {}
    }

    toggleNonPrintObjectsVisibility = (visible: boolean) => {
        this.setState({showNonPrintableObjects: visible})
    }

    hasNameConfigInstancesEnabled = (): boolean => {
        return true
    }
    getNameConfigInstances = (): number[] => {
        // hasNameConfigInstancesEnabled and/or getNameConfigInstances need to be implemented for each worksheet element
        throw new Error("Name config instance handling is not implemented")
    }

    onPropagateEvent = (e: React.UIEvent) => {
        this.state.elementRef.current?.onPropagateEvent(e)
    }

    getMinWidth = () => {
        return Const.MinElementSize
    }
    getMinHeight = () => {
        return Const.MinElementSize
    }

    // Returns the selected sub-element for composed components (e.g. text exercise)
    getFocusElement = (): ElementDefinition | undefined => {
        return undefined
    }
    setFocus = () => {

    }

    unlockElement = (locked: boolean = false) => {
        this.props.onUpdateElement?.(new WorksheetItemUpdate(this.props.element.itemKey, {
            locked: locked
        }), { applyToLocked: true })
    }
}
