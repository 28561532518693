import React from 'react';
import {Worksheet, WorksheetFilter} from "../../_model/Worksheet";
import {MainContext} from "../../_base/MainContext";
import {ColumnDefinition} from "../../Components/List/List";
import translations from "../../Framework/translations.json";
import {Notification} from "../../Components/Notification/NotificationHandler";
import {
    CloneWorksheet,
    DeleteWorksheet,
    GetUserWorksheetListFiltered,
    GetWorksheetById,
    GetWorksheetStatus,
    GetWorksheetThumbnail,
    SetWorksheetStatus
} from "../../_endpoint/WorksheetEndpoint";
import {
    AdminObjectType,
    ColumnRenderOption,
    EntityToEnumValue,
    EnumToEntityArray,
    EnumValueToEntity,
    EnumValueToIndex,
    ListStyle,
    Status,
    WSMarketplaceStatus,
    WSStatus
} from "../../Framework/Enums";
import WSDesignerListItem from "./WSDesignerListItem";
import {TooltipPosition, TooltipText} from "../../Components/Tooltips";
import Entity from "../../_model/Entity";
import SchoolLevel from "../../_model/SchoolLevel";
import {GetLessonSubject, GetSchoolLevel} from "../../_endpoint/MetadataEndpoint";
import LessonSubject from "../../_model/LessonSubject";
import {ListFilter, ListFilterDefinition, ListFilterDefinitionItem} from "../../Components/List/ListFilter";
import {WSContextType} from "../Elements/WSContext";
import {MPStatusIcon} from "../../Marketplace/MPStatusIcon";
import {CardAction, CardDefinition} from "../../Components/List/Card";
import AppHeader from "../../_base/AppHeader";
import {Menu, MenuContext, MenuType} from "../../Components/Menu/Menu";
import DataList from "../../Components/List/DataList";
import {ImagePath} from "../../Framework/CategoryImage";
import Const from "../../Framework/Const";
import {Modal} from "../../Components/Modal";
import {ButtonInfo} from "../../Components/Controls/ButtonList";
import {RouteComponentProps} from "react-router-dom";
import {Util} from "../../Framework/Util";

interface IProps extends RouteComponentProps {
}
interface IState {
    items: Worksheet[],
    lastElementReached: boolean
    pageSize: number
    page: number
    schoolLevels?: SchoolLevel[]
    lessonSubjects?: LessonSubject[]

    filter: WorksheetFilter
    listItems?: WSDesignerListItem[]
    listStyle: ListStyle
    listFilter: ListFilterDefinition[]

    loading: boolean
    modal: WSStatus
    statusChangeItems?: number[]
}

export default class WSDesignerList extends React.Component<IProps, IState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    // width in %
    columnDefinition = [
        new ColumnDefinition(this.context.translate(translations.fields.marketplace), "marketplaceStatus", 3, false, false, ColumnRenderOption.MarketplaceStatus),
        new ColumnDefinition(this.context.translate(translations.fields.title), "name", 77, true, true),
        new ColumnDefinition(this.context.translate(translations.fields.status), "status", 10, false, true),
        new ColumnDefinition(this.context.translate(translations.fields.modified_on), "modifiedOn", 10, false, true, ColumnRenderOption.DateTime)
    ]

    constructor(props: IProps, state: IState) {
        super(props, state)

        this.state = {
            items: [],
            lastElementReached: false,
            loading: false,
            pageSize: 100,
            page: 0,
            listStyle: ListStyle.card,
            filter: new WorksheetFilter(),
            listFilter: [],
            modal: WSStatus.active
        }
    }

    componentDidMount() {
        this.context.getUserSettings().then (userSettings => {
            this.setState({pageSize: userSettings.listPageSize})
        })

        this.getEnumFilterData().then( () => {
            this.fetchData(0, false)
        }, (error) => {
            this.context.handleError(error)
        })
    }

    getCurrentStatusFilter = () => {
        let filter: ListFilter | undefined = this.context.getListFilter(AdminObjectType.worksheet)
        if (filter) {
            let statusFilter = filter.filterValues.find(v => v.filter.name === "status")
            if (statusFilter && statusFilter.value) {
                let statusArray = EnumToEntityArray(WSStatus, translations.enum.ws_status, this.context.translate)
                let status = statusArray.find(s => s.id === statusFilter!.value)

                if (status) {
                    return EntityToEnumValue(status, WSStatus)
                }
            }
        }

        return WSStatus.active
    }

    fetchData = (page: number, append: boolean, searchTerm?: string) => {
        if (!this.state.loading && (!this.state.lastElementReached || (this.state.lastElementReached && !append))) {
            let items = append ? this.state.items : []
            let listItems = append ? this.state.listItems : []

            this.setState({loading: true, items: items, listItems: listItems, page: page}, () => this.fetchWSList(page, searchTerm))
        }
    }
    fetchWSList = (page: number, searchTerm?: string) => {
        const filter = new WorksheetFilter()
        filter.search = searchTerm === "" ? undefined : searchTerm

        let listFilter: ListFilter | undefined = this.context.getListFilter(AdminObjectType.worksheet)
        if (listFilter) {
            listFilter.filterValues.forEach((value, key) => {
                switch (value.filter.name) {
                    case "status":
                        switch (value.value) {
                            case 0:
                                filter.status = WSStatus.active
                                break
                            case 1:
                                filter.status = WSStatus.paperbin
                                break
                        }
                        break

                    case "lesson-subject":
                        switch (value.value) {
                            case -1:
                                filter.subject = undefined
                                break
                            default:
                                filter.subject = this.state.lessonSubjects?.find(s => s.id === value.value)
                        }
                        break

                    case "school-level":
                        switch (value.value) {
                            case -1:
                                filter.schoolLevels = undefined
                                break
                            default:
                                let schoolLevel = this.state.schoolLevels?.find(s => s.id === value.value)
                                filter.schoolLevels = schoolLevel ? [schoolLevel] : undefined
                        }
                }
            })
        }

        GetUserWorksheetListFiltered(filter, page, this.state.pageSize).then(
            (itemData) => {
                if (itemData.length > 0) {
                    let worksheetsInLoadingState: Worksheet[] = []

                    let listItems = this.state.listItems || []
                    const listItemsNew = this.convertWorksheetToListItem(itemData, worksheetsInLoadingState)
                    listItems = listItemsNew.concat(listItems)

                    let items = itemData.concat(this.state.items)

                    let hasLoadingWorksheets = worksheetsInLoadingState.length > 0

                    this.setState({
                        items: items,
                        listItems: listItems,
                        loading: hasLoadingWorksheets,
                        lastElementReached: (items.length % this.state.pageSize) !== 0},
                        () => {
                        //If worksheets are not fully created (after download) ask for changes
                        if (hasLoadingWorksheets) {
                            this.fetchLoadingWorksheets(worksheetsInLoadingState)
                        }
                    })
                }
                else {
                    this.setState({loading: false, lastElementReached: true})
                }
            },
            (error) => {
                this.context.handleError(error, this.context.translate(translations.notification.unexpected_error))
            }
        )
    }
    fetchLoadingWorksheets = (worksheetStatusIds: Worksheet[]) => {
        let promises: Promise<Worksheet>[] = []

        for (let ws of worksheetStatusIds) {
            promises.push(GetWorksheetStatus(ws.id))
        }

        Promise.all(promises).then((items) => {
            let loadingWorksheets = items.filter(ws => ws.status === WSStatus.loading)

            // If worksheets are not yet ready try again
            if (loadingWorksheets.length > 0) {
                setTimeout(() => this.fetchLoadingWorksheets(loadingWorksheets), 10000)
            }
            else {
                let worksheetItems = this.state.items

                // Change worksheet status
                items.forEach(i => {

                    worksheetItems = worksheetItems.map(wsItem => {
                        if(wsItem.id === i.id) {
                            wsItem.status = i.status
                        }
                        return wsItem
                    })
                })

                let worksheetListItems = this.convertWorksheetToListItem(worksheetItems, [])

                this.setState({items: worksheetItems, listItems: worksheetListItems, loading: false})
            }
        })
    }
    convertWorksheetToListItem = (worksheets: Worksheet[], worksheetsInLoadingState: Worksheet[]) : WSDesignerListItem[] => {
        return worksheets.map(item => {

            if(item.status === WSStatus.loading) {
                worksheetsInLoadingState.push(item)
            }

            let mpStatus = Worksheet.getMarketplaceStatus(item)
            return new WSDesignerListItem(
                item.id!,
                item.name,
                this.context.translate(translations.enum.ws_status[item.status]),
                item.modifiedOn || new Date(),
                item.orientation,
                mpStatus,
                item.thumbnail
            )
        })
    }

    getEnumFilterData = async() => {
        let lessonSubjects = await GetLessonSubject()
        let schoolLevel = await GetSchoolLevel()

        schoolLevel = schoolLevel.filter(i => {
            let isLeaf = schoolLevel.find(leaf => leaf.parent?.id === i.id)
            return i.parent !== null && isLeaf === undefined
        })

        let filter = this.setFilter(lessonSubjects, schoolLevel)

        this.setState({schoolLevels: schoolLevel, lessonSubjects: lessonSubjects, listFilter: filter})
    }

    setFilter = (lessonSubjects: LessonSubject[], schoolLevels: SchoolLevel[]) => {
        // Add status list to list filters
        let filter: ListFilterDefinition[] = []

        // Prepare filter list for status
        let status: ListFilterDefinitionItem[] = []
        for (const item of Object.values(WSStatus)) {
            if(item !== WSStatus.deleted && item !== WSStatus.loading) {
                const i = Object.values(WSStatus).indexOf(item);
                status.push(new ListFilterDefinitionItem(i, this.context.translate(translations.enum.ws_status[item])))
            }
        }
        filter.push(new ListFilterDefinition("status", status, this.onFilterStatus, EnumValueToIndex(WSStatus.active, WSStatus)))

        // Prepare lesson subjects for filter
        if (lessonSubjects) {
            let lessonSubject = [
                new ListFilterDefinitionItem(-1,this.context.translate(translations.fields.filter.all_lesson_subjects)),
                ...lessonSubjects.map(s => new ListFilterDefinitionItem(s.id!, s.name))
            ]
            filter.push(new ListFilterDefinition("lesson-subject", lessonSubject, this.onFilterLessonSubject, -1))
        }

        // Prepare school level for filter
        if (schoolLevels) {
            let schoolLevel = [
                new ListFilterDefinitionItem(-1,this.context.translate(translations.fields.filter.all_school_levels)),
                ...schoolLevels.map(s => new ListFilterDefinitionItem(s.id!, s.name))
            ]
            filter.push(new ListFilterDefinition("school-level", schoolLevel, this.onFilterSchoolLevel, -1))
        }

        return filter
    }

    loadThumbnail = (id: number) => {
        GetWorksheetThumbnail(id).then(
            (itemThumb) => {
                let items = this.state.items
                let listItems = this.state.listItems

                let item = items.find(item => item.id === id)
                let listItem = listItems?.find(item => item.id === id)

                item && (item.thumbnail = itemThumb.thumbnail)
                listItem && (listItem.thumbnail = itemThumb.thumbnail)

                this.setState({items: items, listItems: listItems})
            },
            (_) => {
                // Ignore error (no thumbnail), default thumbnail is shown
            }
        )
    }
    onCopyItems = async (items: number[]) => {
        let message = items.length > 1
            ? this.context.translate(translations.notification.copying_ws_start).replace("%1%", items.length.toString())
            : this.context.translate(translations.notification.copying)

        let notification = Notification.handleLoading(message)
        this.context.setNotification(notification)

        try {
            let promises: Promise<Worksheet>[] = []

            for (const id of items) {
                const worksheet = this.state.items.filter(i => i.id === id)
                if (worksheet && worksheet.length > 0) {
                    let clone: Worksheet = {...worksheet[0]}
                    clone.status = WSStatus.active
                    promises.push(
                        CloneWorksheet(clone.id, clone.name + " - " + this.context.translate(translations.text_fragment.copy), WSContextType.standard)
                    )
                }
            }

            //TODO: Fehler müssen angezeigt werden (wenn ein AB nicht geclont werden konnte + welches). Der Rest muss aber success Message bekommen!

            Promise.all(promises)
                .then((items) => {
                    message = this.context.translate(translations.notification.copied)

                    // If user stays on the same site
                    if(this.props.history.location === this.props.location) {

                        let wsListItems = this.convertWorksheetToListItem(items, [])
                        wsListItems = wsListItems.concat(this.state.listItems || [])

                        this.setState({listItems: wsListItems}, () => {
                            this.context.setNotification(Notification.handleSuccess(message), notification.id)
                        })
                    }
                    // If user navigates away
                    else {
                        this.context.setNotification(Notification.handleSuccess(message), notification.id)
                    }
                })
                .catch(e => {
                    this.context.handleError(e, e.message, notification.id)
                })

        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error), notification.id)
        }
    }

    onSetStatus = async(items: number[], status: Status) => {
        let notification = Notification.handleLoading(this.context.translate(translations.notification.setting_status))

        try {
            let filter = this.getCurrentStatusFilter()

            if (filter === WSStatus.paperbin && status === Status.deactivated) {
                this.setState({
                    modal: WSStatus.deleted,
                    statusChangeItems: items
                })
            }
            else if (filter === WSStatus.active && status === Status.deactivated) {
                this.setState({
                    modal: WSStatus.paperbin,
                    statusChangeItems: items
                })
            }
            else {
                this.context.setNotification(notification)
                this.handleStatusChanges(items, WSStatus.active, notification)
            }
        }
        catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error), notification?.id)
        }
    }
    handleStatusChanges = (items: number[], status: WSStatus, notification: Notification) => {
        let promises: Promise<Worksheet>[] = []
        let listItems = this.state.listItems

        for (let i = 0; i < items.length; i++) {
            const ws = this.state.items.find(w => w.id = items[i])

            if (ws) {
                ws.status = status
                promises.push(SetWorksheetStatus(ws))
                listItems = listItems?.filter(li => li.id !== ws.id)
            }
        }

        Promise.all(promises).then(() => {
            this.setState({listItems: listItems})
            this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.set_status)), notification.id)
        },
            e => this.context.handleError(e, e.message, notification.id)
        )
    }

    /* Methods used in card to handle just one worksheet */
    onActivateItem =  async (id: number) => {
        await this.onSetStatus([id], Status.draft)
    }
    onDeactivateItem = async (id: number) => {
        await this.onSetStatus([id], Status.deactivated)
    }
    onCopyItem = async (id: number) => {
        let notification = Notification.handleLoading(this.context.translate(translations.notification.copying))
        this.context.setNotification(notification)

        try {
            const worksheet = await GetWorksheetById(id)
            if (worksheet) {
                let clone: Worksheet = {...worksheet}
                clone.status = WSStatus.active
                let clonedWorksheet = await CloneWorksheet(clone.id, clone.name + " - " + this.context.translate(translations.text_fragment.copy), WSContextType.standard)

                // If user stays on the same site
                if(this.props.history.location === this.props.location) {
                    let newListItems = this.convertWorksheetToListItem([clonedWorksheet], [])
                    newListItems = newListItems.concat(this.state.listItems || [])

                    this.setState({listItems: newListItems}, () => {
                        this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.copied)), notification.id)
                    })
                }
                // If user navigates away
                else {
                    this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.copied)), notification.id)
                }
            }
        } catch (e) {
            this.context.handleError(e, this.context.translate(translations.notification.unexpected_error), notification.id)
        }
    }

    onDeleteModalYes = async() => {
        if (this.state.statusChangeItems) {

            try {
                // Delete each worksheet selected
                for (let i = 0; i < this.state.statusChangeItems.length; i++) {
                    await DeleteWorksheet(this.state.statusChangeItems[i])
                }

                this.onModalClose()

                this.context.setNotification(Notification.handleSuccess(this.context.translate(translations.notification.deleted)))
                this.fetchData(0, false)

            } catch (e) {
                this.context.handleError(e, this.context.translate(translations.notification.unexpected_error))
            }
        }
    }
    onPaperbinModalYes = async() => {
        let notification = Notification.handleLoading(this.context.translate(translations.notification.setting_status))

        if (this.state.statusChangeItems) {
            this.context.setNotification(notification)
            this.handleStatusChanges(this.state.statusChangeItems, WSStatus.paperbin, notification)
            this.onModalClose()
        }
    }
    onModalClose = () => {
        this.setState({
            modal: WSStatus.active,
            statusChangeItems: undefined
        })
    }

    onFilterStatus = (items: Entity[], value: number) => {
        let statusArray = EnumToEntityArray(WSStatus, translations.enum.ws_status, this.context.translate)
        let status = statusArray.find(s => s.id === value)

        if (status) {
            return items.filter(item => item["status"] === status!.name)
        }

        let worksheetFilter = this.state.filter
        worksheetFilter.status = WSStatus[value]

        this.setState({filter: worksheetFilter}, () => this.fetchData(0, false))

        return items
    }
    onFilterSchoolLevel = (items: Entity[], value: number) => {
        if (value < 0) {
            return items
        }

        return items.filter(item => {
            let ws = this.state.items.find(i => i.id === item.id)
            let level = ws?.schoolLevels?.find(s => s.id === value)
            return (level !== undefined)
        })
    }
    onFilterLessonSubject = (items: Entity[], value: number) => {
        if (value < 0) {
            return items
        }

        return items.filter(item => {
            let ws = this.state.items.find(i => i.id === item.id && i.subject?.id === value)
            return (ws !== undefined)
        })
    }

    isFilterActive = () => {
        let status = this.getCurrentStatusFilter()
        return status === WSStatus.active
    }
    isFilterInactive = () => {
        let status = this.getCurrentStatusFilter()
        return status === WSStatus.paperbin
    }

    renderCardContent = (item: WSDesignerListItem) => {
        const thumb = item.thumbnail
            ? (ImagePath.getThumbnailUrl() + item.thumbnail)
            : process.env.PUBLIC_URL + ImagePath.getDefaultUrl() + "/admin/worksheet_empty.png"

        let deletedInDays = 0
        let paperbinStatus = EnumValueToEntity(WSStatus, WSStatus.paperbin, translations.enum.ws_status, this.context.translate).name
        let cardTextClass = "card-text-detail"

        if (item.status === paperbinStatus) {
            cardTextClass = cardTextClass.concat(" card-deletion-text")

            const modifiedOnDate = new Date(item.modifiedOn)
            deletedInDays = Math.round((modifiedOnDate.setDate(modifiedOnDate.getDate() + 30) - Date.now()) / (1000 * 60 * 60 * 24))
        }

        return <>
            <img src={thumb}
                 alt={"Preview"}
                 className={"card-thumbnail" + (item.orientation ? "" : " card-thumbnail-landscape")}
                 onContextMenu={(e) => e.preventDefault()}
            />

            <div className={"card-title text-cut-ellipsis"}>{item.name}</div>
            <div className={"card-text text-cut-ellipsis"} style={{overflow: "visible", whiteSpace: "normal"}}>
                {item.marketplaceStatus && item.status !== paperbinStatus &&
                    <MPStatusIcon mpStatus={WSMarketplaceStatus[item.marketplaceStatus]}
                                  tooltipPosition={TooltipPosition.above}
                                  translateX={-80}
                                  translateY={-18}/>
                }
                <div className={cardTextClass}>
                    {item.status === paperbinStatus
                    ? this.context.translate(translations.text.deleted_in).concat(" ",deletedInDays.toString()," ",this.context.translate(translations.text.days))
                    : Util.formatDate(item.modifiedOn, true)}
                </div>
            </div>
        </>
    }
    render() {
        let cardActions: CardAction[] = []
        let cardDefinition = new CardDefinition(this.renderCardContent, "orientation")

        if (this.isFilterActive()) {
            cardActions.push(
                new CardAction(1,
                    "delete.svg",
                    new TooltipText(this.context.translate(translations.toolbar.delete), this.context.translate(translations.tooltip.delete)),
                    this.onDeactivateItem),
                new CardAction(2,
                    "duplicate.svg",
                    new TooltipText(this.context.translate(translations.toolbar.duplicate), this.context.translate(translations.tooltip.duplicate)),
                    this.onCopyItem))
        }
        if (this.isFilterInactive()) {
            cardActions.push(
                new CardAction(3,
                "checkmark.svg",
                    new TooltipText(this.context.translate(translations.toolbar.recover), this.context.translate(translations.tooltip.recover_worksheet)),
                this.onActivateItem))
        }

        return <div className="app-main-content">
            <AppHeader isAdminArea={false} app={this.context.translate(translations.app.designer)} />
            <div className="admin-list-main">
                <div className="menu-container">
                    <Menu menuType={MenuType.app} menuContext={MenuContext.app}/>
                </div>

                <DataList
                    objectType={AdminObjectType.worksheet}
                    loading={this.state.loading}
                    items={this.state.listItems}
                    userList={true}
                    definitions={this.columnDefinition}
                    cardDefinition={cardDefinition}
                    cardActions={cardActions}
                    selectedEntityTextSingle={this.context.translate(translations.text.worksheet_selected)}
                    selectedEntityTextMultiple={this.context.translate(translations.text.worksheets_selected)}
                    buttonNew={{
                        id: "new",
                        text: this.context.translate(translations.toolbar.new),
                        sort: 10,
                        tooltip: new TooltipText(this.context.translate(translations.toolbar.new), this.context.translate(translations.tooltip.new)),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "coupling.svg",
                        colorPrimary: false,
                        navigateTo: "/designer/" + Const.CreateNewDataUrl
                    }}
                    buttonCopy={!this.isFilterInactive() ? {
                        id: "copy",
                        sort: 20,
                        text: this.context.translate(translations.command.duplicate),
                        tooltip: new TooltipText(this.context.translate(translations.command.duplicate), this.context.translate(translations.tooltip.copy_worksheet)),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "duplicate.svg",
                        colorPrimary: true
                    } : undefined}
                    buttonActivate={this.isFilterInactive() ? {
                        id: "activate",
                        sort: 30,
                        text: this.context.translate(translations.command.recover),
                        tooltip: new TooltipText(this.context.translate(translations.command.recover), this.context.translate(translations.tooltip.recover_worksheet)),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "checkmark.svg",
                        colorPrimary: true
                    } : undefined}
                    buttonDeactivate={{
                        id: "deactivate",
                        sort: 40,
                        text: this.isFilterInactive() ? this.context.translate(translations.command.delete) : this.context.translate(translations.command.deactivate),
                        tooltip: this.isFilterInactive() ?
                            new TooltipText(this.context.translate(translations.command.delete), this.context.translate(translations.tooltip.delete_worksheet)) :
                            new TooltipText(this.context.translate(translations.command.deactivate), this.context.translate(translations.tooltip.deactivate_worksheet)),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "delete.svg",
                        colorPrimary: true
                    }}
                    buttonShowAsList={{
                        id: "showAsList",
                        sort: 110,
                        colorPrimary: true,
                        tooltip: new TooltipText(this.context.translate(translations.command.show_as_list), this.context.translate(translations.tooltip.show_as_list)),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "listenansicht.svg",
                    }}
                    buttonShowAsCard={{
                        id: "showAsCard",
                        sort: 110,
                        colorPrimary: true,
                        tooltip: new TooltipText(this.context.translate(translations.command.show_as_card), this.context.translate(translations.tooltip.show_as_card)),
                        icon: process.env.PUBLIC_URL + ImagePath.getButtonUrl() + "kachelansicht.svg",
                    }}
                    redirectUrl={"/designer/{0}"}
                    onCopyItem={this.onCopyItems}
                    onSetState={this.onSetStatus}
                    allowStatusChangeToUserItself={false}
                    allowMultiSelect={true}
                    searchOptions={{
                        showSearch: true,
                        showSearchOptions: false,
                        typeAheadSearch: false,
                        onSearch: (searchValue) => this.fetchData(0, false, searchValue)
                    }}
                    paginationOptions={{
                        allowPagination: true,
                        showPagination: false,
                        pageSize: this.state.pageSize,
                        currentPage: this.state.page,
                        lastElementReached: this.state.lastElementReached,
                        externalPagination: true,
                        onPageChange: () => this.fetchData(this.state.page + 1, true)
                    }}
                    sortOptions={{
                        allowSort: true,
                        sortField: "modifiedOn",
                        sortDescending: true
                    }}
                    filterOptions={{
                        externalFilter: true,
                        filterDefinitions: this.state.listFilter,
                        onFilterChange: () => this.fetchData(0, false) }}
                    filter={this.state.listFilter}
                    showRowSelector={true}
                    initialListStyle={ListStyle.card}
                    onLoadThumbnail={this.loadThumbnail}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                />
            </div>

            {this.state.modal === WSStatus.deleted &&
            <Modal  id={"delete"}
                    buttons={[
                        new ButtonInfo("btnNo", "button button-cancel", "button", false, false, this.context.translate(translations.text.no), this.onModalClose, {
                            width: "150px",
                            marginLeft: "15px"
                        }),
                        new ButtonInfo("btnYes", "button button-save", "button", true, false, this.context.translate(translations.text.yes), this.onDeleteModalYes, {
                            width: "150px",
                            marginLeft: "15px"
                        })
                    ]}
                    dialogStyle={{width: "40%", height: "160px", minWidth: "520px"}}
                    contentAlignment={"center"}
                    title={this.context.translate(translations.text.delete_worksheets)}
            >

                {this.context.translate(translations.text.delete_worksheets_question)}

            </Modal>
            }
            {this.state.modal === WSStatus.paperbin &&
                <Modal  id={"paperbin"}
                        buttons={[
                            new ButtonInfo("btnNo", "button button-cancel", "button", false, false, this.context.translate(translations.command.cancel), this.onModalClose, {
                                width: "150px",
                                marginLeft: "15px"
                            }),
                            new ButtonInfo("btnOk", "button button-save", "button", true, false, this.context.translate(translations.text.ok), this.onPaperbinModalYes, {
                                width: "150px",
                                marginLeft: "15px"
                            })
                        ]}
                        dialogStyle={{width: "40%", height: "220px", minWidth: "520px"}}
                        contentAlignment={"center"}
                        title={this.context.translate(translations.text.paperbin_worksheets)}
                >

                    {this.context.translate(translations.text.paperbin_worksheets_text)}

                </Modal>
            }
        </div>
    }
}

