import Entity, {EntityData} from "./Entity";
import {Difficulty, Status, WSMarketplaceStatus, WSPageFormat, WSStatus} from "../Framework/Enums";
import LessonSubject from "./LessonSubject";
import Language from "./Language";
import SchoolLevel from "./SchoolLevel";
import {WSContextType} from "../Designer/Elements/WSContext";
import User from "./User";
import Tag from "./Tag";
import {WorksheetPage} from "./WorksheetPage";

export class Worksheet extends Entity {
    id: number
    name: string
    description?: string
    author?: string
    status: WSStatus
    context: WSContextType
    subject?: LessonSubject
    language?: Language
    difficulty?: Difficulty
    format: WSPageFormat
    orientation: boolean

    schoolLevels?: SchoolLevel[]
    blackWhite: boolean
    editingAllowed: boolean
    sourceWorksheetId?: Worksheet
    rating?: number
    ratingCount?: number
    ratingDone?: boolean

    marketplaceStatus?: WSMarketplaceStatus
    marketplaceIsEditingAllowed: boolean
    marketplaceShowName: boolean
    marketplaceShowEmail: boolean
    marketplaceTargetSchool: boolean
    marketplaceTargetPupils: boolean
    marketplaceTargetTeacher: boolean
    marketplaceTargetParents: boolean
    marketplaceKeywords?: string
    marketplaceAdvertisement: boolean

    ownerId?: User
    createdOn?: Date
    createdBy?: User
    modifiedOn?: Date
    modifiedBy?: User

    thumbnail?: string
    pages?: WorksheetPage[]
    tags?: Tag[]

    constructor(id: number, name: string, context: WSContextType, format: WSPageFormat, editingAllowed: boolean, orientation?: boolean) {

        super(name, id);
        this.id = id
        this.name = name
        this.context = context
        this.blackWhite = false
        this.status = WSStatus.active
        this.format = format
        this.orientation = orientation === undefined ? true : orientation
        this.editingAllowed = editingAllowed || false

        this.marketplaceIsEditingAllowed = false
        this.marketplaceShowName = false
        this.marketplaceShowEmail = false
        this.marketplaceTargetSchool = false
        this.marketplaceTargetPupils = false
        this.marketplaceTargetTeacher = false
        this.marketplaceTargetParents = false
        this.marketplaceAdvertisement = false
    }

    static getMarketplaceStatus(item: Worksheet) {
        let mpStatus = item.marketplaceStatus

        if(mpStatus && mpStatus !== WSMarketplaceStatus.rejected) {
            if(mpStatus === WSMarketplaceStatus.downloaded && !item.marketplaceIsEditingAllowed) {
                mpStatus = WSMarketplaceStatus.downloaded_locked
            }
        }
        else {
            mpStatus = undefined
        }

        return mpStatus
    }

    static ToEntityData(item: Worksheet) {
        let status: Status = Status.published

        if (item.marketplaceStatus === WSMarketplaceStatus.approval) {
            status = Status.approval
        }

        return new EntityData(item.name, status, item.createdOn, item.createdBy, item.modifiedOn, item.modifiedBy,
            item.ownerId, item.id)
    }
}

export class WorksheetFilter {
    search?: string
    subject?: LessonSubject
    schoolLevels?: SchoolLevel[]

    status: WSStatus

    constructor() {
        this.status = WSStatus.active
    }

    static initFilter() {
        return new WorksheetFilter()
    }
}

export class WorksheetMarketplaceFilter {
    search?: string
    format?: WSPageFormat
    subject?: LessonSubject
    language?: Language
    difficulty?: Difficulty
    schoolLevels?: SchoolLevel[]

    targetSchool: boolean
    targetPupils: boolean
    targetTeacher: boolean
    targetParents: boolean

    noSchoolLevel: boolean
    onlyMyWorksheets: boolean
    blackAndWhite: boolean
    isEditingAllowed: boolean

    constructor() {
        this.noSchoolLevel = true

        this.blackAndWhite = false
        this.isEditingAllowed = false
        this.onlyMyWorksheets = false

        this.targetPupils = true
        this.targetSchool = true
        this.targetTeacher = true
        this.targetParents = true
    }

    static initFilter() {
        return new WorksheetMarketplaceFilter()
    }
}

