import Converter from "../../../../Framework/Converter";
import {ImageCategory} from "../../../../Framework/CategoryImage";
import {WDDataCellBorder} from "../../General/WDDataCellBorder";
import {BracketPosition} from "../../../Toolbar/Button/Math/WDToolbarButtonReckonBracket";
import {WDElementBaseData} from "../../WDElementBase";
import {ToolboxElementValuesTable} from "../../Table/WDTable";

export class WDMathLineatureDataCell {
    left: string
    middle: string
    right: string
    icon?: string
    iconColor?: string
    bold: boolean
    italic: boolean
    color: string
    background: string
    solution: boolean
    bracket?: BracketPosition
    bracketColor?: string
    border: WDDataCellBorder

    constructor(left: string, middle: string, right: string, icon?: string, iconColor?: string) {
        this.left = left
        this.middle = middle
        this.right = right
        this.icon = icon
        this.iconColor = iconColor
        this.bold = false
        this.italic = false
        this.color = "#000000"
        this.background = "transparent"
        this.border = new WDDataCellBorder()
        this.solution = false
    }
}

export enum WDMathLineatureLineType {
    line,
    line_dots,
    line_frame,
    line_quad,
    dots,
    quad
}
export enum WDMathLineatureSize {
    'size_5x5' = "5x5",
    'size_6x6' = "6x6",
    'size_10x10' = "10x10",
    'size_7x10' = "7x10"
}

export class WDMathLineatureData extends WDElementBaseData {
    id?: number
    data: WDMathLineatureDataCell[][]
    cellWidth: number
    cellHeight: number
    rows: number
    cols: number
    lineType: WDMathLineatureLineType
    borderWidth: number
    lineColor?: string
    showSolution: boolean
    toolboxInfo?: ToolboxElementValuesTable

    constructor(
        cellWidth: number,
        cellHeight: number,
        rows: number,
        cols: number,
        lineType?: WDMathLineatureLineType,
        data?: WDMathLineatureDataCell[][],
        borderWidth?: number,
        lineColor?: string,
        showSolution?: boolean) {

        super()
        this.rows = rows
        this.cellWidth = cellWidth
        this.cellHeight = cellHeight
        this.cols = cols
        this.lineType = lineType || WDMathLineatureLineType.line
        this.data = data || []
        this.borderWidth = borderWidth || 1
        this.lineColor = lineColor
        this.showSolution = showSolution === undefined ? true : showSolution
    }

    static defaultCellWidth = 5
    static defaultCellHeight = 5
    static defaultBorderWidth = 1

    static isEven(lineatureSize: string) {
        return (lineatureSize === "5x5" || lineatureSize === "5x5x2" || lineatureSize === "6x6" || lineatureSize === "10x10")
    }
    static isUneven(lineatureSize: string) {
        return !this.isEven(lineatureSize)
    }

    static getCellWidth(lineatureSize: string | undefined) {
        if (lineatureSize === "5x5" || lineatureSize === "5x5x2") {
            return Converter.mmToPx(5)
        }
        else if (lineatureSize === "6x6") {
            return Converter.mmToPx(6)
        }
        else if (lineatureSize === "7x10") {
            return Converter.mmToPx(7)
        }
        else if (lineatureSize === "10x10") {
            return Converter.mmToPx(10)
        }

        return Converter.mmToPx(this.defaultCellWidth)
    }
    static getCellHeight(lineatureSize: string | undefined) {
        if (lineatureSize === "5x5" || lineatureSize === "5x5x2") {
            return Converter.mmToPx(5)
        }
        else if (lineatureSize === "6x6") {
            return Converter.mmToPx(6)
        }
        else if (lineatureSize === "10x10" || lineatureSize === "7x10") {
            return Converter.mmToPx(10)
        }

        return Converter.mmToPx(this.defaultCellHeight)
    }

    static getImageCategory(lineatureSize: string) {
        return WDMathLineatureData.isEven(lineatureSize) ? ImageCategory.MATH_LINEATURE_THUMBNAIL_EVENLY : ImageCategory.MATH_LINEATURE_THUMBNAIL_UNEVENLY
    }
    static mapLineStyle(imageKey: string) {
        if (imageKey.toUpperCase().indexOf("LINE-DOTS") > 0) {
            return WDMathLineatureLineType.line_dots
        }
        else if (imageKey.toUpperCase().indexOf("LINE-FRAME") > 0) {
            return WDMathLineatureLineType.line_frame
        }
        else if (imageKey.toUpperCase().indexOf("LINE-QUAD") > 0) {
            return WDMathLineatureLineType.line_quad
        }
        else if (imageKey.toUpperCase().indexOf("DOTS") > 0) {
            return WDMathLineatureLineType.dots
        }
        else if (imageKey.toUpperCase().indexOf("QUAD") > 0) {
            return WDMathLineatureLineType.quad
        }
        return WDMathLineatureLineType.line
    }
    static serialize(data: WDMathLineatureData) {
        return JSON.stringify(data, (key, value) => {
            if (key === "variable") return undefined;
            else return value;
        })
    }
}
