import React from "react";
import {WDToolbarElement, WDToolbarElementProps, WDToolbarElementState} from "../../Toolbar/Toolbar/WDToolbarElement";
import {WDToolbar, WDToolbarOrientation} from "../../Toolbar/Toolbar/WDToolbar";
import {WDToolbarSectionTransformation} from "../../Toolbar/Section/WDToolbarSectionTransformation";
import Converter from "../../../Framework/Converter";
import {MainContext} from "../../../_base/MainContext";
import {ElementLayout} from "../WDElementContainer";
import {WDToolbarSectionObjectGeneral} from "../../Toolbar/Section/WDToolbarSectionObjectGeneral";
import {WDToolbarTabsConfig, WDToolbarTypeEnum} from "../../Toolbar/Toolbar/WDToolbarTabs";
import {WDToolbarButtonSolution} from "../../Toolbar/Button/Admin/WDToolbarButtonSolution";
import {WDToolbarAction} from "../../Toolbar/WDToolbarAction";
import {WSContext, WSContextType} from "../WSContext";
import {WDToolbarButtonSeparator} from "../../Toolbar/Button/General/WDToolbarButtonSeparator";
import {WDToolbarButtonCrossOut} from "../../Toolbar/Button/Image/WDToolbarButtonCrossOut";
import {WDToolbarButtonCrossOutSolution} from "../../Toolbar/Button/Admin/WDToolbarButtonCrossOutSolution";
import {
    ImageToolbarButtonType,
    WDToolbarButtonSelectImage
} from "../../Toolbar/Button/Image/WDToolbarButtonSelectImage";
import {WorksheetItemType, WorksheetItemTypeEnum} from "../../../_model/WorksheetItemType";
import _ from "lodash";
import {WDToolbarButtonLineColor} from "../../Toolbar/Button/General/WDToolbarButtonLineColor";
import {TooltipText} from "../../../Components/Tooltips";
import translations from "../../../Framework/translations.json";

export interface WDToolbarImageProps extends WDToolbarElementProps {
}

export interface WDToolbarImageState extends WDToolbarElementState {
}

export class WDImageToolbar extends WDToolbarElement<WDToolbarImageProps, WDToolbarImageState> {
    static contextType = MainContext
    declare context: React.ContextType<typeof MainContext>

    constructor(props: WDToolbarImageProps, state: WDToolbarImageState) {
        super(props, state);

        this.state = {
            isLicenseValidForElement: true,
            activeId: -1,
            activeToolbarType: WDToolbarTypeEnum.OBJECT,
            refToolbar: React.createRef()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<WDToolbarImageProps>, nextState: Readonly<WDToolbarImageState>): boolean {
        // Check if new selected element is different from previous selected element by data
        return !(_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState))
    }

    render () {
        let crossOutObj = this.multiselectSearchForValue("crossOut")
        let crossOutColorObj = this.multiselectSearchForValue("crossOutColor")
        let lineColorObj = this.multiselectSearchForValue("lineColor")
        let colorableObj = this.multiselectSearchForValueFromObject(this.props.additionalToolbarData, "colorable")

        const crossOut = (crossOutObj?.valueEqual && crossOutObj.newValue !== undefined) ? crossOutObj.newValue : false
        const crossOutColor = (crossOutColorObj?.valueEqual && crossOutColorObj.newValue !== undefined) ? crossOutColorObj.newValue : undefined
        const lineColor = (lineColorObj?.valueEqual && lineColorObj.newValue !== undefined) ? lineColorObj.newValue : undefined
        const colorable = (colorableObj?.valueEqual && colorableObj.newValue !== undefined) ? colorableObj.newValue : false

        return <WDToolbar
            id={"-element"}
            left={this.props.left}
            top={this.props.top - 4}
            isLocked={this.props.elementProps.locked}
            calculatePosition={true}
            className={"ws-designer-toolbar-two-line"}
            orientation={WDToolbarOrientation.top}
            activeToolbarType={this.state.activeToolbarType}
            toolbarTabsConfig={new WDToolbarTabsConfig(true, true, false, this.onChangeToolbarTab)}
            ref={this.state.refToolbar}
        >

            {this.state.activeToolbarType === WDToolbarTypeEnum.TRANSFORMATION &&

            <ul className={"ws-designer-toolbar-buttons"}>
                <WDToolbarSectionTransformation
                    elementProps={{
                        layout: new ElementLayout(
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.left)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.top)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.width)),
                            Math.round(Converter.pxToMm(this.props.elementProps.layout.height))
                        ),
                        transformation: this.props.elementProps.transformation,
                        border: this.props.elementProps.border,
                        color: this.props.elementProps.color,
                        minLeft: this.props.elementProps.minLeft,
                        minTop: this.props.elementProps.minTop,
                        minWidth: Math.round(Converter.pxToMm(this.props.elementProps.minWidth)),
                        minHeight: Math.round(Converter.pxToMm(this.props.elementProps.minHeight)),
                        maxLeft: this.props.elementProps.maxLeft !== undefined ? Math.round(Converter.pxToMm(this.props.elementProps.maxLeft)) : undefined,
                        maxTop: this.props.elementProps.maxTop !== undefined ? Math.round(Converter.pxToMm(this.props.elementProps.maxTop)) : undefined,
                        maxWidth: Math.round(Converter.pxToMm(this.props.elementProps.maxWidth)),
                        maxHeight: Math.round(Converter.pxToMm(this.props.elementProps.maxHeight)),
                        locked: this.props.elementProps.locked
                    }}
                    context={this.props.context}
                    selectedElementCount={this.props.selectedElementCount}
                    elementsOnSameSheet={this.props.elementsOnSameSheet}
                    isReadonly={this.isUsedInTextExerciseChild()}
                    isEdited={this.props.editMode}
                    showColorOptions={false}
                    initialLinkState={true}
                    canChangeLinkState={false}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onFlipHorizontal={this.props.onFlipHorizontal}
                    onFlipVertical={this.props.onFlipVertical}
                    onArrange={this.props.onArrange}
                    onAlign={this.props.onAlign}
                    onDistribute={this.props.onDistribute}
                />
            </ul>
            }

            {this.state.activeToolbarType === WDToolbarTypeEnum.OBJECT &&

            <ul className={"ws-designer-toolbar-buttons"}>

                <div className={"ws-designer-toolbar-section"}>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonCrossOut
                            id={101}
                            clickable={true}
                            pressed={crossOut}
                            options={{
                                open: this.state.activeId === 101,
                                width: 70,
                                height: 150,
                                color: crossOutColor
                            }}
                            onActionButton={this.onToolbarAction}
                            onToggleButton={this.onToggleButton}
                        />
                        <WDToolbarButtonLineColor
                            id={102}
                            clickable={colorable}
                            pressed={lineColor !== undefined}
                            lineColor={lineColor}
                            open={this.state.activeId === 102}
                            tooltip={new TooltipText(
                                this.context.translate(translations.toolbar.dye),
                                this.context.translate(translations.tooltip.dye)
                            )}
                            tooltipDisabled={new TooltipText(
                                this.context.translate(translations.toolbar.dye_disabled),
                                this.context.translate(translations.tooltip.dye_disabled_not_possible)
                            )}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.props.onToolbarAction}
                        />
                    </div>
                    <div className={"ws-designer-toolbar-row"}>
                        <WDToolbarButtonSelectImage
                            id={103}
                            width={310}
                            height={210}
                            type={ImageToolbarButtonType.replace}
                            pressed={this.state.activeId === 103}
                            clickable={this.props.context !== WSContextType.text_exercise_child}
                            worksheetType={this.props.context}
                            onToggleButton={this.onToggleButton}
                            onActionButton={this.onToolbarAction}
                        />
                    </div>
                </div>

                <WDToolbarSectionObjectGeneral
                    activeId={this.state.activeId}
                    showColorOptions={true}
                    showBorderPadding={false}
                    onToolbarAction={this.onToolbarAction}
                    onChangeActive={() => this.setState({activeId: -1})}
                    context={this.props.context}
                    worksheetItemType={new WorksheetItemType("", WorksheetItemTypeEnum.IMAGE)}
                    elementProps={this.props.elementProps}
                    onUpdateSelectedElements={this.props.onUpdateSelectedElements}
                    onChangeBorder={this.props.onChangeBorder}
                    onElementDeleted={this.props.onElementDeleted}
                    onDuplicate={this.props.onDuplicate}
                    onCopy={this.props.onCopy}
                    onCut={this.props.onCut}
                    onPaste={this.props.onPaste}
                    onChangeGroupingStatus={this.props.selectedElementCount > 1 ? this.props.onChangeGroupingStatus : undefined}
                />

                {/* Only in admin mode! */}
                {WSContext.showToolbarButtonMarkSolution(this.props.context) &&
                    <>
                    <WDToolbarButtonSeparator />
                    <div className={"ws-designer-toolbar-section"}>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonSolution
                                id={602}
                                clickable={true}
                                onActionButton={this.onToolbarAction}
                                icon={"solution_mark.svg"}
                                action={WDToolbarAction.SOLUTION}
                            />
                        </div>
                        <div className={"ws-designer-toolbar-row"}>
                            <WDToolbarButtonCrossOutSolution
                                id={603}
                                clickable={true}
                                onActionButton={this.onToolbarAction}
                                action={WDToolbarAction.SOLUTION_CROSS_OUT}
                            />
                        </div>
                    </div>
                    </>
                }
            </ul>
            }
        </WDToolbar>
    }
}
